@import "../../../styles/colors";
@import "../../../styles/palette";

@import "../../../styles/typography";
@import "../../../styles/responsive";
@import "../../../styles/animations";


.container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: $secondary-color;
  min-height: 100vh; }

.videoBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 768px) {
    margin-top: 100px; } }

.embedBox {
  margin: 1em auto;
  width: 65%;
  padding: 10px;
  @media (min-width: $computer) {
    padding-top: 7em; } }

.navBar {
  background-color: $color-primary-1 !important; }
