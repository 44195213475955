@import "../../styles/colors";
@import "../../styles/palette";
@import "../../styles/typography";
@import "../../styles/responsive";
h2 {
  color: white;
  text-align: center;
  font-size: $big-font-size;
  @media (max-width: $tablet) {
    margin-top: 0;
    font-size: $large-font-size; } }

.rateUsContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: $color-primary-1;

  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: -2px; //fix white line between sections
  @media (min-width: $computer) {
    padding-top: 1rem;
    padding-bottom: 1rem; }

  .rateUsCopy {
    margin: 1px; }
  .rateIconItem {
    display: flex;
    justify-content: center;
    .rateIconButton {
      background-color: rgba(255, 255, 255, 0.89); }
    .rateIcon {
      width: 3.5rem; } } }

.list {
  background-color: $color-primary-1;

  padding-bottom: 75px;

  .contentSingle {
    min-height: 95vh;
    padding: 8em 18px 18px; }

  .content {
    padding-top: 85px;
    min-height: 95vh;
    @media (max-width: 374px) {
      padding-top: 135px; } }

  .noContent {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 58vh; }

  .chargingContent {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 85px;
    min-height: 95vh; }

  .chargingCircle {
    color: $secondary-color; }

  ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    margin-top: 35px;
    padding-bottom: 3em;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: $medium-font-size;
      margin-top: 20px;

      p, h3 {
        margin-right: 10px; } } }

  .card {
    width: 100%; }

  .errorMessage {
    color: #fff;
    text-align: center; }

  .buttonNew {
    position: fixed;
    margin: 10px;
    right: 10px;
    bottom: 10px;
    background-color: $secondary-color;
    color: $primary-color-hover;
    box-shadow: 5px 6px 8px grey;
    font-weight: bold; }

  .buttonNew:hover {
    background-color: $secondary-color-off; } }

.shareButtonsBox {
  padding: 2em;
  display: flex;
  align-items: center;
  justify-content: space-evenly; }

.shareButton:hover {
  cursor: pointer; }

.multipleSelectionButton {
  position: fixed !important;
  margin: 10px !important;
  right: 10px !important;
  bottom: 60px !important;
  background-color: #ffffff !important;
  color: #448086 !important;
  box-shadow: 0px 5px 19px grey; }


.multipleSelectionControls {
  display: flex;
  position: fixed;
  bottom: 0;
  padding-top: 1.25em;
  padding-bottom: 1.25em;
  background-color: #fff;
  box-shadow: -1px 2px 42px 0px rgba(0, 0, 0, 0.75);
  width: 100%;
  z-index: 300;
  justify-content: space-evenly;
  align-items: center;

  .multipleSelectionInfo {
    display: flex;
    align-items: center;

    .multipleSelectionInfoClose {
      margin-right: 0.5em; } } }

.closeModalIcon {
  position: absolute!important;
  right: 5px!important;
  top: 5px!important; }

.sendingDataAnimation {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.floatingActiveBraceletButton {
  position: fixed !important;
  bottom: 1em;
  right: 1em;
  z-index: 99 !important;
  border-radius: 25px !important;
  background-color: $color-secondary-1-0  !important;
  color: $primary-color-hover !important;
  box-shadow: 3px 7px 20px 0px rgba(0, 0, 0, 1);
  @media (min-width: $tablet) {
 } }   //// display: none !important

.activeIcon {
  color: $primary-color-hover !important;
  margin-right: 0.5em !important; }

.getNewPulsIdButton {
  background-color: #ff8f00!important;
  color: #ffffff !important; }
