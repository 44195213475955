@import "../../../../styles/colors";
@import "../../../../styles/palette";
@import "../../../../styles/typography";
@import "../../../../styles/responsive";

.sendingDataAnimation {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.container {
  margin-bottom: 50px; }
.formControl {
  display: flex!important;

  label {
    color: $color-primary-1 !important; }
  div {
    fieldset {
      border-color: $color-primary-1 !important;
      legend {
        max-width: 90px !important; } } }
  svg {
    color: $color-primary-1 !important; } }

.formControlType {
  display: flex!important;
  @media (max-width: $tablet) {
    margin-bottom: 20px !important; }
  label {
    color: $color-primary-1 !important; }
  div {
    fieldset {
      border-color: $color-primary-1 !important; } } }

.stepper {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  position: absolute;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  @media (max-width: $tablet) {
    position: fixed; } }


.flexColumn {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  button {
    background-color: $color-primary-1;

    color: white; }
  button:hover {
    background-color: $primary-color-hover;
    color: white; }
  button:disabled {
    background-color: white;
    color: $color-primary-1; } }


.noEqualPasswordsMessage {
  color: red!important;
  font-style: italic;
  margin-left: 20px; }

.noEqualPasswordsMessageBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red!important;
  font-style: italic; }

.paymentModalTitleBox {
  display: flex;
  align-items: center; }

.sendingDataAnimation {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.textField {
  label {
    color: $color-primary-1 !important; }
  div {
    fieldset {
      border-color: $color-primary-1 !important; } } }

.contentText {
  border-bottom: 10px; }


.doubleColumnContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start; }

.parentContainer {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-around; }

.docsListContainer {
  background-color: $box-background;
  border-radius: 25px;
  .document {
    .documentHeader {
      display: flex;
      align-items: center; } }
  .contactsListContainerTitle {
    margin: 10px 15px 0;
    color: #979797; } }

.documentsContainer {
  display: flex;
  justify-content: space-around;
  align-items: baseline!important;
  margin-top: 10px !important; }

.documentFormControlContainer {
  align-items: flex-start;
  justify-content: space-between;
  @media (min-width: $tablet) {
    display: flex; }
  @media (max-width: $tablet) {
    flex-direction: column !important; } }

.uploadFileContainer {
  display: flex;
  flex-wrap: wrap;
  min-height: 65px;
  align-items: center;
  @media (max-width: $tablet) {
    flex-direction: column;
    margin-top: 25px !important; } }
.uploadFilebutton {
  background-color: $color-primary-1 !important;
  color: white !important;
  margin-right: 5px !important;
  margin-left: 12px !important; }
.uploadFilebutton:hover {
  background-color: $primary-color-hover !important;
  color: white !important; }
.fileNameContainer {
  display: flex;
  align-items: center;
  border-left: 3px solid $primary-color-hover;
  flex-wrap: wrap;
  @media (max-width: $tablet) {
    flex-direction: column;
    margin-top: 15px;
    margin-left: 0; } }

.addButton {
  margin-top: 15px;
  background-color: $color-primary-1 !important; }
.addButton:hover {
  background-color: $primary-color-hover !important; }
.addButton:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important; }
.addButtonIcon {
  margin-right: 5px; }

.newDocumentFormContainer {
  background-color: #f0f0f0;
  border-radius: 25px;
  padding: 1em !important;
  margin-bottom: 1em !important;
  margin-top: 1em !important; }
.newDocumentActionButtons {
  display: flex;
  justify-content: flex-end; }

.tutoWrapper {
  display: flex;
  flex-direction: column;
  text-align: center; }

.tutoImgWrapper {
  text-align: center;
  margin: 0.6em; }

.tutoParentWrapper:after, .tutoParentWrapper:before {
  @media (min-width: $tablet) {
    right: 99%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; } }


.tutoParentWrapper:after {
  @media (min-width: $tablet) {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #ffffff;
    border-width: 15px;
    margin-top: -15px; } }

.tutoParentWrapper:before {
  @media (min-width: $tablet) {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: #ffffff;
    border-width: 21px;
    margin-top: -21px; } }
.UxTutoMask {
  @media (max-width: 1400px) {
    fill: rgba(0, 0, 0, 0.9) !important;
    stroke: rgba(0, 0, 0, 0.9) !important; } }
