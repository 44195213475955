@import "../../styles/colors";
@import "../../styles/palette";
@import "../../styles/typography";

.snackParentWrapper {
  z-index: 300000 !important; }

.success {
  z-index: 200000 !important;
  div {
    background-color: $snack-success;
    font-weight: bold; } }

.warning {
  z-index: 200000 !important;
  div {
    background-color: $snack-warning;
    font-weight: bold; } }


.error {
  z-index: 200000 !important;
  div {
    background-color: $snack-error;
    font-weight: bold; } }


.snackButton {
  z-index: 200000 !important;
  color: #fff !important;
  border-color: #fff !important; }

