@import "../../styles/colors";
@import "../../styles/palette";
@import "../../styles/typography";
@import "../../styles/responsive";
@import "../../styles/animations";

.parentContainer {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding-bottom: 3em;
  background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%); }
//background-image: linear-gradient(to bottom, #ffe6d5 0%, #ffd1ff 100%)
////background-image: linear-gradient(to top, #accbee 0%, #e7f0fd 100%);

.faqCard {
  border-radius: 15px !important; }

.headImageContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 5rem; }

.headImage {
  @media (max-width: $tablet) {
    display: none; } }


.headImageWrapper {
  //border-bottom: 4px solid #448086
  border-radius: 4px;
  padding: 1.8em;
  width: 100%;
  margin-top: -1em;
  text-align: center; }

.panelDetails {
  background-color: #eaf3ff;
  border-radius: 15px;
  margin: 0.7rem; }
//background-image: linear-gradient(to top, #f4f3f9 77%, #ffffff 100%)
.panelDetailsTypography {
  font-size: $small-font-size !important; }

.backButton {
  position: absolute;
  left: 25px;
  top: 8rem;

  .backIcon {
    margin-right: 0.3em; } }

.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .button {
    margin-top: 2rem;
    padding: 10px;
    background: transparent;
    color: $color-primary-1;

    border: 2px $color-primary-1 solid;
    border-radius: 5px;
    font-size: $big-font-size; }

  .primaryCTAButton {
    margin-top: 2rem;
    color: #ffffff;
    background: #FF6966;
    //border: 2px solid
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    padding: 1em 2.5em;
    border-radius: 10px;
    cursor: pointer;
    font-size: $big-font-size;
    @media (max-width: $mobile) {
      font-size: $large-font-size; } }

  .primaryCTAButton:hover {
    background-color: #ea605d; } }

.tapIcon {
  margin-left: 0.5rem;
  font-size: 2rem;
  transform: rotate(-25deg); }
