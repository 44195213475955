@import "../../styles/colors";
@import "../../styles/palette";
@import "../../styles/typography";
@import "../../styles/responsive";
@import "../../styles/animations";

.gridContainer {
  padding-top: 7em; }
.gridContainer, .returnButtonContainer {
  display: flex;
  justify-content: center; }

.thanksMessage {
  text-align: center; }
.returnButton {
  background-color: $color-primary-1 !important;
  color: $color-secondary-1-0  !important; }
