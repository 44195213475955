@import "../../styles/colors";
@import "../../styles/palette";
@import "../../styles/typography";
@import "../../styles/responsive";

.formControl {
  margin-top: 8px;
  min-width: 120px;
  label {
    color: $color-primary-1 !important; }
  div {
    fieldset {
      border-color: $color-primary-1 !important; } }
  svg {
    color: $color-primary-1 !important; } }

.flexColumn {
  display: flex;
  flex-direction: column;
  button {
    background-color: $color-primary-1;

    color: white; }
  button:hover {
    background-color: $primary-color-hover;
    color: white; }
  button:disabled {
    background-color: white;
    color: $color-primary-1; } }


.noEqualPasswordsMessage {
  color: red!important;
  font-style: italic;
  margin-left: 20px; }

.noEqualPasswordsMessageBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red!important;
  font-style: italic; }

.paymentModalTitleBox {
  display: flex;
  align-items: center; }

.sendingDataAnimation {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.textField {
  label {
    color: $color-primary-1 !important; }
  div {
    fieldset {
      border-color: $color-primary-1 !important; } } }
.contentText {
  border-bottom: 10px; }

.dialogContainer {
  [class^="MuiPaper-root"] {
    min-height: 300px; } }

.closeModalIcon {
  position: absolute!important;
  right: 5px!important;
  top: 5px!important; }
