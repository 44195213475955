@keyframes floatBracelet {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@keyframes floatVerticalBracelet {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes backgroundGradient {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes backgroundGradient2 {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }
