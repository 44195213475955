@import "../../../../styles/colors";
@import "../../../../styles/palette";
@import "../../../../styles/typography";
@import "../../../../styles/responsive";


.parentContainer {
  display: flex;
  justify-content: center; }



.dialogContentText {
  font-size: $large-font-size !important;
  text-align: center !important;
  margin-bottom: 1em !important; }

.mainActionButtonContainer {
  display: flex;
  justify-content: center; }

.mainActionButton {
  margin-top: 1em !important;
  border-radius: 50px !important;
  padding: 1.25rem !important;
  @media (min-width: $tablet) {
    margin-left: 2rem !important; } }

.dialogActions {
  justify-content: center !important; }

.closeModalIcon {
  position: absolute!important;
  right: 5px!important;
  top: 5px!important; }

.sendingDataAnimation {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.cardContent {
  @media(min-width: $tablet) {
    display: flex;
    justify-content: center;
    align-items: center; } }

.inputAndClaimParentContainer {
  @media (min-width: $tablet) {
    margin-right: 2rem;
    margin-left: 2rem; } }

.inputAndButtonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: $tablet) {
    flex-direction: row; } }

.succeedAnimationContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .succeedAnimationClaim {
    margin-top: 1rem; } }
