@import "../../styles/colors";
@import "../../styles/palette";
@import "../../styles/typography.sass";
@import "../../styles/responsive.sass";
@import "../../styles/animations";

.rootContainer {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  //background: linear-gradient(224deg, #00f609, #03cfcf, #58f706)
  //background: linear-gradient(-65deg, #10ce3e, #00cbbe, #37ca4b, #b0a7e7)
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: backgroundGradient 15s ease-in-out infinite;
  overflow: hidden; }

.title {
  font-family: Comfortaa, sans-serif !important;
  color: #FFFFFF !important;
  padding: 1rem;
  text-align: center !important;
  @media (min-width: $tablet) {
    padding: 4rem 2rem 2rem;
    font-size: 2rem !important; } }

.animationAndHashtagContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 !important;
  @media (min-width: $tablet) {
      flex-direction: row; } }

.hashtag {
  font-family: Comfortaa, sans-serif !important;
  color: #FFFFFF !important;
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.05rem !important;
  @media (min-width: $tablet) {
    font-size: 1.75rem !important; }
  .hashtagIcon {
    font-family: Comfortaa, sans-serif !important;
    font-size: 3rem;
    @media (min-width: $tablet) {
      font-size: 5rem !important; } } }

.logosContainer {
  display: flex;
  justify-content: center;
  align-items: center; }

.pulsidLogo {
  width: 60%;
  @media (min-width: $tablet) {
    width: 10rem; } }
.impetraLogo {
  width: 85%;
  @media (min-width: $tablet) {
    width: 13rem; } }
