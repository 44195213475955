$primary-color: #4FA9B3;
$secondary-color: #fff8e3;
//$secondary-color: #fff8e3
$secondary-color-off: #efe9d6;

$box-background: #f7f7f7;
$green-color: #c3dd36;


$primary-color-hover: #448086;

$complementary-charcoal-color: #121c29;
$complementary-concrete-color: #716b77;
$complementary-silver-color: #dddcdc;
$complementary-soft-color: #f5f5f5;
$complementary-sand-color: #fafafa;
$complementary-white-color: #ffffff;
$complementary-black-opacity-7: rgba(0, 0, 0, 0.7);

$error-color: #ff3d00;

$snack-error: #dd2c01;
$snack-success: #4fb457;
$snack-warning: #ffac00;

$facebook-pantone-color: #3b5998;
$twitter-pantone-color: #55acee;
$linkedin-pantone-color: #0077b5;
$instagram-pantone-color: #8a3ab9;
$youtube-pantone-color: #ff0000;
