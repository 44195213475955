@import "../../styles/colors";
@import "../../styles/palette";
@import "../../styles/typography";
@import "../../styles/responsive";
@import "../../styles/animations";


.container {
  display: flex;
  padding-top: 25%;
  justify-content: center;
  align-items: stretch;
  background-color: $secondary-color;
  @media(min-width: $mobile) {
    padding-top: 12%; } }
.gridItem {
  padding: 1rem;
  @media(max-width: $tablet) {
    padding-top: 2rem;
    padding-bottom: 2rem; } }

.videoTitle {
  text-align: center;
  padding: 10px !important; }

.videoBox {
  margin: 15px;
  display: flex;
  background-color: black;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.embedBox {
  margin: 1em auto;
  margin-top: 2rem !important;
  //height: 80%
  width: 65%;
  padding: 10px;
  //display: flex
  //justify-content: center
  @media (max-width: $tablet) {
    width: 83%; }
  div:first-child {
    border-radius: 12px !important;
    box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23) !important; } }

.videoBorder {
  iframe {
    border-radius: 25px !important; } }

.navBar {
  background-color: $color-primary-1 !important; }


.buttonContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem;
  .button {
    margin-top: 2rem;
    padding: 10px;
    background: transparent;
    color: $primary-color;
    border: 2px $color-primary-1 solid;
    border-radius: 5px;
    font-size: $big-font-size; } }

.ctaContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  @media(max-width: $tablet) {
    padding-top: 2rem;
    padding-bottom: 2rem; } }

.ctaTitle {
  text-align: center !important;
  margin-bottom: auto !important;
  padding: 10px; }

.ctaButton {
  margin-top: 2rem !important;
  background: #00BCD4 !important;
  color: #ffff !important;
  font-family: sans-serif !important;
  font-weight: bold !important;
  font-size: $medium-font-size !important;
  margin-bottom: 1rem !important;
  border-radius: 43rem !important;
  box-shadow: 0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23) !important;
  @media(min-width: $tablet) {
    width: 60% !important;
    padding: 2rem 2rem !important;
    margin-bottom: auto !important; } }


