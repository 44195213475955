body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: 'Aristotelica';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Aristotelica-Text-Regular-trial.e0ab01a0.ttf) format('truetype')
}

@font-face {
  font-family: 'Blogger-sans';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/Blogger_Sans-Light.b8bf30a6.otf)
}

@font-face {
  font-family: 'Comfortaa';
  font-style: normal;
  src: url(/static/media/Comfortaa-Medium.1c593a56.ttf)
}
/*font-family*/
/*font-size*/
/*font-weight*/
@-webkit-keyframes Landing_floatBracelet__2ZL2N {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }
@keyframes Landing_floatBracelet__2ZL2N {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@-webkit-keyframes Landing_floatVerticalBracelet__JdxwB {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes Landing_floatVerticalBracelet__JdxwB {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@-webkit-keyframes Landing_backgroundGradient__2cqKY {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes Landing_backgroundGradient__2cqKY {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@-webkit-keyframes Landing_backgroundGradient2___U-Np {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

@keyframes Landing_backgroundGradient2___U-Np {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

.Landing_Landing__2l03- {
  display: flex;
  flex-direction: column;
  text-align: justify;
  align-items: center;
  overflow-x: hidden;
  font-family: Aristotelica;
  background-color: #ffffff; }
  .Landing_Landing__2l03- h1 {
    color: white;
    text-align: center;
    font-size: 30px; }
  .Landing_Landing__2l03- h2 {
    color: white;
    text-align: center;
    font-size: 30px; }
    @media (max-width: 768px) {
      .Landing_Landing__2l03- h2 {
        margin-top: 0;
        font-size: 20px; } }
  .Landing_Landing__2l03- h3 {
    font-family: Blogger-sans; }
  .Landing_Landing__2l03- li {
    font-family: Blogger-sans; }
  .Landing_Landing__2l03- a {
    font-family: Blogger-sans; }
  .Landing_Landing__2l03- p {
    font-size: 20px;
    font-family: Blogger-sans;
    text-align: justify; }
  .Landing_Landing__2l03- .Landing_headContainer__1zPAe {
    display: flex;
    min-height: 100vh;
    padding: 7rem 0.5rem 0.5rem;
    align-items: center;
    background: linear-gradient(to bottom, #06beb6, #74a7b2);
    background-size: cover; }
    .Landing_Landing__2l03- .Landing_headContainer__1zPAe h1 {
      margin-bottom: 2rem;
      font-size: 60px; }
      @media (max-width: 768px) {
        .Landing_Landing__2l03- .Landing_headContainer__1zPAe h1 {
          font-size: 40px; } }
    @media (min-width: 1024px) {
      .Landing_Landing__2l03- .Landing_headContainer__1zPAe .Landing_headContainerImg__390Xc {
        margin-top: -200px; } }
    .Landing_Landing__2l03- .Landing_headContainer__1zPAe .Landing_headContainerContent__3R5iC {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.5rem; }
      @media (min-width: 768px) {
        .Landing_Landing__2l03- .Landing_headContainer__1zPAe .Landing_headContainerContent__3R5iC {
          padding-left: 3rem;
          padding-right: 2rem; } }
    @media (min-width: 768px) {
      .Landing_Landing__2l03- .Landing_headContainer__1zPAe .Landing_videoPlayerGridItem__3kjrA {
        padding-left: 2rem;
        padding-right: 3rem; } }
  .Landing_Landing__2l03- .Landing_dataIntroduction__2WWpA {
    margin-top: 6rem;
    margin-bottom: -2rem; }
    @media (max-width: 1024px) {
      .Landing_Landing__2l03- .Landing_dataIntroduction__2WWpA {
        margin-top: 4rem;
        margin-bottom: 4rem; } }
    .Landing_Landing__2l03- .Landing_dataIntroduction__2WWpA h1 {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bolder !important; }
      @media (max-width: 1024px) {
        .Landing_Landing__2l03- .Landing_dataIntroduction__2WWpA h1 {
          flex-direction: column; } }
      .Landing_Landing__2l03- .Landing_dataIntroduction__2WWpA h1 svg {
        font-size: 2rem; }
        @media (min-width: 1024px) {
          .Landing_Landing__2l03- .Landing_dataIntroduction__2WWpA h1 svg {
            margin-left: 1rem; } }
      .Landing_Landing__2l03- .Landing_dataIntroduction__2WWpA h1 svg:first-child {
        margin-left: 0; }
        @media (min-width: 1024px) {
          .Landing_Landing__2l03- .Landing_dataIntroduction__2WWpA h1 svg:first-child {
            margin-right: 1rem; } }
      .Landing_Landing__2l03- .Landing_dataIntroduction__2WWpA h1 svg:last-child {
        display: none; }
        @media (min-width: 1024px) {
          .Landing_Landing__2l03- .Landing_dataIntroduction__2WWpA h1 svg:last-child {
            display: block; } }
    @media (max-width: 480px) {
      .Landing_Landing__2l03- .Landing_dataIntroduction__2WWpA .Landing_liClass__2oSkB {
        padding-left: 0; } }
    .Landing_Landing__2l03- .Landing_dataIntroduction__2WWpA p {
      color: #FFFFFF;
      font-weight: bold; }
      @media (max-width: 480px) {
        .Landing_Landing__2l03- .Landing_dataIntroduction__2WWpA p {
          font-size: 17px;
          padding-left: 0; } }
    .Landing_Landing__2l03- .Landing_dataIntroduction__2WWpA .Landing_dataIntroductionIcon__3347Z {
      color: #ffffff; }
      @media (max-width: 480px) {
        .Landing_Landing__2l03- .Landing_dataIntroduction__2WWpA .Landing_dataIntroductionIcon__3347Z {
          margin-right: 0; } }
      .Landing_Landing__2l03- .Landing_dataIntroduction__2WWpA .Landing_dataIntroductionIcon__3347Z svg {
        font-size: 2.25rem; }
    @media (max-width: 480px) {
      .Landing_Landing__2l03- .Landing_dataIntroduction__2WWpA {
        margin-right: 0;
        padding-right: 0; } }
  .Landing_Landing__2l03- .Landing_weHelpYouSection__1riJm {
    display: flex;
    justify-content: space-around;
    background-color: #4FA9B3;
    width: 100%;
    margin-bottom: -2px; }
    .Landing_Landing__2l03- .Landing_weHelpYouSection__1riJm p {
      text-align: center;
      color: #ffffff; }
    .Landing_Landing__2l03- .Landing_weHelpYouSection__1riJm .Landing_documentsButtonContainer__3BzFq {
      margin-top: 1em;
      display: flex !important;
      justify-content: center !important; }
      .Landing_Landing__2l03- .Landing_weHelpYouSection__1riJm .Landing_documentsButtonContainer__3BzFq .Landing_downloadDocumentButton__3z1NT {
        background-color: #ffcf2f !important; }
      .Landing_Landing__2l03- .Landing_weHelpYouSection__1riJm .Landing_documentsButtonContainer__3BzFq .Landing_documentsDownloadIcon__3WOuW {
        margin-right: 0.2em; }
  .Landing_Landing__2l03- .Landing_peopleContainer__2mpNn {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 0 10px;
    color: #ffffff;
    position: relative;
    margin-top: -4px; }
    .Landing_Landing__2l03- .Landing_peopleContainer__2mpNn p {
      max-width: 800px; }
      @media (max-width: 768px) {
        .Landing_Landing__2l03- .Landing_peopleContainer__2mpNn p {
          max-width: 400px; } }
    .Landing_Landing__2l03- .Landing_peopleContainer__2mpNn .Landing_fondoOlasImgs__2iuWg {
      position: absolute; }
    .Landing_Landing__2l03- .Landing_peopleContainer__2mpNn .Landing_peopleContainerContent__2PB3k {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 1rem;
      max-width: 90vw; }
    .Landing_Landing__2l03- .Landing_peopleContainer__2mpNn .Landing_peopleIconContainer__DpvDJ {
      display: flex; }
      .Landing_Landing__2l03- .Landing_peopleContainer__2mpNn .Landing_peopleIconContainer__DpvDJ .Landing_peopleIconContent__b7Xk- {
        display: flex;
        flex-direction: column;
        align-items: center; }
      @media (max-width: 480px) {
        .Landing_Landing__2l03- .Landing_peopleContainer__2mpNn .Landing_peopleIconContainer__DpvDJ p {
          text-align: center; } }
      .Landing_Landing__2l03- .Landing_peopleContainer__2mpNn .Landing_peopleIconContainer__DpvDJ .Landing_peopleIconContent__b7Xk-:hover {
        cursor: pointer; }
      .Landing_Landing__2l03- .Landing_peopleContainer__2mpNn .Landing_peopleIconContainer__DpvDJ .Landing_peopleIconLeft__LefJ3 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 50px; }
      .Landing_Landing__2l03- .Landing_peopleContainer__2mpNn .Landing_peopleIconContainer__DpvDJ .Landing_peopleIconRigth__2d9r4 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
  .Landing_Landing__2l03- .Landing_whyContainer__1M0fc {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .Landing_Landing__2l03- .Landing_whyContainer__1M0fc h1 {
      color: #50a7b4; }
    .Landing_Landing__2l03- .Landing_whyContainer__1M0fc .Landing_whyList__KpW_k {
      list-style-type: none;
      text-align: left;
      font-size: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-weight: bold; }
      @media (max-width: 768px) {
        .Landing_Landing__2l03- .Landing_whyContainer__1M0fc .Landing_whyList__KpW_k {
          font-size: 17px;
          padding-left: 0;
          padding-right: 20px;
          margin-left: -20px; } }
      .Landing_Landing__2l03- .Landing_whyContainer__1M0fc .Landing_whyList__KpW_k li {
        display: flex;
        align-items: center; }
    .Landing_Landing__2l03- .Landing_whyContainer__1M0fc .Landing_whyArrowImg__2RpLL {
      transform: rotate(45deg); }
  .Landing_Landing__2l03- .Landing_ownBraceletsContainer__2fjsS {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%; }
    .Landing_Landing__2l03- .Landing_ownBraceletsContainer__2fjsS .Landing_braceletsContainerImg__2Qonf {
      display: flex;
      align-items: center; }
      .Landing_Landing__2l03- .Landing_ownBraceletsContainer__2fjsS .Landing_braceletsContainerImg__2Qonf .Landing_braceletsContainerImgBracelet__1rTL3 {
        -webkit-animation: Landing_floatBracelet__2ZL2N 6s ease-in-out infinite;
                animation: Landing_floatBracelet__2ZL2N 6s ease-in-out infinite;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 250px; }
        @media (max-width: 480px) {
          .Landing_Landing__2l03- .Landing_ownBraceletsContainer__2fjsS .Landing_braceletsContainerImg__2Qonf .Landing_braceletsContainerImgBracelet__1rTL3 {
            margin-bottom: 50px; } }
        .Landing_Landing__2l03- .Landing_ownBraceletsContainer__2fjsS .Landing_braceletsContainerImg__2Qonf .Landing_braceletsContainerImgBracelet__1rTL3 img {
          height: 130px; }
          @media (max-width: 768px) {
            .Landing_Landing__2l03- .Landing_ownBraceletsContainer__2fjsS .Landing_braceletsContainerImg__2Qonf .Landing_braceletsContainerImgBracelet__1rTL3 img {
              width: 210px; } }
        .Landing_Landing__2l03- .Landing_ownBraceletsContainer__2fjsS .Landing_braceletsContainerImg__2Qonf .Landing_braceletsContainerImgBracelet__1rTL3 p {
          color: #448086;
          font-size: 30px; }
      .Landing_Landing__2l03- .Landing_ownBraceletsContainer__2fjsS .Landing_braceletsContainerImg__2Qonf .Landing_braceletsContainerImgHandQR__2ktFQ {
        width: 150px;
        transform: rotate(-10deg);
        margin-left: -2rem; }
        @media (max-width: 480px) {
          .Landing_Landing__2l03- .Landing_ownBraceletsContainer__2fjsS .Landing_braceletsContainerImg__2Qonf .Landing_braceletsContainerImgHandQR__2ktFQ {
            margin-left: -5rem; } }
    .Landing_Landing__2l03- .Landing_ownBraceletsContainer__2fjsS .Landing_braceletsContainerImgArrowLeft__1UPOk {
      margin-top: -200px;
      margin-left: 70px; }
      @media (max-width: 768px) {
        .Landing_Landing__2l03- .Landing_ownBraceletsContainer__2fjsS .Landing_braceletsContainerImgArrowLeft__1UPOk {
          display: none; } }
    .Landing_Landing__2l03- .Landing_ownBraceletsContainer__2fjsS .Landing_braceletsContainerImgArrowMobile__55BMQ {
      margin-top: -90px;
      margin-left: 70px; }
      @media (min-width: 768px) {
        .Landing_Landing__2l03- .Landing_ownBraceletsContainer__2fjsS .Landing_braceletsContainerImgArrowMobile__55BMQ {
          display: none; } }
    .Landing_Landing__2l03- .Landing_ownBraceletsContainer__2fjsS .Landing_content__1AmWW {
      display: flex;
      justify-content: space-around;
      align-items: center; }
      @media (max-width: 768px) {
        .Landing_Landing__2l03- .Landing_ownBraceletsContainer__2fjsS .Landing_content__1AmWW {
          flex-direction: column;
          align-items: center; } }
    .Landing_Landing__2l03- .Landing_ownBraceletsContainer__2fjsS .Landing_contentVideo__2hPm6 {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px; }
      @media (max-width: 768px) {
        .Landing_Landing__2l03- .Landing_ownBraceletsContainer__2fjsS .Landing_contentVideo__2hPm6 {
          flex-direction: column-reverse;
          align-items: center; } }
    .Landing_Landing__2l03- .Landing_ownBraceletsContainer__2fjsS .Landing_ownBraceletsTextContainer__NXg6M {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 600px; }
      @media (max-width: 768px) {
        .Landing_Landing__2l03- .Landing_ownBraceletsContainer__2fjsS .Landing_ownBraceletsTextContainer__NXg6M {
          padding: 0 20px; } }
      .Landing_Landing__2l03- .Landing_ownBraceletsContainer__2fjsS .Landing_ownBraceletsTextContainer__NXg6M h1 {
        color: #50a7b4; }
    .Landing_Landing__2l03- .Landing_ownBraceletsContainer__2fjsS .Landing_iframeVideo__zG3sX {
      width: 600px;
      height: 350px; }
      @media (max-width: 480px) {
        .Landing_Landing__2l03- .Landing_ownBraceletsContainer__2fjsS .Landing_iframeVideo__zG3sX {
          width: 85%;
          height: 200px;
          margin-bottom: 20px; } }
  .Landing_Landing__2l03- .Landing_historyContainer__2kPC8 {
    display: flex;
    background-color: #efe9d6; }
    @media (max-width: 480px) {
      .Landing_Landing__2l03- .Landing_historyContainer__2kPC8 {
        flex-direction: column; } }
    .Landing_Landing__2l03- .Landing_historyContainer__2kPC8 .Landing_historyContainerContent__1qqD- {
      max-width: 65%;
      padding: 10px 60px;
      display: flex;
      flex-direction: column;
      align-items: center; }
      @media (max-width: 480px) {
        .Landing_Landing__2l03- .Landing_historyContainer__2kPC8 .Landing_historyContainerContent__1qqD- {
          max-width: 100%;
          padding: 0 20px; } }
      .Landing_Landing__2l03- .Landing_historyContainer__2kPC8 .Landing_historyContainerContent__1qqD- h1 {
        color: #50a7b4; }
      @media (min-width: 1024px) {
        .Landing_Landing__2l03- .Landing_historyContainer__2kPC8 .Landing_historyContainerContent__1qqD- p {
          max-width: 65%; } }
    .Landing_Landing__2l03- .Landing_historyContainer__2kPC8 .Landing_historyImageContainer__1C52G {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      @media (min-width: 768px) {
        .Landing_Landing__2l03- .Landing_historyContainer__2kPC8 .Landing_historyImageContainer__1C52G {
          margin-right: 15px; } }
      .Landing_Landing__2l03- .Landing_historyContainer__2kPC8 .Landing_historyImageContainer__1C52G img {
        width: 100%;
        height: auto; }
        @media (min-width: 768px) {
          .Landing_Landing__2l03- .Landing_historyContainer__2kPC8 .Landing_historyImageContainer__1C52G img {
            margin-top: 3em;
            margin-bottom: 3em;
            border-radius: 25px; } }
  .Landing_Landing__2l03- .Landing_enviromentContainer__30oHF {
    display: flex;
    justify-content: center;
    background-color: #fff8e3; }
    @media (max-width: 480px) {
      .Landing_Landing__2l03- .Landing_enviromentContainer__30oHF {
        padding-top: 40px; } }
    .Landing_Landing__2l03- .Landing_enviromentContainer__30oHF .Landing_enviromentContainerContent__2cu60 {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center; }
      @media (max-width: 480px) {
        .Landing_Landing__2l03- .Landing_enviromentContainer__30oHF .Landing_enviromentContainerContent__2cu60 {
          flex-direction: column-reverse; } }
    .Landing_Landing__2l03- .Landing_enviromentContainer__30oHF .Landing_enviromentTextContainer__2Qbwv {
      max-width: 65%;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-right: 20px;
      align-items: center; }
      @media (max-width: 480px) {
        .Landing_Landing__2l03- .Landing_enviromentContainer__30oHF .Landing_enviromentTextContainer__2Qbwv {
          max-width: 100%; } }
      .Landing_Landing__2l03- .Landing_enviromentContainer__30oHF .Landing_enviromentTextContainer__2Qbwv h1 {
        color: #50a7b4; }
      @media (min-width: 1024px) {
        .Landing_Landing__2l03- .Landing_enviromentContainer__30oHF .Landing_enviromentTextContainer__2Qbwv p {
          max-width: 70%; } }
    .Landing_Landing__2l03- .Landing_enviromentContainer__30oHF .Landing_enviromentImageContainer__1EsOs {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      @media (min-width: 768px) {
        .Landing_Landing__2l03- .Landing_enviromentContainer__30oHF .Landing_enviromentImageContainer__1EsOs {
          margin-left: 15px; } }
      .Landing_Landing__2l03- .Landing_enviromentContainer__30oHF .Landing_enviromentImageContainer__1EsOs img {
        width: 100%;
        height: auto; }
        @media (min-width: 768px) {
          .Landing_Landing__2l03- .Landing_enviromentContainer__30oHF .Landing_enviromentImageContainer__1EsOs img {
            border-radius: 25px;
            margin-top: 3em;
            margin-bottom: 3em; } }
  .Landing_Landing__2l03- .Landing_sampleContainer__1Vm7F {
    display: flex;
    justify-content: space-around;
    padding: 0 10px 40px 10px;
    width: 100%;
    margin-top: 0.25rem;
    flex-direction: row; }
    @media (max-width: 480px) {
      .Landing_Landing__2l03- .Landing_sampleContainer__1Vm7F {
        flex-direction: column; } }
    .Landing_Landing__2l03- .Landing_sampleContainer__1Vm7F .Landing_contentMock__2330h {
      display: flex;
      justify-content: center;
      position: relative; }
      .Landing_Landing__2l03- .Landing_sampleContainer__1Vm7F .Landing_contentMock__2330h .Landing_sampleImg__6Oxnx {
        height: 350px;
        margin-right: -35px;
        align-self: flex-end;
        padding-right: 1em; }
        @media (min-width: 768px) {
          .Landing_Landing__2l03- .Landing_sampleContainer__1Vm7F .Landing_contentMock__2330h .Landing_sampleImg__6Oxnx {
            height: 400px;
            margin-right: 20px;
            padding-right: 0.5em; } }
        @media (min-width: 1024px) {
          .Landing_Landing__2l03- .Landing_sampleContainer__1Vm7F .Landing_contentMock__2330h .Landing_sampleImg__6Oxnx {
            height: 500px;
            padding-right: 4em; } }
      .Landing_Landing__2l03- .Landing_sampleContainer__1Vm7F .Landing_contentMock__2330h .Landing_sampleImg__6Oxnx:hover {
        cursor: pointer; }
      .Landing_Landing__2l03- .Landing_sampleContainer__1Vm7F .Landing_contentMock__2330h .Landing_mockStepsView__3rpYv {
        height: 325px;
        margin-right: -35px;
        z-index: 30;
        align-self: flex-end; }
        @media (min-width: 768px) {
          .Landing_Landing__2l03- .Landing_sampleContainer__1Vm7F .Landing_contentMock__2330h .Landing_mockStepsView__3rpYv {
            height: 350px;
            margin-right: -2em; } }
        @media (min-width: 1024px) {
          .Landing_Landing__2l03- .Landing_sampleContainer__1Vm7F .Landing_contentMock__2330h .Landing_mockStepsView__3rpYv {
            height: 450px;
            margin-right: -2.50em; } }
      .Landing_Landing__2l03- .Landing_sampleContainer__1Vm7F .Landing_contentMock__2330h .Landing_mockStepsView__3rpYv:hover {
        cursor: pointer; }
      .Landing_Landing__2l03- .Landing_sampleContainer__1Vm7F .Landing_contentMock__2330h .Landing_mockContainerImg__2UEJW {
        position: absolute;
        width: 250px;
        bottom: -8%;
        left: -20%;
        z-index: 50; }
        @media (max-width: 768px) {
          .Landing_Landing__2l03- .Landing_sampleContainer__1Vm7F .Landing_contentMock__2330h .Landing_mockContainerImg__2UEJW {
            width: 160px;
            bottom: -6%;
            left: -17%; } }
        @media (max-width: 480px) {
          .Landing_Landing__2l03- .Landing_sampleContainer__1Vm7F .Landing_contentMock__2330h .Landing_mockContainerImg__2UEJW {
            left: 0; } }
    .Landing_Landing__2l03- .Landing_sampleContainer__1Vm7F .Landing_contentList__LTs8B {
      margin-right: 40px; }
      @media (max-width: 480px) {
        .Landing_Landing__2l03- .Landing_sampleContainer__1Vm7F .Landing_contentList__LTs8B {
          margin-right: 20px;
          margin-left: 20px; } }
      .Landing_Landing__2l03- .Landing_sampleContainer__1Vm7F .Landing_contentList__LTs8B h1 {
        color: #50a7b4; }
        @media (min-width: 768px) {
          .Landing_Landing__2l03- .Landing_sampleContainer__1Vm7F .Landing_contentList__LTs8B h1 {
            padding-left: 66px; } }
      .Landing_Landing__2l03- .Landing_sampleContainer__1Vm7F .Landing_contentList__LTs8B .Landing_content__1AmWW {
        display: flex;
        justify-content: space-around; }
      .Landing_Landing__2l03- .Landing_sampleContainer__1Vm7F .Landing_contentList__LTs8B .Landing_sampleList__jxeBH {
        list-style-type: none;
        text-align: left;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-weight: bold; }
        @media (max-width: 768px) {
          .Landing_Landing__2l03- .Landing_sampleContainer__1Vm7F .Landing_contentList__LTs8B .Landing_sampleList__jxeBH {
            font-size: 17px;
            padding-left: 0; } }
        .Landing_Landing__2l03- .Landing_sampleContainer__1Vm7F .Landing_contentList__LTs8B .Landing_sampleList__jxeBH li {
          display: flex;
          align-items: center; }
  .Landing_Landing__2l03- .Landing_rateUsContainer__3f_Py {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #50a7b4;
    padding-top: 1rem;
    padding-bottom: 5rem;
    margin-bottom: -2px; }
    @media (min-width: 1024px) {
      .Landing_Landing__2l03- .Landing_rateUsContainer__3f_Py {
        padding-top: 3rem;
        padding-bottom: 4rem; } }
  .Landing_Landing__2l03- .Landing_rateUsCopy__1_QSA {
    margin: 2rem; }
  .Landing_Landing__2l03- .Landing_rateIconItem__1_yO4 {
    display: flex;
    justify-content: center; }
    .Landing_Landing__2l03- .Landing_rateIconItem__1_yO4 .Landing_rateIconButton__27f0k {
      background-color: rgba(255, 255, 255, 0.89); }
    .Landing_Landing__2l03- .Landing_rateIconItem__1_yO4 .Landing_rateIcon__3ovBW {
      width: 3.5rem; }
  .Landing_Landing__2l03- .Landing_reviewsContainer__2gcDA {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #50a7b4;
    padding-top: 4rem;
    margin-bottom: -2px; }
  .Landing_Landing__2l03- .Landing_reviewGridItem__3ZZ_9 {
    margin: 1rem; }
    .Landing_Landing__2l03- .Landing_reviewGridItem__3ZZ_9 button {
      font-size: 30px;
      background-color: transparent;
      color: #fff8e3;
      border: none;
      padding: 10px 20px;
      border-radius: 6px;
      cursor: pointer; }
    .Landing_Landing__2l03- .Landing_reviewGridItem__3ZZ_9 .Landing_reviewContainerContent__1u21q {
      display: flex;
      align-items: center;
      flex-direction: column; }
      .Landing_Landing__2l03- .Landing_reviewGridItem__3ZZ_9 .Landing_reviewContainerContent__1u21q .Landing_reviewHead__3r8Hk {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #ffffff; }
      .Landing_Landing__2l03- .Landing_reviewGridItem__3ZZ_9 .Landing_reviewContainerContent__1u21q .Landing_reviewLogo__1Re35 {
        width: 150px;
        height: 150px;
        object-fit: cover;
        background-color: #ffffff;
        border-radius: 50%; }
        @media (max-width: 768px) {
          .Landing_Landing__2l03- .Landing_reviewGridItem__3ZZ_9 .Landing_reviewContainerContent__1u21q .Landing_reviewLogo__1Re35 {
            width: 80px;
            height: 80px; } }
      .Landing_Landing__2l03- .Landing_reviewGridItem__3ZZ_9 .Landing_reviewContainerContent__1u21q .Landing_reviewDataContainer__1xvOc {
        display: flex;
        color: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: start; }
        @media (max-width: 768px) {
          .Landing_Landing__2l03- .Landing_reviewGridItem__3ZZ_9 .Landing_reviewContainerContent__1u21q .Landing_reviewDataContainer__1xvOc {
            max-width: 500px;
            margin-top: 20px;
            text-align: justify; } }
        .Landing_Landing__2l03- .Landing_reviewGridItem__3ZZ_9 .Landing_reviewContainerContent__1u21q .Landing_reviewDataContainer__1xvOc .Landing_reviewTitle__2lrHk {
          margin-top: 1rem;
          color: #ffffff;
          text-align: center; }
        .Landing_Landing__2l03- .Landing_reviewGridItem__3ZZ_9 .Landing_reviewContainerContent__1u21q .Landing_reviewDataContainer__1xvOc .Landing_starsContainer__15LFR {
          display: flex;
          align-items: center; }
        .Landing_Landing__2l03- .Landing_reviewGridItem__3ZZ_9 .Landing_reviewContainerContent__1u21q .Landing_reviewDataContainer__1xvOc a {
          text-decoration: underline;
          color: white;
          margin-bottom: 10px; }
        .Landing_Landing__2l03- .Landing_reviewGridItem__3ZZ_9 .Landing_reviewContainerContent__1u21q .Landing_reviewDataContainer__1xvOc p {
          font-weight: bold;
          font-size: 17px;
          margin-bottom: 8px; }
        @media (max-width: 768px) {
          .Landing_Landing__2l03- .Landing_reviewGridItem__3ZZ_9 .Landing_reviewContainerContent__1u21q .Landing_reviewDataContainer__1xvOc h6 {
            max-width: 200px;
            text-align: start;
            margin: 10px 0 0; } }
  .Landing_Landing__2l03- .Landing_newsContainer__M7K1q {
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    width: 100%; }
    .Landing_Landing__2l03- .Landing_newsContainer__M7K1q button {
      font-size: 30px;
      background-color: transparent;
      color: #fff8e3;
      border: none;
      padding: 10px 20px;
      border-radius: 6px;
      cursor: pointer; }
    .Landing_Landing__2l03- .Landing_newsContainer__M7K1q .Landing_newsContainerContent__2_Kew {
      display: flex;
      align-items: center;
      background-color: #50a7b4;
      padding-bottom: 3rem;
      min-height: 20rem;
      padding-left: 150px;
      padding-right: 150px; }
      @media (max-width: 768px) {
        .Landing_Landing__2l03- .Landing_newsContainer__M7K1q .Landing_newsContainerContent__2_Kew {
          padding-right: 10px;
          padding-left: 10px;
          flex-direction: column;
          min-height: 27rem; } }
      .Landing_Landing__2l03- .Landing_newsContainer__M7K1q .Landing_newsContainerContent__2_Kew .Landing_newsLogo__qOeGf {
        width: 150px;
        height: 150px;
        object-fit: contain;
        background-color: #ffffff;
        border-radius: 50%; }
        @media (max-width: 768px) {
          .Landing_Landing__2l03- .Landing_newsContainer__M7K1q .Landing_newsContainerContent__2_Kew .Landing_newsLogo__qOeGf {
            width: 50px;
            height: 50px;
            position: absolute;
            margin: 15px 0 0 90px; } }
      .Landing_Landing__2l03- .Landing_newsContainer__M7K1q .Landing_newsContainerContent__2_Kew .Landing_newsDataContainer__1C4ws {
        padding-left: 50px;
        display: flex;
        color: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        text-align: start; }
        @media (max-width: 768px) {
          .Landing_Landing__2l03- .Landing_newsContainer__M7K1q .Landing_newsContainerContent__2_Kew .Landing_newsDataContainer__1C4ws {
            max-width: 500px;
            margin-top: 20px;
            margin-right: 50px;
            text-align: justify; } }
        .Landing_Landing__2l03- .Landing_newsContainer__M7K1q .Landing_newsContainerContent__2_Kew .Landing_newsDataContainer__1C4ws a {
          text-decoration: underline;
          color: white;
          margin-bottom: 10px; }
        .Landing_Landing__2l03- .Landing_newsContainer__M7K1q .Landing_newsContainerContent__2_Kew .Landing_newsDataContainer__1C4ws p {
          font-weight: bold;
          font-size: 17px;
          margin-bottom: 8px;
          margin-top: 30px; }
        @media (max-width: 768px) {
          .Landing_Landing__2l03- .Landing_newsContainer__M7K1q .Landing_newsContainerContent__2_Kew .Landing_newsDataContainer__1C4ws h2 {
            max-width: 200px;
            text-align: start;
            margin: 10px 0 0; } }
  .Landing_Landing__2l03- .Landing_footerContainer__1RFW- {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    @media (max-width: 768px) {
      .Landing_Landing__2l03- .Landing_footerContainer__1RFW- {
        align-items: center; } }
    .Landing_Landing__2l03- .Landing_footerContainer__1RFW- .Landing_sevi__XTkWl {
      max-width: 85%;
      cursor: pointer;
      align-self: center;
      color: #716b77;
      margin-top: 20px;
      text-align: center; }
    .Landing_Landing__2l03- .Landing_footerContainer__1RFW- .Landing_codeModal__1nMy6 {
      cursor: pointer;
      align-self: center;
      margin: 10px;
      text-align: center;
      text-decoration: underline; }
    .Landing_Landing__2l03- .Landing_footerContainer__1RFW- .Landing_footerContent__2tFGm {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center; }
      @media (max-width: 768px) {
        .Landing_Landing__2l03- .Landing_footerContainer__1RFW- .Landing_footerContent__2tFGm {
          flex-direction: column; } }
      .Landing_Landing__2l03- .Landing_footerContainer__1RFW- .Landing_footerContent__2tFGm .Landing_footerLawsContainer__3Wyza {
        display: flex;
        margin: 0.5rem; }
        .Landing_Landing__2l03- .Landing_footerContainer__1RFW- .Landing_footerContent__2tFGm .Landing_footerLawsContainer__3Wyza p {
          margin: 0.25rem;
          font-size: 15px;
          color: #716b77; }
      .Landing_Landing__2l03- .Landing_footerContainer__1RFW- .Landing_footerContent__2tFGm .Landing_seviLogo__2-WYC {
        width: 150px; }
      .Landing_Landing__2l03- .Landing_footerContainer__1RFW- .Landing_footerContent__2tFGm .Landing_footerLogosContainer__1IT39 {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 25rem;
        margin-top: 2rem; }
        @media (max-width: 480px) {
          .Landing_Landing__2l03- .Landing_footerContainer__1RFW- .Landing_footerContent__2tFGm .Landing_footerLogosContainer__1IT39 {
            flex-direction: column; } }
        .Landing_Landing__2l03- .Landing_footerContainer__1RFW- .Landing_footerContent__2tFGm .Landing_footerLogosContainer__1IT39 img {
          margin: 1rem; }
      .Landing_Landing__2l03- .Landing_footerContainer__1RFW- .Landing_footerContent__2tFGm .Landing_footerContentServices__1NBZm {
        display: flex;
        flex-direction: column; }
        .Landing_Landing__2l03- .Landing_footerContainer__1RFW- .Landing_footerContent__2tFGm .Landing_footerContentServices__1NBZm div {
          max-width: 400px; }

.Landing_modalInfoHeader__19vfG {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px; }

.Landing_modalInfoContent__3834r {
  display: flex;
  align-items: center;
  justify-content: space-around; }
  @media (max-width: 768px) {
    .Landing_modalInfoContent__3834r {
      flex-direction: column; } }
  .Landing_modalInfoContent__3834r li {
    font-size: 20px;
    margin-top: 10px; }
  .Landing_modalInfoContent__3834r .Landing_imageHand__3gJik {
    width: 15rem;
    height: 15rem; }
  .Landing_modalInfoContent__3834r .Landing_imageKid__MlL5x {
    width: 11rem;
    height: 22rem; }

.Landing_seeMoreText__3X8tJ {
  text-decoration: underline; }

.Landing_cookiesLayer__34im2 {
  width: 100%;
  height: 100%;
  background: rgba(128, 128, 128, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 6000; }
  .Landing_cookiesLayer__34im2 .Landing_snackbar__Bgr6J {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 6rem;
    width: 100%;
    height: 22rem;
    font-size: 1.2rem;
    text-align: center; }
    @media (max-width: 1024px) {
      .Landing_cookiesLayer__34im2 .Landing_snackbar__Bgr6J {
        width: 75%;
        padding: 2rem; } }
    .Landing_cookiesLayer__34im2 .Landing_snackbar__Bgr6J .Landing_moreInfoCookieText__1xKAq {
      color: white;
      margin: 5px; }
    .Landing_cookiesLayer__34im2 .Landing_snackbar__Bgr6J button {
      background-color: transparent;
      color: white;
      border: none; }

.Landing_finalImgContainer__1E6fa {
  position: relative;
  margin: 0; }
  .Landing_finalImgContainer__1E6fa .Landing_asterisco__qHUay {
    position: absolute;
    bottom: 2px;
    font-size: 15px !important;
    color: #efe9d6;
    right: 20%; }
    @media (max-width: 768px) {
      .Landing_finalImgContainer__1E6fa .Landing_asterisco__qHUay {
        right: 41%; } }

.Landing_cookiesMoreInfoButton__1_Bm2 {
  background-color: #50a7b4 !important; }

.Landing_cookiesMoreInfoButton__1_Bm2:hover {
  background-color: #448086 !important; }

.Landing_privacy__1aOre {
  cursor: pointer; }

.Landing_privacy__1aOre:hover {
  text-decoration: underline; }

.Landing_shareButtonsBox__1l5_S {
  padding: 2em;
  padding-bottom: 1em !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly; }

.Landing_shareButton__D60al:hover {
  cursor: pointer; }

.Landing_floatingButtonGetIt__3LjtU, .Landing_floatingButtonGetItLogged__2eAex {
  position: fixed !important;
  bottom: 1em !important;
  right: 1em !important;
  box-shadow: 3px 7px 20px 0px black;
  color: #448086 !important;
  background-color: #fff8e3 !important;
  z-index: 10; }
  @media (min-width: 768px) {
    .Landing_floatingButtonGetIt__3LjtU, .Landing_floatingButtonGetItLogged__2eAex {
      display: none !important; } }

.Landing_floatingButtonGetItLogged__2eAex {
  bottom: 5em !important; }

.Landing_floatingButtonGetItIcon__2LkVD {
  margin-right: 0.3em; }

.Landing_companiesTrustContainer__2vj97 {
  margin-top: 3em !important;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 768px) {
    .Landing_companiesTrustContainer__2vj97 {
      padding-bottom: 5em;
      flex-direction: column; } }
  .Landing_companiesTrustContainer__2vj97 .Landing_companiesTrustContent__1sg5Z {
    display: flex;
    align-items: center; }
  .Landing_companiesTrustContainer__2vj97 .Landing_companiesTrustLogoEnisa__2eYnE {
    width: 150px;
    margin-right: 1.25rem;
    height: auto; }
    @media (max-width: 480px) {
      .Landing_companiesTrustContainer__2vj97 .Landing_companiesTrustLogoEnisa__2eYnE {
        max-height: 8rem;
        margin-right: 0;
        width: 55px; } }
  .Landing_companiesTrustContainer__2vj97 .Landing_companiesTrustLogo__22R64 {
    width: 190px;
    height: auto; }
    @media (max-width: 480px) {
      .Landing_companiesTrustContainer__2vj97 .Landing_companiesTrustLogo__22R64 {
        max-height: 8rem;
        width: 105px; } }

.Landing_companiesTrustClaim__2Rwt3 {
  color: #ffffff !important;
  font-size: 80px !important;
  text-align: center !important;
  padding-bottom: 6rem !important;
  margin-top: 1rem !important;
  min-width: 75%;
  max-width: 90%;
  border-bottom: 0.25rem solid #fff; }
  @media (max-width: 480px) {
    .Landing_companiesTrustClaim__2Rwt3 {
      min-width: 90% !important;
      max-width: 90% !important;
      font-size: 30px !important;
      padding-bottom: 2.25rem !important;
      margin-bottom: 1rem !important; } }

.Landing_floatingActiveBraceletButton__3UBxj {
  position: fixed !important;
  border-radius: 25px !important;
  background-color: #fff8e3 !important;
  color: #448086 !important;
  bottom: 1em;
  right: 1em;
  box-shadow: 3px 7px 20px 0px black; }

.Landing_activeIcon__2pIac {
  color: #448086 !important;
  margin-right: 0.5em !important; }

.Landing_FbLikeButtonContainer__deK7I {
  padding-top: 1em; }
  @media (min-width: 768px) {
    .Landing_FbLikeButtonContainer__deK7I {
      padding-top: 3em; } }

.Landing_facebookLikeButton__36drz {
  display: flex !important;
  align-items: center !important;
  margin-top: 2em !important;
  padding-right: 1.5em !important;
  font-size: 11px !important;
  background-color: #3C5A99 !important;
  border-radius: 6px; }
  @media (max-width: 768px) {
    .Landing_facebookLikeButton__36drz {
      display: none !important; } }
  @media (min-width: 768px) {
    .Landing_facebookLikeButton__36drz {
      font-size: 13px !important;
      padding-right: 1.7em !important; } }

.Landing_facebookLike__2Nb4W {
  height: 30px;
  width: 30px; }
  @media (min-width: 768px) {
    .Landing_facebookLike__2Nb4W {
      height: 45px;
      width: 45px; } }

.Landing_pulsidHeadImage__1LCcM {
  display: none;
  height: 100px;
  width: 135px; }
  @media (max-width: 768px) {
    .Landing_pulsidHeadImage__1LCcM {
      display: block; } }

.Landing_pulsidPriceBadgeDesktop__2svK6 {
  min-width: 4rem;
  min-height: 4rem;
  background-color: #c3dd36;
  font-size: large !important;
  font-weight: bold; }
  @media (max-width: 768px) {
    .Landing_pulsidPriceBadgeDesktop__2svK6 {
      display: none !important; } }

.Landing_pulsidPriceBadgeAlways__1OQsL {
  min-width: 4rem;
  min-height: 4rem;
  background-color: #c3dd36;
  font-size: large !important;
  font-weight: bold; }

.Landing_pulsidPriceBadge__2SRCI {
  display: none !important; }
  @media (max-width: 768px) {
    .Landing_pulsidPriceBadge__2SRCI {
      display: block !important; } }
  .Landing_pulsidPriceBadge__2SRCI span:last-child {
    min-width: 3rem;
    background-color: #c3dd36 !important;
    font-size: medium;
    font-weight: bold;
    min-height: 3rem; }

.Landing_discountSticker__13VXr {
  position: absolute;
  right: -25px;
  top: -25px;
  border-radius: 50%;
  font-size: 15px;
  padding: 1.3em 1em;
  background-color: #ff5a5a;
  color: #ffffff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12); }

.Landing_offerSnackbar__3NThD {
  width: 100%;
  max-width: 100% !important;
  text-align: center;
  font-size: 17px !important; }
  .Landing_offerSnackbar__3NThD div:first-child {
    width: 100%;
    max-width: 100% !important;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    font-size: 17px !important; }

.Landing_snackOfferButton__1C8iK {
  background-color: #448086;
  color: #fff8e3; }

.Landing_offerBanner__1J8Si {
  position: fixed;
  display: flex;
  z-index: 55;
  bottom: 0;
  width: 100%;
  padding: 1em;
  text-align: center;
  font-size: 20px;
  background-color: #ff0012;
  color: white; }
  @media (max-width: 768px) {
    .Landing_offerBanner__1J8Si {
      flex-direction: column;
      display: none; } }

.Landing_offerBannerMobile__1jGa5 {
  position: fixed;
  display: none;
  z-index: 55;
  bottom: 0;
  width: 100%;
  padding: 1em;
  text-align: center;
  font-size: 20px;
  background-color: #FF0012;
  color: white; }
  @media (max-width: 768px) {
    .Landing_offerBannerMobile__1jGa5 {
      display: flex;
      justify-content: space-around; } }

.Landing_closeOfferBanner__b5aOl {
  position: absolute;
  right: 25px;
  top: 8px; }
  @media (max-width: 768px) {
    .Landing_closeOfferBanner__b5aOl {
      position: absolute;
      right: 5px;
      top: 5px; } }

.Landing_offerBannerCountdown__-k0Te {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 768px) {
    .Landing_offerBannerCountdown__-k0Te {
      margin-top: 1.4em !important; } }

@media (max-width: 768px) {
  .Landing_offerBannerCountdown__-k0Te, .Landing_offerClaim__14yT6 {
    display: flex;
    flex-direction: column; } }

.Landing_offerClaim__14yT6 span:first-child {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    .Landing_offerClaim__14yT6 span:first-child {
      display: inline-flex !important; } }

.Landing_offerButtonBox__3Ed5S {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 768px) {
    .Landing_offerButtonBox__3Ed5S {
      margin-top: 1em !important; } }

.Landing_offerBannerGetItButton__3pyR6 {
  background-color: #00B32C !important;
  color: #ffffff !important; }

.Landing_closeOfferBannerIcon__167Fc {
  color: #ffffff; }

.Landing_whatsappPostSection__ecMQC {
  display: flex;
  justify-content: space-around;
  background-color: #74a7b1;
  width: 100%;
  padding-top: 2rem;
  margin-bottom: -2px;
  color: #fff; }

.Landing_whatsappPostClaim__ROhtv {
  text-align: center !important; }

.Landing_whatsappPostItem__1SWRU {
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 2rem !important; }
  .Landing_whatsappPostItem__1SWRU .Landing_downloadDocumentButton__3z1NT {
    background-color: #fff8e3; }

.Landing_whatsappPostImage__NEZvN {
  height: 250px; }

.Landing_newsletterSectionGrid__2Wx9I {
  padding-top: 0;
  padding-bottom: 2rem;
  margin-top: -4px;
  margin-bottom: -4px;
  background-color: #ffffff; }
  @media (min-width: 768px) {
    .Landing_newsletterSectionGrid__2Wx9I {
      background-color: #4FA9B3;
      padding-top: 2rem;
      padding-bottom: 2rem; } }

.Landing_freeShippingClaim__3XqHj {
  color: #ffffff;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: 'Roboto Bold', sans-serif;
  padding-left: 0.4rem;
  padding-right: 1.2rem; }
  .Landing_freeShippingClaim__3XqHj .Landing_countDown__LoUbN {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    font-family: 'Roboto Bold', sans-serif !important;
    font-size: 17px; }
    @media (min-width: 768px) {
      .Landing_freeShippingClaim__3XqHj .Landing_countDown__LoUbN {
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 30px; } }
  .Landing_freeShippingClaim__3XqHj span {
    font-family: 'Roboto Bold', sans-serif !important;
    font-size: 20px; }
    @media (min-width: 768px) {
      .Landing_freeShippingClaim__3XqHj span {
        font-size: 30px; } }

.Landing_CTAContainer__2Nc3E {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em !important; }
  .Landing_CTAContainer__2Nc3E .Landing_tapIcon__ARy4p {
    margin-left: 0.5rem;
    font-size: 2rem;
    transform: rotate(-25deg); }
  .Landing_CTAContainer__2Nc3E .Landing_primaryCTAButton__J6hLh {
    color: #ffffff;
    background: #FF6966;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    padding: 1em 2.5em;
    border-radius: 10px;
    cursor: pointer;
    font-size: 30px; }
    @media (max-width: 480px) {
      .Landing_CTAContainer__2Nc3E .Landing_primaryCTAButton__J6hLh {
        font-size: 20px; } }
  .Landing_CTAContainer__2Nc3E .Landing_primaryCTAButton__J6hLh:hover {
    background-color: #ea605d; }
  .Landing_CTAContainer__2Nc3E .Landing_secondaryCTAButton__2v-ZL {
    color: #50a7b4 !important;
    border: 2px solid !important;
    padding: 1em 2.5em !important;
    border-radius: 10px !important;
    cursor: pointer !important;
    font-size: 30px !important; }
    @media (max-width: 480px) {
      .Landing_CTAContainer__2Nc3E .Landing_secondaryCTAButton__2v-ZL {
        font-size: 20px !important; } }

.Landing_buyedClaimContainer__3Nz5T {
  display: flex;
  justify-content: center;
  margin-bottom: 0.25rem !important; }
  @media (min-width: 1024px) {
    .Landing_buyedClaimContainer__3Nz5T {
      margin-bottom: 1.25rem !important; } }

.Landing_buyedClaimBox__1AG0o, .Landing_buyNowClaimBox__AQSl3, .Landing_womenDayBanner__2AUKJ {
  width: 100%;
  height: 2.5rem;
  color: #000;
  background-color: #ffffff;
  font-size: 17px;
  text-align: center;
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: 5rem; }
  @media (min-width: 768px) {
    .Landing_buyedClaimBox__1AG0o, .Landing_buyNowClaimBox__AQSl3, .Landing_womenDayBanner__2AUKJ {
      font-size: 20px;
      margin-right: -1rem;
      margin-left: -1rem; } }

.Landing_buyNowClaimBox__AQSl3 {
  background-color: #FF6966; }

.Landing_womenDayBanner__2AUKJ {
  background-color: #d228ac; }

.Landing_countUp__2J_dO {
  margin-top: 3rem !important;
  color: #fff;
  font-size: 70px !important;
  text-align: center; }
  @media (min-width: 768px) {
    .Landing_countUp__2J_dO {
      margin-top: 6rem !important; } }

.Landing_footerVideo__t9Egc {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 3rem; }
  @media (min-width: 1024px) {
    .Landing_footerVideo__t9Egc {
      width: 50%;
      box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.13); } }

.Landing_navidadCollage__2BJTI {
  width: 11rem;
  margin-top: 2rem; }
  @media (min-width: 1024px) {
    .Landing_navidadCollage__2BJTI {
      margin-top: 6rem;
      width: 14rem; } }

/*font-family*/
/*font-size*/
/*font-weight*/
.Snack_snackParentWrapper__XFotv {
  z-index: 300000 !important; }

.Snack_success__1Yr4G {
  z-index: 200000 !important; }
  .Snack_success__1Yr4G div {
    background-color: #4fb457;
    font-weight: bold; }

.Snack_warning__23BzA {
  z-index: 200000 !important; }
  .Snack_warning__23BzA div {
    background-color: #ffac00;
    font-weight: bold; }

.Snack_error__1T7IV {
  z-index: 200000 !important; }
  .Snack_error__1T7IV div {
    background-color: #dd2c01;
    font-weight: bold; }

.Snack_snackButton__caI0I {
  z-index: 200000 !important;
  color: #fff !important;
  border-color: #fff !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
@media (min-width: 1024px) {
  .BuyModals_mainDialogContent__tZ6zG {
    border-radius: 8px;
    margin: 0 8rem;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; } }

.BuyModals_parentContainer__zwrVd {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: inherit !important; }

.BuyModals_termsContainer__2NeOf {
  display: flex;
  flex-direction: column;
  align-items: center; }

.BuyModals_formControl__2eft2 {
  margin-top: 8px;
  min-width: 120px;
  display: flex !important; }
  .BuyModals_formControl__2eft2 label {
    color: #50a7b4 !important; }
  .BuyModals_formControl__2eft2 div fieldset {
    border-color: #50a7b4 !important; }
  .BuyModals_formControl__2eft2 svg {
    color: #50a7b4 !important; }

.BuyModals_checkbox__3AWri {
  color: #50a7b4 !important; }

.BuyModals_countryGridItem__3PWND {
  visibility: hidden; }

.BuyModals_formRedsys__2a2AL {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 1rem; }

.BuyModals_cityZipcodeContainer__fbohb {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.BuyModals_privacyLink__G1qrL {
  color: #716b77; }

.BuyModals_privacyLink__G1qrL:hover {
  color: #0077b5;
  text-decoration: underline; }

.BuyModals_closeModalIcon__3-J2a {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.BuyModals_fixModalPadding__WuiXj {
  padding: 0 !important; }

.BuyModals_paymentModal__HKo3V {
  padding: 0 !important; }
  .BuyModals_paymentModal__HKo3V [class^="MuiPaper-root"] {
    min-width: 350px; }
  .BuyModals_paymentModal__HKo3V .BuyModals_paymentModalTitleBox__1KX_9 {
    display: flex;
    align-items: center;
    margin-top: -20px; }

.BuyModals_sendingDataAnimation__5Ve7U {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.BuyModals_textField__2Hsrp label {
  color: #50a7b4 !important; }

.BuyModals_textField__2Hsrp div fieldset {
  border-color: #50a7b4 !important; }

.BuyModals_textFieldError__1a7QL label {
  color: #ff3d00 !important; }

.BuyModals_textFieldError__1a7QL div fieldset {
  border-color: #ff3d00 !important; }

.BuyModals_contentText__3tZq3 {
  margin-bottom: 10px !important; }

.BuyModals_contentInnerText__1qtSl {
  margin-left: 8px !important;
  font-size: 2.2rem !important;
  align-self: start !important; }

.BuyModals_formContainer__1jlOP {
  display: flex;
  flex-direction: column !important;
  flex-wrap: wrap;
  justify-content: space-evenly; }

.BuyModals_sizeCardContainer__ypXLn {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  justify-content: space-around; }
  @media (max-width: 480px) {
    .BuyModals_sizeCardContainer__ypXLn {
      flex-direction: column-reverse;
      margin-top: 10px !important; } }
  .BuyModals_sizeCardContainer__ypXLn .BuyModals_sizeCard__2iZAR {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    box-shadow: none; }
    @media (max-width: 480px) {
      .BuyModals_sizeCardContainer__ypXLn .BuyModals_sizeCard__2iZAR {
        margin: 1.00em;
        padding: 1em; } }
    .BuyModals_sizeCardContainer__ypXLn .BuyModals_sizeCard__2iZAR .BuyModals_pictureContainer__AxOD6 {
      display: flex;
      justify-content: center; }
    .BuyModals_sizeCardContainer__ypXLn .BuyModals_sizeCard__2iZAR .BuyModals_sizeCardImg__TxxvI {
      width: 250px;
      margin: 1em !important;
      border-radius: 4px; }
      @media (max-width: 480px) {
        .BuyModals_sizeCardContainer__ypXLn .BuyModals_sizeCard__2iZAR .BuyModals_sizeCardImg__TxxvI {
          width: 190px; } }
    .BuyModals_sizeCardContainer__ypXLn .BuyModals_sizeCard__2iZAR .BuyModals_sizeCardSize__1Cpju {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #448086; }
      .BuyModals_sizeCardContainer__ypXLn .BuyModals_sizeCard__2iZAR .BuyModals_sizeCardSize__1Cpju .BuyModals_braceletSizeBox__g15-C {
        display: flex;
        align-items: center;
        flex-direction: row;
        color: #448086;
        margin-top: 5px;
        margin-bottom: 10px; }
      .BuyModals_sizeCardContainer__ypXLn .BuyModals_sizeCard__2iZAR .BuyModals_sizeCardSize__1Cpju .BuyModals_braceletSizeCode__3Bmj0 {
        margin-top: 10px; }
      .BuyModals_sizeCardContainer__ypXLn .BuyModals_sizeCard__2iZAR .BuyModals_sizeCardSize__1Cpju svg {
        font-size: 15px; }
    .BuyModals_sizeCardContainer__ypXLn .BuyModals_sizeCard__2iZAR p {
      color: #448086;
      font-weight: bold; }
    .BuyModals_sizeCardContainer__ypXLn .BuyModals_sizeCard__2iZAR .BuyModals_sizePicker__2njtC {
      margin: 1em; }
      .BuyModals_sizeCardContainer__ypXLn .BuyModals_sizeCard__2iZAR .BuyModals_sizePicker__2njtC label {
        font-size: 15px;
        margin-left: -3px; }
  .BuyModals_sizeCardContainer__ypXLn .BuyModals_braceletsSelectorGrid__fyrfD {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center; }
    @media (max-width: 480px) {
      .BuyModals_sizeCardContainer__ypXLn .BuyModals_braceletsSelectorGrid__fyrfD {
        flex-direction: column-reverse; } }

.BuyModals_sizeSelectorActions__mvprL {
  display: flex; }

@media (min-width: 768px) {
  .BuyModals_sizeSelectorMobileButton__GGg9F {
    display: none !important; } }

.BuyModals_sizeStock__bscf6 {
  color: red !important;
  margin: 0;
  padding-bottom: 0.5rem; }

.BuyModals_sizeCardContainerCart__2oOtZ {
  justify-content: center;
  margin-bottom: 1em !important;
  flex-direction: column-reverse;
  align-items: flex-end; }
  .BuyModals_sizeCardContainerCart__2oOtZ .BuyModals_sizeCardCart__lOQkN {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-left: solid 5px #50a7b4; }
    @media (min-width: 480px) {
      .BuyModals_sizeCardContainerCart__2oOtZ .BuyModals_sizeCardCart__lOQkN {
        padding: 0 1rem;
        margin: 1rem; } }
    @media (max-width: 480px) {
      .BuyModals_sizeCardContainerCart__2oOtZ .BuyModals_sizeCardCart__lOQkN {
        width: 100% !important; } }
    .BuyModals_sizeCardContainerCart__2oOtZ .BuyModals_sizeCardCart__lOQkN .BuyModals_sizeCardImg__TxxvI {
      width: 100px;
      margin: 1em !important; }
      @media (max-width: 480px) {
        .BuyModals_sizeCardContainerCart__2oOtZ .BuyModals_sizeCardCart__lOQkN .BuyModals_sizeCardImg__TxxvI {
          width: 90px; } }
    .BuyModals_sizeCardContainerCart__2oOtZ .BuyModals_sizeCardCart__lOQkN p, .BuyModals_sizeCardContainerCart__2oOtZ .BuyModals_sizeCardCart__lOQkN span {
      color: #448086;
      font-weight: bold;
      margin: 5px; }
    .BuyModals_sizeCardContainerCart__2oOtZ .BuyModals_sizeCardCart__lOQkN span {
      margin-top: 5px; }
    .BuyModals_sizeCardContainerCart__2oOtZ .BuyModals_sizeCardCart__lOQkN .BuyModals_productImageBox__200at {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .BuyModals_sizeCardContainerCart__2oOtZ .BuyModals_sizeCardCart__lOQkN .BuyModals_productDetails__1F8Xl {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }

.BuyModals_cartItem__6owkf {
  display: flex !important;
  justify-content: center;
  padding: 10px 0 !important; }

.BuyModals_discountContainer__28X99 {
  display: flex;
  align-items: center; }
  .BuyModals_discountContainer__28X99 .BuyModals_discountButton__1TVtq {
    margin-left: 10px;
    border: 1px solid #50a7b4;
    color: #50a7b4 !important; }
  .BuyModals_discountContainer__28X99 .BuyModals_discountButton__1TVtq:disabled {
    border: 1px solid #aaaaaa;
    color: #e0e0e0 !important; }

.BuyModals_snackTotalBraceletsMessage__3JroY {
  font-weight: bold; }

.BuyModals_snackTotalBracelets__2CENM {
  padding: 15px; }
  .BuyModals_snackTotalBracelets__2CENM button {
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer; }
  .BuyModals_snackTotalBracelets__2CENM span {
    background-color: darkorange; }
  .BuyModals_snackTotalBracelets__2CENM a {
    color: #fff; }
  .BuyModals_snackTotalBracelets__2CENM a:hover {
    color: blue; }

.BuyModals_packagingSection__Gchq4 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }

.BuyModals_packagingCheck__3tbvO {
  align-self: flex-start !important; }

.BuyModals_packagingCheckLabel__2gfAb {
  color: #716b77 !important; }

.BuyModals_packagingQuantitySelector__1KoR1 {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 75%;
  padding: 5px; }
  @media (max-width: 480px) {
    .BuyModals_packagingQuantitySelector__1KoR1 {
      width: 75%; } }

.BuyModals_packagingImgContainer__3Qf0_ {
  display: flex;
  align-items: center;
  justify-content: center; }

.BuyModals_packagingImg__nGckm {
  width: 270px;
  height: auto;
  border-radius: 15px;
  margin: 1em !important; }
  @media (max-width: 480px) {
    .BuyModals_packagingImg__nGckm {
      height: auto;
      width: 220px; } }

.BuyModals_packagingInput__1TrRk {
  width: 100px; }

.BuyModals_packagingCartImg__3_cv2 {
  margin-top: 0.5em;
  width: 100%;
  border-radius: 15px; }
  @media (max-width: 480px) {
    .BuyModals_packagingCartImg__3_cv2 {
      width: 150px; } }

.BuyModals_priceChip__1DxWO {
  margin-bottom: 1em;
  margin-top: 0.5em;
  font-size: 1.5rem !important; }

.BuyModals_totalPriceChip__1y_AA {
  padding: 0.5rem !important;
  font-size: 1rem !important;
  color: #ffffff !important;
  background-color: #50a7b4 !important;
  margin-left: 1em; }

.BuyModals_oldPriceChip__1wUGk {
  margin-bottom: 1em;
  margin-top: 0.5em;
  text-decoration: line-through !important; }

.BuyModals_totalPriceWrapper__2UfzK {
  margin-top: 1em;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-right: 0.5rem;
  flex-direction: column; }

.BuyModals_parentShippingPriceBox__1a2-b {
  display: flex;
  justify-content: center;
  align-items: center; }

.BuyModals_shippingPriceBox__qrpIc {
  background-color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-left: 5px solid #CCBD88;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
  .BuyModals_shippingPriceBox__qrpIc h6 {
    text-align: center; }
  .BuyModals_shippingPriceBox__qrpIc .BuyModals_centerContainer__17Msm {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center; }

.BuyModals_offerCard__1TvED {
  margin: auto;
  width: 75%;
  flex-direction: column;
  padding: 1em !important;
  text-align: center !important;
  border-radius: 50px !important;
  margin-bottom: 0.5em !important;
  margin-top: 0.5em !important;
  background-image: linear-gradient(-20deg, #fdbeb0 0%, #fdf8bd 100%); }
  @media (min-width: 768px) {
    .BuyModals_offerCard__1TvED {
      width: 25%; } }

.BuyModals_dialogActions__1sTow, .BuyModals_dialogActionsFirstStep__3Dj-y {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  padding: 0.5em;
  box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  margin: 0 !important;
  z-index: 300;
  text-align: center; }

.BuyModals_dialogActionButton__GQawM {
  padding: 0.65em 3em !important;
  margin: 5px !important;
  color: #ffffff !important;
  font-size: 20px !important;
  font-weight: bolder !important;
  background-color: #8BC34A !important; }

.BuyModals_dialogActionButton__GQawM:disabled {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  background-color: transparent !important;
  background-color: initial !important;
  color: #808080 !important; }

.BuyModals_disabledButton__2qyNh {
  padding: 0.65em 3em !important;
  margin: 5px !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  background-color: transparent !important;
  color: #808080 !important;
  box-shadow: none !important;
  font-size: 20px !important;
  font-weight: bolder !important;
  cursor: default !important; }

.BuyModals_loadingPaymentActionsModal__2LN9O [class^="MuiPaper-root"] {
  transition: height .45s ease-in-out !important; }

.BuyModals_redsysAnimationContainer__1IK4s {
  transition: opacity .45s ease-in-out !important;
  opacity: 0;
  text-align: center; }

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .BuyModals_cityZipcodeContainer__fbohb {
    display: flex;
    background-color: #4fb457;
    flex-direction: column;
    justify-content: space-between; } }

.BuyModals_cartResumeListTitle__1aLs9 {
  color: #000000 !important;
  font-size: medium !important;
  font-size: initial !important;
  font-weight: normal !important;
  font-weight: initial !important; }

.BuyModals_discountPrimaryText__U9yHt {
  color: #ff7428 !important; }

.BuyModals_discountSecondaryText__djpq_ {
  color: #ff7428 !important; }

.BuyModals_hurrify1__2DTaL, .BuyModals_hurrify2__1JGHG {
  padding: 1rem 1rem;
  text-align: center !important;
  margin: 1rem; }

.BuyModals_hurrify1__2DTaL {
  background-color: #e57373;
  color: #ffffff; }

.BuyModals_hurrify2__1JGHG {
  background-color: #ffd04dc7;
  border-radius: 4rem; }

.BuyModals_hurrify3__3zPP- {
  text-align: center !important;
  margin-top: 1rem;
  margin-bottom: 0.5rem; }

.BuyModals_securePaymentBox__29xJs {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem; }

.BuyModals_securePaymentItem__3a9A6 {
  display: flex;
  justify-content: center; }

.BuyModals_securePaymentClaimLogo__2m669 {
  width: 12rem; }

.BuyModals_securePaymentLogo__1Eg5t {
  width: 5rem; }

.BuyModals_shippingPriceSelector__5-kcq {
  margin-bottom: 1rem; }

.BuyModals_freeShippingOptionLabel__qIU6I {
  font-size: 17px;
  margin-top: 2rem !important;
  background-color: rgba(87, 255, 128, 0.6);
  border-radius: 4px;
  padding: 5px; }

.BuyModals_productImgCarousel__2wt-J {
  margin-top: 1rem; }
  .BuyModals_productImgCarousel__2wt-J img {
    border-radius: 4px; }

/*font-family*/
/*font-size*/
/*font-weight*/
.LoginModal_formControl__Y7ufP {
  margin-top: 8px;
  min-width: 120px; }

.LoginModal_flexColumn__1dRfU {
  display: flex;
  flex-direction: column; }
  .LoginModal_flexColumn__1dRfU button {
    background-color: #50a7b4;
    color: white; }
  .LoginModal_flexColumn__1dRfU button:hover {
    background-color: #448086;
    color: white; }

.LoginModal_noEqualPasswordsMessage__1rHAd {
  color: red !important;
  font-style: italic;
  margin-left: 20px; }

.LoginModal_noEqualPasswordsMessageBox__1YMkv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red !important;
  font-style: italic; }

.LoginModal_paymentModalTitleBox__3JqT5 {
  display: flex;
  align-items: center; }

.LoginModal_sendingDataAnimation__6Xmv4 {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.LoginModal_closeModalIcon__Yj9mV {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.LoginModal_forgottenPasswordText__3tVpI {
  margin-top: 10px !important;
  font-style: italic; }

.LoginModal_textField__13P4H label {
  color: #50a7b4 !important; }

.LoginModal_textField__13P4H div {
  padding-right: 0 !important; }
  .LoginModal_textField__13P4H div fieldset {
    border-color: #50a7b4 !important; }

.LoginModal_textField__13P4H button {
  color: #50a7b4;
  background-color: transparent; }

.LoginModal_textField__13P4H button:hover {
  background-color: transparent;
  color: #448086; }

/*font-family*/
/*font-size*/
/*font-weight*/
.Navbar_navbar__3KAQi {
  width: 100%;
  position: fixed;
  z-index: 70;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  left: 0;
  background-color: #4FA9B3;
  background-color: transparent; }
  .Navbar_navbar__3KAQi .Navbar_logoImg__2uEa- {
    width: 75px;
    cursor: pointer;
    margin-left: 0.2rem; }
    @media (min-width: 768px) {
      .Navbar_navbar__3KAQi .Navbar_logoImg__2uEa- {
        margin-left: 2.5%;
        width: 100px; } }
  .Navbar_navbar__3KAQi .Navbar_buttonsContainer__1RXfU {
    display: flex;
    align-items: center; }
    .Navbar_navbar__3KAQi .Navbar_buttonsContainer__1RXfU .Navbar_buttonsContent__1HSUQ {
      display: flex;
      flex-direction: row; }
      @media (max-width: 767px) {
        .Navbar_navbar__3KAQi .Navbar_buttonsContainer__1RXfU .Navbar_buttonsContent__1HSUQ {
          display: none; } }
      @media (max-width: 768px) {
        .Navbar_navbar__3KAQi .Navbar_buttonsContainer__1RXfU .Navbar_buttonsContent__1HSUQ {
          display: none;
          font-size: 17px; } }
    .Navbar_navbar__3KAQi .Navbar_buttonsContainer__1RXfU .Navbar_actionsContent__12jbI {
      display: flex;
      flex-direction: row;
      align-items: center; }
      @media (max-width: 340px) {
        .Navbar_navbar__3KAQi .Navbar_buttonsContainer__1RXfU .Navbar_actionsContent__12jbI {
          flex-direction: column; } }
      @media (max-width: 768px) {
        .Navbar_navbar__3KAQi .Navbar_buttonsContainer__1RXfU .Navbar_actionsContent__12jbI button {
          font-size: 17px; } }

.Navbar_menuButton__2pEaq {
  color: #ffffff !important;
  margin-right: 0.5rem !important; }
  @media (min-width: 768px) {
    .Navbar_menuButton__2pEaq {
      display: flex !important; } }
  @media (min-width: 1024px) {
    .Navbar_menuButton__2pEaq {
      display: none !important; } }

.Navbar_nestedList__1wLxn {
  padding-left: 1.8rem !important; }

.Navbar_actionButton__1-HKA {
  background-color: #ffffff !important;
  color: #000 !important;
  margin: 20px 10px !important;
  cursor: pointer;
  font-size: 20px; }
  @media (max-width: 768px) {
    .Navbar_actionButton__1-HKA {
      font-size: 17px;
      min-width: auto !important;
      display: none !important; } }

.Navbar_actionButtonOutlined__i_vUl {
  border-color: #ffffff !important;
  color: #ffffff !important;
  margin: 20px 10px !important;
  cursor: pointer;
  font-size: 20px; }
  @media (max-width: 768px) {
    .Navbar_actionButtonOutlined__i_vUl {
      font-size: 17px;
      min-width: auto !important;
      display: none !important; } }

.Navbar_actionButtonGetIt__1lmjO {
  color: #ffffff !important;
  margin: 20px 10px !important;
  font-weight: bold !important; }
  @media (min-width: 768px) {
    .Navbar_actionButtonGetIt__1lmjO {
      font-size: 17px;
      display: none !important; } }
  @media (max-width: 480px) {
    .Navbar_actionButtonGetIt__1lmjO {
      display: none !important; } }

.Navbar_landingButton__18b33 {
  font-size: 20px;
  text-decoration: none;
  color: #ffffff;
  margin: 20px;
  padding: 10px;
  border-radius: 6px;
  cursor: pointer; }

.Navbar_logoutModal__2sjbm .Navbar_contentModal__ZNJaA {
  min-width: 350px; }
  @media (max-width: 768px) {
    .Navbar_logoutModal__2sjbm .Navbar_contentModal__ZNJaA {
      min-width: 100px; } }

.Navbar_hiddenScrollToTopLink__380po {
  display: none; }

.Navbar_activeBraceletButton__1-wma {
  color: #fff8e3 !important; }
  @media (max-width: 768px) {
    .Navbar_activeBraceletButton__1-wma {
      display: none !important; } }

.Navbar_offerBanner__3UiX1 {
  width: 100%;
  padding: 3em;
  text-align: center;
  font-size: 17px;
  background-color: black;
  color: white; }

.Navbar_spanishProductIcon__2yUVw {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: auto; }
  .Navbar_spanishProductIcon__2yUVw span {
    color: #fff;
    font-size: 12px; }

.Navbar_leftIcons__1CArM {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: auto; }

/*font-family*/
/*font-size*/
/*font-weight*/
.BeforeCloseAlertModal_dialogContentText__3lcd2 {
  font-size: 20px !important;
  margin-bottom: 1em !important;
  text-align: center; }

.BeforeCloseAlertModal_mainActionButton__2SkvB {
  border-radius: 25px !important;
  padding: 1rem 3rem !important; }

.BeforeCloseAlertModal_closeModalIcon__RkvWk {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.BeforeCloseAlertModal_dialogActions__BMCrB {
  justify-content: center !important; }

.BeforeCloseAlertModal_sendingDataAnimation__3Saj2 {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

/*font-family*/
/*font-size*/
/*font-weight*/
.NewsletterModal_parentContainer__PdVB2 {
  display: flex;
  justify-content: center; }

.NewsletterModal_dialogContentText__2F7CD {
  font-size: 20px !important;
  text-align: center;
  margin-bottom: 1em !important; }

.NewsletterModal_mainActionButtonContainer__1BBZR {
  display: flex;
  justify-content: center; }

.NewsletterModal_mainActionButton__Wioue {
  margin-top: 1em !important;
  border-radius: 50px !important;
  padding: 1.25rem !important; }

.NewsletterModal_dialogActions__1JFMI {
  justify-content: center !important; }

.NewsletterModal_closeModalIcon__3Z3w4 {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.NewsletterModal_sendingDataAnimation__26bxd {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

/*font-family*/
/*font-size*/
/*font-weight*/
.PresaleModal_parentContainer__32JMN {
  display: flex;
  justify-content: center; }

.PresaleModal_dialogContentText__16hPI {
  font-size: 20px !important;
  text-align: center;
  margin-bottom: 1em !important;
  margin-top: 1em !important; }

.PresaleModal_mainActionButtonContainer__14W_2 {
  display: flex;
  justify-content: center; }

.PresaleModal_mainActionButton__2SLTf {
  margin-top: 1em !important;
  border-radius: 50px !important;
  padding: 1.25rem !important; }

.PresaleModal_dialogActions__SxpsP {
  justify-content: center !important; }

.PresaleModal_closeModalIcon__2iH7z {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.PresaleModal_sendingDataAnimation__3CDPo {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

/*font-family*/
/*font-size*/
/*font-weight*/
.NewsletterSection_parentContainer__2-m2u {
  display: flex;
  justify-content: center; }

.NewsletterSection_dialogContentText__16Lir {
  font-size: 20px !important;
  text-align: center !important;
  margin-bottom: 1em !important; }

.NewsletterSection_mainActionButtonContainer__1PfQH {
  display: flex;
  justify-content: center; }

.NewsletterSection_mainActionButton__S9ZWG {
  margin-top: 1em !important;
  border-radius: 50px !important;
  padding: 1.25rem !important; }
  @media (min-width: 768px) {
    .NewsletterSection_mainActionButton__S9ZWG {
      margin-left: 2rem !important; } }

.NewsletterSection_dialogActions__a4quK {
  justify-content: center !important; }

.NewsletterSection_closeModalIcon__-YbA3 {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.NewsletterSection_sendingDataAnimation__1-_IR {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

@media (max-width: 768px) {
  .NewsletterSection_card__1iA4h {
    box-shadow: none !important; } }

@media (min-width: 768px) {
  .NewsletterSection_cardContent__3VmBl {
    display: flex;
    justify-content: center;
    align-items: center; } }

@media (min-width: 768px) {
  .NewsletterSection_inputAndClaimParentContainer__3izd9 {
    margin-right: 2rem;
    margin-left: 2rem; } }

.NewsletterSection_inputAndButtonContainer__3_ZrL {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    .NewsletterSection_inputAndButtonContainer__3_ZrL {
      flex-direction: row; } }

.NewsletterSection_succeedAnimationContainer__2seB1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .NewsletterSection_succeedAnimationContainer__2seB1 .NewsletterSection_succeedAnimationClaim__Lpi7E {
    margin-top: 1rem; }

/*font-family*/
/*font-size*/
/*font-weight*/
.Privacy_privacy__15it- {
  display: flex;
  flex-direction: column;
  margin-top: 80px;
  padding: 4% 20% 4% 20%;
  text-align: justify; }
  @media (max-width: 768px) {
    .Privacy_privacy__15it- {
      padding: 4% 20px 4% 20px; } }
  .Privacy_privacy__15it- h2 {
    color: #000; }

.Privacy_downloadButtons__Q-RDi {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-top: 2em; }

.Privacy_navBar__3rNDe {
  background-color: #50a7b4 !important; }

.Privacy_privacy__15it- {
  cursor: pointer; }

/*font-family*/
/*font-size*/
/*font-weight*/
.Detail_detail__2__Sp {
  background-color: #fff8e3;
  min-height: 100vh !important; }
  .Detail_detail__2__Sp .Detail_chargingContent__18mdC {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .Detail_detail__2__Sp .Detail_content__1VT8Z, .Detail_detail__2__Sp .Detail_noNavBarContent__34ddo {
    padding-top: 90px;
    min-height: 88vh; }
    @media (max-width: 374px) {
      .Detail_detail__2__Sp .Detail_content__1VT8Z, .Detail_detail__2__Sp .Detail_noNavBarContent__34ddo {
        padding-top: 145px; } }
    .Detail_detail__2__Sp .Detail_content__1VT8Z ul, .Detail_detail__2__Sp .Detail_noNavBarContent__34ddo ul {
      list-style-type: none;
      padding-left: 0;
      margin: 0; }
      .Detail_detail__2__Sp .Detail_content__1VT8Z ul li, .Detail_detail__2__Sp .Detail_noNavBarContent__34ddo ul li {
        display: flex;
        align-items: center;
        padding-left: 10%;
        font-size: 17px; }
        .Detail_detail__2__Sp .Detail_content__1VT8Z ul li p, .Detail_detail__2__Sp .Detail_content__1VT8Z ul li h3, .Detail_detail__2__Sp .Detail_noNavBarContent__34ddo ul li p, .Detail_detail__2__Sp .Detail_noNavBarContent__34ddo ul li h3 {
          margin-right: 10px; }
    .Detail_detail__2__Sp .Detail_content__1VT8Z .Detail_header__22KSU, .Detail_detail__2__Sp .Detail_noNavBarContent__34ddo .Detail_header__22KSU {
      background-color: #fff8e3;
      padding-left: 5%;
      margin-bottom: -1.5rem !important; }
    .Detail_detail__2__Sp .Detail_content__1VT8Z .Detail_basicData__22Lv5 .Detail_document__2kyFH, .Detail_detail__2__Sp .Detail_noNavBarContent__34ddo .Detail_basicData__22Lv5 .Detail_document__2kyFH {
      margin-top: 10px; }
      .Detail_detail__2__Sp .Detail_content__1VT8Z .Detail_basicData__22Lv5 .Detail_document__2kyFH .Detail_documentText__3YzVh, .Detail_detail__2__Sp .Detail_noNavBarContent__34ddo .Detail_basicData__22Lv5 .Detail_document__2kyFH .Detail_documentText__3YzVh {
        flex: inherit; }
      .Detail_detail__2__Sp .Detail_content__1VT8Z .Detail_basicData__22Lv5 .Detail_document__2kyFH .Detail_documentTypeText__27CPM, .Detail_detail__2__Sp .Detail_noNavBarContent__34ddo .Detail_basicData__22Lv5 .Detail_document__2kyFH .Detail_documentTypeText__27CPM {
        min-width: 100px; }
      .Detail_detail__2__Sp .Detail_content__1VT8Z .Detail_basicData__22Lv5 .Detail_document__2kyFH .Detail_documentViewPhotos__1gB57, .Detail_detail__2__Sp .Detail_noNavBarContent__34ddo .Detail_basicData__22Lv5 .Detail_document__2kyFH .Detail_documentViewPhotos__1gB57 {
        font-size: 13px;
        color: #50a7b4;
        font-weight: bold;
        cursor: pointer;
        margin-left: 2em; }
      .Detail_detail__2__Sp .Detail_content__1VT8Z .Detail_basicData__22Lv5 .Detail_document__2kyFH .Detail_documentSecondaryBox__2Lyjs, .Detail_detail__2__Sp .Detail_noNavBarContent__34ddo .Detail_basicData__22Lv5 .Detail_document__2kyFH .Detail_documentSecondaryBox__2Lyjs {
        display: flex; }
    .Detail_detail__2__Sp .Detail_content__1VT8Z .Detail_emergencyData__2_yV- .Detail_header__22KSU, .Detail_detail__2__Sp .Detail_noNavBarContent__34ddo .Detail_emergencyData__2_yV- .Detail_header__22KSU {
      padding-left: 5%;
      font-size: 15px; }
    .Detail_detail__2__Sp .Detail_content__1VT8Z .Detail_emergencyData__2_yV- .Detail_contact__xLI9e, .Detail_detail__2__Sp .Detail_noNavBarContent__34ddo .Detail_emergencyData__2_yV- .Detail_contact__xLI9e {
      border-bottom: 1px solid grey; }
    .Detail_detail__2__Sp .Detail_content__1VT8Z .Detail_emergencyData__2_yV- .Detail_emergencyDataRow__2_Jzi, .Detail_detail__2__Sp .Detail_noNavBarContent__34ddo .Detail_emergencyData__2_yV- .Detail_emergencyDataRow__2_Jzi {
      margin-top: 10px;
      padding-bottom: 10px; }
    @media (max-width: 768px) {
      .Detail_detail__2__Sp .Detail_content__1VT8Z .Detail_medicData__3Cj_k .Detail_medicObservationRow__322qf, .Detail_detail__2__Sp .Detail_noNavBarContent__34ddo .Detail_medicData__3Cj_k .Detail_medicObservationRow__322qf {
        align-items: flex-start;
        margin-top: 10px; } }
    @media (min-width: 768px) {
      .Detail_detail__2__Sp .Detail_content__1VT8Z .Detail_medicData__3Cj_k .Detail_medicObservationRow__322qf .Detail_medicObservation__1WIqb, .Detail_detail__2__Sp .Detail_noNavBarContent__34ddo .Detail_medicData__3Cj_k .Detail_medicObservationRow__322qf .Detail_medicObservation__1WIqb {
        display: flex;
        align-items: center; } }
    @media (max-width: 768px) {
      .Detail_detail__2__Sp .Detail_content__1VT8Z .Detail_medicData__3Cj_k .Detail_medicObservationRow__322qf .Detail_medicObservation__1WIqb h3, .Detail_detail__2__Sp .Detail_noNavBarContent__34ddo .Detail_medicData__3Cj_k .Detail_medicObservationRow__322qf .Detail_medicObservation__1WIqb h3 {
        margin-top: 0; } }
  .Detail_detail__2__Sp .Detail_noNavBarContent__34ddo {
    padding-top: 10px; }

.Detail_detailBox__2uf5H {
  padding-bottom: 100px; }
  @media (max-width: 768px) {
    .Detail_detailBox__2uf5H {
      padding-bottom: 200px; } }

.Detail_errorMessage__-CwjE {
  display: flex;
  align-items: center;
  text-align: center;
  min-height: 100vh;
  justify-content: center; }

.Detail_relationshipText__1u5fP {
  color: #9a9a9a;
  font-size: 12px;
  margin-left: 5px; }

.Detail_snack__w-8jc {
  z-index: 200000 !important; }
  .Detail_snack__w-8jc div {
    background-color: #ffac00 !important;
    font-weight: bold; }

.Detail_snackMessage__3UqG7 {
  display: flex;
  align-items: center; }

.Detail_snackIcon__18eZx {
  margin-right: 25px; }

.Detail_snackButton__2xue6 {
  color: #fff !important;
  border-color: #fff !important;
  margin-bottom: 5px !important; }

.Detail_closeModalIcon__G96rm {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.Detail_dialogTitle__3a2On {
  text-align: center; }

.Detail_seeDataButton__18jQO {
  background-color: #50a7b4 !important;
  color: #fff8e3 !important;
  padding: 1.5em !important; }

.Detail_stepsContainer__1oAf9 {
  background-color: #fff8e3;
  border-radius: 25px;
  margin: 1em;
  padding-top: 1em !important; }

.Detail_patientPhotoContainer__SVRYa {
  display: flex !important;
  justify-content: center !important;
  padding-left: 0 !important; }

.Detail_patientPhoto__3nSdY {
  width: 10rem !important;
  height: 10rem !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
.AssignModal_formControl__UYpoc {
  margin-top: 8px;
  min-width: 120px; }

.AssignModal_flexColumn__BQhxE {
  display: flex;
  flex-direction: column; }
  .AssignModal_flexColumn__BQhxE button {
    background-color: #50a7b4;
    color: white; }
  .AssignModal_flexColumn__BQhxE button:hover {
    background-color: #448086;
    color: white; }

.AssignModal_checkbox__2o2ke {
  color: #50a7b4 !important; }

.AssignModal_noEqualPasswordsMessage__3nhuP {
  color: red !important;
  font-style: italic;
  margin-left: 20px; }

.AssignModal_noEqualPasswordsMessageBox__2f8D- {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red !important;
  font-style: italic; }

.AssignModal_paymentModalTitleBox__cf6eY {
  display: flex;
  align-items: center; }

.AssignModal_privacyLink__YOXtW {
  color: #716b77; }

.AssignModal_privacyLink__YOXtW:hover {
  color: #0077b5;
  text-decoration: underline; }

.AssignModal_sendingDataAnimation__ZhQkM {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.AssignModal_closeModalIcon__1ocdm {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.AssignModal_forgottenPasswordText__20TYv {
  margin-top: 10px !important;
  font-style: italic; }

.AssignModal_textField__1K1EI label {
  color: #50a7b4 !important; }

.AssignModal_textField__1K1EI div fieldset {
  border-color: #50a7b4 !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
.PatientModal_formControl__3Z595 {
  margin-top: 8px;
  min-width: 120px; }
  .PatientModal_formControl__3Z595 label {
    color: #50a7b4 !important; }
  .PatientModal_formControl__3Z595 div fieldset {
    border-color: #50a7b4 !important; }
  .PatientModal_formControl__3Z595 svg {
    color: #50a7b4 !important; }

.PatientModal_flexColumn__JfMtp {
  display: flex;
  flex-direction: column; }
  .PatientModal_flexColumn__JfMtp button {
    background-color: #50a7b4;
    color: white; }
  .PatientModal_flexColumn__JfMtp button:hover {
    background-color: #448086;
    color: white; }
  .PatientModal_flexColumn__JfMtp button:disabled {
    background-color: white;
    color: #50a7b4; }

.PatientModal_noEqualPasswordsMessage__2GweG {
  color: red !important;
  font-style: italic;
  margin-left: 20px; }

.PatientModal_noEqualPasswordsMessageBox__vj9Li {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red !important;
  font-style: italic; }

.PatientModal_paymentModalTitleBox__2bZdz {
  display: flex;
  align-items: center; }

.PatientModal_sendingDataAnimation__1q27V {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.PatientModal_textField__cCkPm label {
  color: #50a7b4 !important; }

.PatientModal_textField__cCkPm div fieldset {
  border-color: #50a7b4 !important; }

.PatientModal_contentText__1kMvn {
  border-bottom: 10px; }

.PatientModal_dialogContainer__2Wyao [class^="MuiPaper-root"] {
  min-height: 300px; }

.PatientModal_closeModalIcon__3T7Qr {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
.BasicDataForm_gridContainer__szv8i {
  justify-content: space-between !important;
  margin-top: 15px !important;
  margin-bottom: 50px !important; }

.BasicDataForm_photoLabel__1fIB5 {
  color: #50a7b4; }

.BasicDataForm_filepondWrapper__3oNH0 {
  margin-top: 0.5rem;
  margin-bottom: -1rem; }

.BasicDataForm_formControl__5ihqc, .BasicDataForm_formControlCalendar__20lnK {
  box-sizing: border-box;
  min-width: 120px;
  display: flex !important; }
  .BasicDataForm_formControl__5ihqc label, .BasicDataForm_formControlCalendar__20lnK label {
    color: #50a7b4 !important; }
  .BasicDataForm_formControl__5ihqc div, .BasicDataForm_formControlCalendar__20lnK div {
    box-sizing: border-box; }
    .BasicDataForm_formControl__5ihqc div fieldset, .BasicDataForm_formControlCalendar__20lnK div fieldset {
      box-sizing: border-box;
      border-color: #50a7b4 !important; }
  .BasicDataForm_formControl__5ihqc svg, .BasicDataForm_formControlCalendar__20lnK svg {
    color: #50a7b4 !important; }

.BasicDataForm_stepper__3Oijj {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  position: absolute;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); }
  @media (max-width: 768px) {
    .BasicDataForm_stepper__3Oijj {
      position: fixed; } }

.BasicDataForm_flexColumn__2GCJ9 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }
  .BasicDataForm_flexColumn__2GCJ9 button {
    background-color: #50a7b4;
    color: white; }
  .BasicDataForm_flexColumn__2GCJ9 button:hover {
    background-color: #448086;
    color: white; }
  .BasicDataForm_flexColumn__2GCJ9 button:disabled {
    background-color: white;
    color: #4FA9B3; }

.BasicDataForm_noEqualPasswordsMessage__2VWeV {
  color: red !important;
  font-style: italic;
  margin-left: 20px; }

.BasicDataForm_noEqualPasswordsMessageBox__2Ukfh {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red !important;
  font-style: italic; }

.BasicDataForm_paymentModalTitleBox__1IWca {
  display: flex;
  align-items: center; }

.BasicDataForm_sendingDataAnimation__3ObD- {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.BasicDataForm_textField__1j4ir label {
  color: #50a7b4 !important; }

.BasicDataForm_textField__1j4ir div fieldset {
  border-color: #50a7b4 !important; }

.BasicDataForm_textFieldError__2NUfI label {
  color: #ff3d00 !important; }

.BasicDataForm_textFieldError__2NUfI div fieldset {
  border-color: #ff3d00 !important; }

.BasicDataForm_dateTextField__1wqaU {
  display: flex !important;
  margin-bottom: 20px !important; }
  .BasicDataForm_dateTextField__1wqaU label {
    color: #50a7b4 !important; }
  .BasicDataForm_dateTextField__1wqaU div fieldset {
    border-color: #50a7b4 !important; }
  .BasicDataForm_dateTextField__1wqaU .BasicDataForm_MuiOutlinedInput-input-289__3KgWP {
    cursor: pointer !important; }
  .BasicDataForm_dateTextField__1wqaU .BasicDataForm_MuiOutlinedInput-input-289__3KgWP:hover {
    cursor: pointer !important; }

.BasicDataForm_dateTextField__1wqaU:hover {
  cursor: pointer !important; }

.BasicDataForm_dateTextFieldError__1EDhb {
  display: flex !important;
  margin-bottom: 5px; }
  .BasicDataForm_dateTextFieldError__1EDhb label {
    color: #ff3d00 !important; }
  .BasicDataForm_dateTextFieldError__1EDhb div fieldset {
    border-color: #ff3d00 !important; }

.BasicDataForm_dateTextFieldError__1EDhb:hover {
  cursor: pointer !important; }

.BasicDataForm_adornmentHover__vZcq6:hover {
  cursor: pointer !important; }

.BasicDataForm_contentText__2A8ib {
  border-bottom: 10px; }

.BasicDataForm_formContainer__3WftU {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly; }

.BasicDataForm_doubleColumnContainer__2I1A- {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start; }

.BasicDataForm_calendarBox__3V9dG {
  display: flex;
  flex-direction: column;
  align-items: center; }

.BasicDataForm_calendar__dW6dK {
  width: 100% !important;
  margin-top: -3px;
  z-index: 36;
  border-top: none !important;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px; }

.BasicDataForm_formControlCalendar__20lnK input:hover {
  cursor: pointer; }

.BasicDataForm_formControlCalendar__20lnK:hover {
  cursor: pointer; }

.BasicDataForm_formControlCalendarOpened__1fwsd {
  box-sizing: border-box;
  min-width: 120px;
  display: flex !important; }
  .BasicDataForm_formControlCalendarOpened__1fwsd label {
    color: #50a7b4 !important; }
  .BasicDataForm_formControlCalendarOpened__1fwsd div {
    box-sizing: border-box; }
    .BasicDataForm_formControlCalendarOpened__1fwsd div fieldset {
      box-sizing: border-box;
      border-color: #50a7b4 !important;
      border-bottom: none; }
  .BasicDataForm_formControlCalendarOpened__1fwsd svg {
    color: #50a7b4 !important; }

.BasicDataForm_tutoWrapper__3r2ML {
  display: flex;
  flex-direction: column;
  text-align: center; }

.BasicDataForm_tutoImgWrapper__3kZFZ {
  text-align: center;
  margin: 0.6em; }

@media (min-width: 768px) {
  .BasicDataForm_tutoParentWrapper__2xK-P:after, .BasicDataForm_tutoParentWrapper__2xK-P:before {
    right: 99%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; } }

@media (min-width: 768px) {
  .BasicDataForm_tutoParentWrapper__2xK-P:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #ffffff;
    border-width: 15px;
    margin-top: -15px; } }

@media (min-width: 768px) {
  .BasicDataForm_tutoParentWrapper__2xK-P:before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: #ffffff;
    border-width: 21px;
    margin-top: -21px; } }

@media (max-width: 1400px) {
  .BasicDataForm_UxTutoMask__3m1ir {
    fill: rgba(0, 0, 0, 0.9) !important;
    stroke: rgba(0, 0, 0, 0.9) !important; } }

.BasicDataForm_patientPhotoContainer__cO3Fy {
  position: relative;
  display: flex;
  justify-content: center; }

.BasicDataForm_patientPhotoWrapper__2Tpys {
  position: relative; }

.BasicDataForm_patientPhoto__3__ye {
  height: 6rem !important;
  width: 6rem !important; }

.BasicDataForm_editPhotoButton__1jn9P {
  padding: 0.25rem !important;
  background-color: #4FA9B3 !important;
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important; }
  .BasicDataForm_editPhotoButton__1jn9P svg {
    color: #fff; }

/*font-family*/
/*font-size*/
/*font-weight*/
.MedicalDataForm_gridContainer__36D9M {
  margin-top: 8px !important;
  margin-bottom: 50px !important; }

.MedicalDataForm_formControl__T45e6 {
  margin-top: 8px;
  min-width: 120px; }
  .MedicalDataForm_formControl__T45e6 label {
    color: #50a7b4 !important; }
  .MedicalDataForm_formControl__T45e6 div fieldset {
    border-color: #50a7b4 !important; }
  .MedicalDataForm_formControl__T45e6 svg {
    color: #50a7b4 !important; }

.MedicalDataForm_stepper__1qP5u {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  position: absolute;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); }
  @media (max-width: 768px) {
    .MedicalDataForm_stepper__1qP5u {
      position: fixed; } }

.MedicalDataForm_flexColumn__3UJyR {
  display: flex;
  flex-direction: column; }
  .MedicalDataForm_flexColumn__3UJyR button {
    background-color: #4FA9B3;
    color: white; }
  .MedicalDataForm_flexColumn__3UJyR button:hover {
    background-color: #448086;
    color: white; }
  .MedicalDataForm_flexColumn__3UJyR button:disabled {
    background-color: white;
    color: #4FA9B3; }

.MedicalDataForm_noEqualPasswordsMessage__1myHk {
  color: red !important;
  font-style: italic;
  margin-left: 20px; }

.MedicalDataForm_noEqualPasswordsMessageBox__2kcnf {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red !important;
  font-style: italic; }

.MedicalDataForm_paymentModalTitleBox__16A9w {
  display: flex;
  align-items: center; }

.MedicalDataForm_sendingDataAnimation__UJuSH {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.MedicalDataForm_textField__2Sffo {
  margin-top: 20px !important; }
  .MedicalDataForm_textField__2Sffo label {
    color: #50a7b4 !important; }
  .MedicalDataForm_textField__2Sffo div fieldset {
    border-color: #50a7b4 !important; }

.MedicalDataForm_chipInputLabel__3aWa2 {
  margin-bottom: 5px;
  color: #50a7b4 !important; }

.MedicalDataForm_chip__3goi2 {
  margin: 5px; }

.MedicalDataForm_contentText__2faau {
  border-bottom: 10px; }

.MedicalDataForm_tutoWrapper__3vVEc {
  display: flex;
  flex-direction: column;
  text-align: center; }

.MedicalDataForm_tutoImgWrapper__2h5AK {
  text-align: center;
  margin: 0.6em; }

@media (min-width: 768px) {
  .MedicalDataForm_tutoParentWrapper__1Td8R:after, .MedicalDataForm_tutoParentWrapper__1Td8R:before {
    right: 99%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; } }

@media (min-width: 768px) {
  .MedicalDataForm_tutoParentWrapper__1Td8R:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #ffffff;
    border-width: 15px;
    margin-top: -15px; } }

@media (min-width: 768px) {
  .MedicalDataForm_tutoParentWrapper__1Td8R:before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: #ffffff;
    border-width: 21px;
    margin-top: -21px; } }

@media (max-width: 1400px) {
  .MedicalDataForm_UxTutoMask__1pBTc {
    fill: rgba(0, 0, 0, 0.9) !important;
    stroke: rgba(0, 0, 0, 0.9) !important; } }

/*font-family*/
/*font-size*/
/*font-weight*/
.ChipInput_chip__3wcRE {
  margin: 2px 5px; }

.ChipInput_textField__1_NQL {
  margin-left: 10px; }
  .ChipInput_textField__1_NQL label {
    color: #50a7b4 !important; }
  .ChipInput_textField__1_NQL div fieldset {
    border-color: #50a7b4 !important; }
  .ChipInput_textField__1_NQL .ChipInput_contentText__Olrvh {
    border-bottom: 10px; }

.ChipInput_gridContainer__3MzhG {
  margin: 0px 2.5px !important;
  width: unset !important; }

.ChipInput_parentGrid__3_g75 {
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  align-items: baseline;
  border: 1px solid #50a7b4 !important;
  border-radius: 5px; }

.ChipInput_typography__3_yg7 {
  margin-left: 5px !important;
  margin-top: 2px !important; }

.ChipInput_label__21uFD {
  color: #50a7b4 !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
.DocumentsDataForm_sendingDataAnimation__1yzx3 {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.DocumentsDataForm_container__3XpsZ {
  margin-bottom: 50px; }

.DocumentsDataForm_formControl__QN7WY {
  display: flex !important; }
  .DocumentsDataForm_formControl__QN7WY label {
    color: #50a7b4 !important; }
  .DocumentsDataForm_formControl__QN7WY div fieldset {
    border-color: #50a7b4 !important; }
    .DocumentsDataForm_formControl__QN7WY div fieldset legend {
      max-width: 90px !important; }
  .DocumentsDataForm_formControl__QN7WY svg {
    color: #50a7b4 !important; }

.DocumentsDataForm_formControlType__1Ib55 {
  display: flex !important; }
  @media (max-width: 768px) {
    .DocumentsDataForm_formControlType__1Ib55 {
      margin-bottom: 20px !important; } }
  .DocumentsDataForm_formControlType__1Ib55 label {
    color: #50a7b4 !important; }
  .DocumentsDataForm_formControlType__1Ib55 div fieldset {
    border-color: #50a7b4 !important; }

.DocumentsDataForm_stepper__3IXXK {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  position: absolute;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); }
  @media (max-width: 768px) {
    .DocumentsDataForm_stepper__3IXXK {
      position: fixed; } }

.DocumentsDataForm_flexColumn__ZMA57 {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }
  .DocumentsDataForm_flexColumn__ZMA57 button {
    background-color: #50a7b4;
    color: white; }
  .DocumentsDataForm_flexColumn__ZMA57 button:hover {
    background-color: #448086;
    color: white; }
  .DocumentsDataForm_flexColumn__ZMA57 button:disabled {
    background-color: white;
    color: #50a7b4; }

.DocumentsDataForm_noEqualPasswordsMessage__3WSzP {
  color: red !important;
  font-style: italic;
  margin-left: 20px; }

.DocumentsDataForm_noEqualPasswordsMessageBox__3tRuO {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red !important;
  font-style: italic; }

.DocumentsDataForm_paymentModalTitleBox__464Iw {
  display: flex;
  align-items: center; }

.DocumentsDataForm_sendingDataAnimation__1yzx3 {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.DocumentsDataForm_textField__1CvyY label {
  color: #50a7b4 !important; }

.DocumentsDataForm_textField__1CvyY div fieldset {
  border-color: #50a7b4 !important; }

.DocumentsDataForm_contentText__k4K8E {
  border-bottom: 10px; }

.DocumentsDataForm_doubleColumnContainer__3ncCQ {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start; }

.DocumentsDataForm_parentContainer__2vz8P {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-around; }

.DocumentsDataForm_docsListContainer__3D4_J {
  background-color: #f7f7f7;
  border-radius: 25px; }
  .DocumentsDataForm_docsListContainer__3D4_J .DocumentsDataForm_document__1wlGq .DocumentsDataForm_documentHeader__30mDY {
    display: flex;
    align-items: center; }
  .DocumentsDataForm_docsListContainer__3D4_J .DocumentsDataForm_contactsListContainerTitle__YtKWl {
    margin: 10px 15px 0;
    color: #979797; }

.DocumentsDataForm_documentsContainer__2VXe4 {
  display: flex;
  justify-content: space-around;
  align-items: baseline !important;
  margin-top: 10px !important; }

.DocumentsDataForm_documentFormControlContainer__1B63Y {
  align-items: flex-start;
  justify-content: space-between; }
  @media (min-width: 768px) {
    .DocumentsDataForm_documentFormControlContainer__1B63Y {
      display: flex; } }
  @media (max-width: 768px) {
    .DocumentsDataForm_documentFormControlContainer__1B63Y {
      flex-direction: column !important; } }

.DocumentsDataForm_uploadFileContainer__13ho9 {
  display: flex;
  flex-wrap: wrap;
  min-height: 65px;
  align-items: center; }
  @media (max-width: 768px) {
    .DocumentsDataForm_uploadFileContainer__13ho9 {
      flex-direction: column;
      margin-top: 25px !important; } }

.DocumentsDataForm_uploadFilebutton__2G_g2 {
  background-color: #50a7b4 !important;
  color: white !important;
  margin-right: 5px !important;
  margin-left: 12px !important; }

.DocumentsDataForm_uploadFilebutton__2G_g2:hover {
  background-color: #448086 !important;
  color: white !important; }

.DocumentsDataForm_fileNameContainer__3Q_Rm {
  display: flex;
  align-items: center;
  border-left: 3px solid #448086;
  flex-wrap: wrap; }
  @media (max-width: 768px) {
    .DocumentsDataForm_fileNameContainer__3Q_Rm {
      flex-direction: column;
      margin-top: 15px;
      margin-left: 0; } }

.DocumentsDataForm_addButton__39Paa {
  margin-top: 15px;
  background-color: #50a7b4 !important; }

.DocumentsDataForm_addButton__39Paa:hover {
  background-color: #448086 !important; }

.DocumentsDataForm_addButton__39Paa:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important; }

.DocumentsDataForm_addButtonIcon__3CXw0 {
  margin-right: 5px; }

.DocumentsDataForm_newDocumentFormContainer__3IeR8 {
  background-color: #f0f0f0;
  border-radius: 25px;
  padding: 1em !important;
  margin-bottom: 1em !important;
  margin-top: 1em !important; }

.DocumentsDataForm_newDocumentActionButtons__3nOX9 {
  display: flex;
  justify-content: flex-end; }

.DocumentsDataForm_tutoWrapper__kNz8e {
  display: flex;
  flex-direction: column;
  text-align: center; }

.DocumentsDataForm_tutoImgWrapper__2soBB {
  text-align: center;
  margin: 0.6em; }

@media (min-width: 768px) {
  .DocumentsDataForm_tutoParentWrapper__1HyKG:after, .DocumentsDataForm_tutoParentWrapper__1HyKG:before {
    right: 99%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; } }

@media (min-width: 768px) {
  .DocumentsDataForm_tutoParentWrapper__1HyKG:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #ffffff;
    border-width: 15px;
    margin-top: -15px; } }

@media (min-width: 768px) {
  .DocumentsDataForm_tutoParentWrapper__1HyKG:before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: #ffffff;
    border-width: 21px;
    margin-top: -21px; } }

@media (max-width: 1400px) {
  .DocumentsDataForm_UxTutoMask__1objk {
    fill: rgba(0, 0, 0, 0.9) !important;
    stroke: rgba(0, 0, 0, 0.9) !important; } }

/*font-family*/
/*font-size*/
/*font-weight*/
.ContactsDataForm_container__1XVK_ {
  margin-bottom: 50px; }

.ContactsDataForm_formControl__2CZ1X {
  display: flex !important; }
  .ContactsDataForm_formControl__2CZ1X label {
    color: #50a7b4 !important; }
  .ContactsDataForm_formControl__2CZ1X div fieldset {
    border-color: #50a7b4 !important; }
    .ContactsDataForm_formControl__2CZ1X div fieldset legend {
      max-width: 90px !important; }
  .ContactsDataForm_formControl__2CZ1X svg {
    color: #50a7b4 !important; }

.ContactsDataForm_stepper__2diVW {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  position: absolute;
  box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.22); }
  @media (max-width: 768px) {
    .ContactsDataForm_stepper__2diVW {
      position: fixed; } }

.ContactsDataForm_autocompleteLocationInputs__Ldv7N {
  display: flex;
  flex-direction: column; }

.ContactsDataForm_flexColumn__3XOco {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }
  .ContactsDataForm_flexColumn__3XOco button {
    background-color: #4FA9B3;
    color: white; }
  .ContactsDataForm_flexColumn__3XOco button:hover {
    background-color: #448086;
    color: white; }
  .ContactsDataForm_flexColumn__3XOco button:disabled {
    background-color: white;
    color: #4FA9B3; }

.ContactsDataForm_noEqualPasswordsMessage__2qF9z {
  color: red !important;
  font-style: italic;
  margin-left: 20px; }

.ContactsDataForm_noEqualPasswordsMessageBox__1sDlj {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red !important;
  font-style: italic; }

.ContactsDataForm_paymentModalTitleBox__A40y_ {
  display: flex;
  align-items: center; }

.ContactsDataForm_sendingDataAnimation__pj-HX {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.ContactsDataForm_contentText__317lP {
  border-bottom: 10px; }

.ContactsDataForm_formContainer__3ONum {
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly; }

.ContactsDataForm_doubleColumnContainer__rfn4U {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start; }

.ContactsDataForm_parentContainer__11KcN {
  display: flex;
  align-items: center;
  justify-content: space-around; }

.ContactsDataForm_contactsListContainer__Suks0 {
  background-color: #f7f7f7;
  border-radius: 25px; }
  .ContactsDataForm_contactsListContainer__Suks0 .ContactsDataForm_contactsListContainerTitle__2g761 {
    margin: 10px 15px 0;
    color: #979797; }
  .ContactsDataForm_contactsListContainer__Suks0 .ContactsDataForm_document__QvoKf .ContactsDataForm_documentHeader__2WeSX {
    display: flex;
    align-items: center; }

.ContactsDataForm_contactsContainer__UdTGq {
  display: flex;
  justify-content: center;
  margin-top: 10px !important; }

.ContactsDataForm_addButton__21cII {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
  background-color: #50a7b4 !important; }

.ContactsDataForm_addButton__21cII:hover {
  background-color: #448086 !important; }

.ContactsDataForm_addButton__21cII:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important; }

.ContactsDataForm_addButtonIcon__1yejf {
  margin-right: 5px; }

.ContactsDataForm_relationshipText__1uRcI {
  color: #9a9a9a;
  font-size: 12px;
  margin-left: 5px; }

.ContactsDataForm_inputAdornment__3-I5T {
  color: #716b77; }

.ContactsDataForm_newContactFormContainer__2Ovzs {
  background-color: #f0f0f0;
  border-radius: 25px;
  padding: 1em !important;
  margin-bottom: 1em !important;
  margin-top: 1em !important; }

.ContactsDataForm_newContactActionButtons__3PM4n {
  display: flex;
  justify-content: flex-end; }

.ContactsDataForm_redoIcon__1-Eps {
  transform: rotate(45deg); }

.ContactsDataForm_addContactButton__2G48p {
  background-color: #4FA9B3;
  color: #fff; }

.ContactsDataForm_tutoWrapper__jgGGT {
  display: flex;
  flex-direction: column;
  text-align: center; }

.ContactsDataForm_tutoImgWrapper__SJE5r {
  text-align: center;
  margin: 0.6em; }

@media (min-width: 768px) {
  .ContactsDataForm_tutoParentWrapper__wVfKB:after, .ContactsDataForm_tutoParentWrapper__wVfKB:before {
    right: 99%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; } }

@media (min-width: 768px) {
  .ContactsDataForm_tutoParentWrapper__wVfKB:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #ffffff;
    border-width: 15px;
    margin-top: -15px; } }

@media (min-width: 768px) {
  .ContactsDataForm_tutoParentWrapper__wVfKB:before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: #ffffff;
    border-width: 21px;
    margin-top: -21px; } }

@media (max-width: 1400px) {
  .ContactsDataForm_UxTutoMask__2Fm7i {
    fill: rgba(0, 0, 0, 0.9) !important;
    stroke: rgba(0, 0, 0, 0.9) !important; } }

/*font-family*/
/*font-size*/
/*font-weight*/
.PhotosDialog_formControl__2skzP {
  margin-top: 8px;
  min-width: 120px; }

.PhotosDialog_flexColumn__SAo87 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center; }
  .PhotosDialog_flexColumn__SAo87 button {
    background-color: #50a7b4;
    color: white; }
  .PhotosDialog_flexColumn__SAo87 button:hover {
    background-color: #448086;
    color: white; }
  .PhotosDialog_flexColumn__SAo87 .PhotosDialog_photo__XK84m {
    margin-top: 15px;
    border-radius: 15px;
    background: url(/static/media/loading.e4987724.gif) 50% no-repeat;
    height: auto;
    width: auto;
    min-width: 75px;
    min-height: 75px;
    max-width: 500px;
    max-height: 500px; }
    @media (max-width: 480px) {
      .PhotosDialog_flexColumn__SAo87 .PhotosDialog_photo__XK84m {
        max-height: 400px;
        max-width: 300px; } }
  .PhotosDialog_flexColumn__SAo87 .PhotosDialog_stepNextButton__1SRTX {
    position: absolute !important;
    top: 50% !important;
    right: 5px !important; }
  .PhotosDialog_flexColumn__SAo87 .PhotosDialog_stepBeforeButton__23O5B {
    position: absolute !important;
    top: 50% !important;
    left: 5px !important; }

.PhotosDialog_closeModalIcon__2XDca {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.PhotosDialog_textField__3_ra5 label {
  color: #50a7b4 !important; }

.PhotosDialog_textField__3_ra5 div fieldset {
  border-color: #50a7b4 !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
.EmergencyStepsModal_container__1cv_u {
  margin-bottom: 50px; }

.EmergencyStepsModal_formControl__gXUPo {
  display: flex !important; }
  .EmergencyStepsModal_formControl__gXUPo label {
    color: #50a7b4 !important; }
  .EmergencyStepsModal_formControl__gXUPo div fieldset {
    border-color: #50a7b4 !important; }
    .EmergencyStepsModal_formControl__gXUPo div fieldset legend {
      max-width: 90px !important; }
  .EmergencyStepsModal_formControl__gXUPo svg {
    color: #50a7b4 !important; }

.EmergencyStepsModal_formControlType__ly1CE {
  display: flex !important; }
  @media (max-width: 768px) {
    .EmergencyStepsModal_formControlType__ly1CE {
      margin-bottom: 20px !important; } }
  .EmergencyStepsModal_formControlType__ly1CE label {
    color: #50a7b4 !important; }
  .EmergencyStepsModal_formControlType__ly1CE div fieldset {
    border-color: #50a7b4 !important; }

.EmergencyStepsModal_stepper__3bU6Q {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  position: absolute; }
  @media (max-width: 768px) {
    .EmergencyStepsModal_stepper__3bU6Q {
      position: fixed; } }

.EmergencyStepsModal_flexColumn__2qFnT {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap; }
  .EmergencyStepsModal_flexColumn__2qFnT button {
    background-color: #50a7b4;
    color: white; }
  .EmergencyStepsModal_flexColumn__2qFnT button:hover {
    background-color: #448086;
    color: white; }
  .EmergencyStepsModal_flexColumn__2qFnT button:disabled {
    background-color: white;
    color: #50a7b4; }

.EmergencyStepsModal_noEqualPasswordsMessage__Kc1hL {
  color: red !important;
  font-style: italic;
  margin-left: 20px; }

.EmergencyStepsModal_noEqualPasswordsMessageBox__fqKLu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red !important;
  font-style: italic; }

.EmergencyStepsModal_paymentModalTitleBox__2h3km {
  display: flex;
  align-items: center; }

.EmergencyStepsModal_sendingDataAnimation__2y4qd {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  color: #448086 !important; }

.EmergencyStepsModal_textField__3xGzU label {
  color: #50a7b4 !important; }

.EmergencyStepsModal_textField__3xGzU div fieldset {
  border-color: #50a7b4 !important; }

.EmergencyStepsModal_contentText__3elwA {
  border-bottom: 10px; }

.EmergencyStepsModal_doubleColumnContainer__2gyn2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start; }

.EmergencyStepsModal_parentContainer__3_GzL {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: space-around; }

.EmergencyStepsModal_stepsListContainer__2iv4k {
  background-color: #f7f7f7;
  border-radius: 25px; }
  .EmergencyStepsModal_stepsListContainer__2iv4k .EmergencyStepsModal_document__2O8cm .EmergencyStepsModal_documentHeader__N8P8o {
    display: flex;
    align-items: center; }
  .EmergencyStepsModal_stepsListContainer__2iv4k .EmergencyStepsModal_contactsListContainerTitle__1YEko {
    margin: 10px 15px 0;
    color: #979797; }

.EmergencyStepsModal_stepsContainer__1_GOG {
  display: flex;
  justify-content: space-around;
  align-items: baseline !important;
  margin-top: 10px !important; }

.EmergencyStepsModal_stepsGrid__110jp {
  margin-top: 1em !important; }

.EmergencyStepsModal_stepsFormControlContainer__3YNyr {
  align-items: flex-start;
  justify-content: space-between; }
  @media (min-width: 768px) {
    .EmergencyStepsModal_stepsFormControlContainer__3YNyr {
      display: flex; } }
  @media (max-width: 768px) {
    .EmergencyStepsModal_stepsFormControlContainer__3YNyr {
      flex-direction: column !important; } }

.EmergencyStepsModal_uploadFileContainer__3mWqX {
  display: flex;
  flex-wrap: wrap;
  min-height: 65px;
  align-items: center; }
  @media (max-width: 768px) {
    .EmergencyStepsModal_uploadFileContainer__3mWqX {
      flex-direction: column;
      margin-top: 25px !important; } }

.EmergencyStepsModal_uploadFilebutton__3mMfu {
  background-color: #50a7b4 !important;
  color: white !important;
  margin-right: 5px !important;
  margin-left: 12px !important; }

.EmergencyStepsModal_uploadFilebutton__3mMfu:hover {
  background-color: #448086 !important;
  color: white !important; }

.EmergencyStepsModal_fileNameContainer__3x2Yz {
  display: flex;
  align-items: center;
  border-left: 3px solid #448086;
  flex-wrap: wrap; }
  @media (max-width: 768px) {
    .EmergencyStepsModal_fileNameContainer__3x2Yz {
      flex-direction: column;
      margin-top: 15px;
      margin-left: 0; } }

.EmergencyStepsModal_addButton__30-H7, .EmergencyStepsModal_saveButton__3t27V {
  margin-top: 15px;
  background-color: #50a7b4 !important; }

.EmergencyStepsModal_addButton__30-H7:hover, .EmergencyStepsModal_saveButton__3t27V:hover {
  background-color: #448086 !important; }

.EmergencyStepsModal_addButton__30-H7:disabled, .EmergencyStepsModal_saveButton__3t27V:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important; }

.EmergencyStepsModal_addButtonIcon__NpmJN {
  margin-right: 5px; }

.EmergencyStepsModal_saveButton__3t27V {
  margin-left: 1em !important; }

.EmergencyStepsModal_newStepFormContainer__2nebc {
  background-color: #f0f0f0;
  border-radius: 25px;
  padding: 1em !important;
  margin-bottom: 1em !important;
  margin-top: 1em !important; }

.EmergencyStepsModal_newStepActionButtons__xZgps {
  display: flex;
  justify-content: flex-end; }

.EmergencyStepsModal_closeModalIcon__3S7Np {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.EmergencyStepsModal_listItemAction__3RLwY {
  color: red; }

.EmergencyStepsModal_listItem__3s0Wc {
  border-left: 3px solid #448086;
  margin: 1em; }

.EmergencyStepsModal_listItemDragging__29aUl {
  background-color: #50a7b4;
  border-radius: 25px; }

.EmergencyStepsModal_dialogSubtext__2xiIG {
  color: #a5a5a5 !important; }

.EmergencyStepsModal_tutoWrapper__34A1h {
  display: flex;
  flex-direction: column;
  text-align: center; }

.EmergencyStepsModal_tutoImgWrapper__3yaj0 {
  text-align: center;
  margin: 0.6em; }

@media (min-width: 768px) {
  .EmergencyStepsModal_tutoParentWrapper__IuRsL:after, .EmergencyStepsModal_tutoParentWrapper__IuRsL:before {
    right: 99%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; } }

@media (min-width: 768px) {
  .EmergencyStepsModal_tutoParentWrapper__IuRsL:after {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #ffffff;
    border-width: 15px;
    margin-top: -15px; } }

@media (min-width: 768px) {
  .EmergencyStepsModal_tutoParentWrapper__IuRsL:before {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: #ffffff;
    border-width: 21px;
    margin-top: -21px; } }

@media (max-width: 1400px) {
  .EmergencyStepsModal_UxTutoMask__3AMGT {
    fill: rgba(0, 0, 0, 0.9) !important;
    stroke: rgba(0, 0, 0, 0.9) !important; } }

/*font-family*/
/*font-size*/
/*font-weight*/
h2 {
  color: white;
  text-align: center;
  font-size: 30px; }
  @media (max-width: 768px) {
    h2 {
      margin-top: 0;
      font-size: 20px; } }

.BraceletList_rateUsContainer__3gH5v {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #50a7b4;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: -2px; }
  @media (min-width: 1024px) {
    .BraceletList_rateUsContainer__3gH5v {
      padding-top: 1rem;
      padding-bottom: 1rem; } }
  .BraceletList_rateUsContainer__3gH5v .BraceletList_rateUsCopy__kqpc3 {
    margin: 1px; }
  .BraceletList_rateUsContainer__3gH5v .BraceletList_rateIconItem__wG9hL {
    display: flex;
    justify-content: center; }
    .BraceletList_rateUsContainer__3gH5v .BraceletList_rateIconItem__wG9hL .BraceletList_rateIconButton__19N3n {
      background-color: rgba(255, 255, 255, 0.89); }
    .BraceletList_rateUsContainer__3gH5v .BraceletList_rateIconItem__wG9hL .BraceletList_rateIcon__3tylr {
      width: 3.5rem; }

.BraceletList_list__NfQTx {
  background-color: #50a7b4;
  padding-bottom: 75px; }
  .BraceletList_list__NfQTx .BraceletList_contentSingle__2YNO9 {
    min-height: 95vh;
    padding: 8em 18px 18px; }
  .BraceletList_list__NfQTx .BraceletList_content__2Tg35 {
    padding-top: 85px;
    min-height: 95vh; }
    @media (max-width: 374px) {
      .BraceletList_list__NfQTx .BraceletList_content__2Tg35 {
        padding-top: 135px; } }
  .BraceletList_list__NfQTx .BraceletList_noContent__1qVCy {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 58vh; }
  .BraceletList_list__NfQTx .BraceletList_chargingContent__1eMzO {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 85px;
    min-height: 95vh; }
  .BraceletList_list__NfQTx .BraceletList_chargingCircle__t3RGK {
    color: #fff8e3; }
  .BraceletList_list__NfQTx ul {
    list-style-type: none;
    padding-left: 0;
    margin: 0;
    margin-top: 35px;
    padding-bottom: 3em; }
    .BraceletList_list__NfQTx ul li {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 17px;
      margin-top: 20px; }
      .BraceletList_list__NfQTx ul li p, .BraceletList_list__NfQTx ul li h3 {
        margin-right: 10px; }
  .BraceletList_list__NfQTx .BraceletList_card__tsT5z {
    width: 100%; }
  .BraceletList_list__NfQTx .BraceletList_errorMessage___jwaY {
    color: #fff;
    text-align: center; }
  .BraceletList_list__NfQTx .BraceletList_buttonNew__3BQ0f {
    position: fixed;
    margin: 10px;
    right: 10px;
    bottom: 10px;
    background-color: #fff8e3;
    color: #448086;
    box-shadow: 5px 6px 8px grey;
    font-weight: bold; }
  .BraceletList_list__NfQTx .BraceletList_buttonNew__3BQ0f:hover {
    background-color: #efe9d6; }

.BraceletList_shareButtonsBox__Tlhkh {
  padding: 2em;
  display: flex;
  align-items: center;
  justify-content: space-evenly; }

.BraceletList_shareButton__EO0XY:hover {
  cursor: pointer; }

.BraceletList_multipleSelectionButton__1PRQv {
  position: fixed !important;
  margin: 10px !important;
  right: 10px !important;
  bottom: 60px !important;
  background-color: #ffffff !important;
  color: #448086 !important;
  box-shadow: 0px 5px 19px grey; }

.BraceletList_multipleSelectionControls__fX2hw {
  display: flex;
  position: fixed;
  bottom: 0;
  padding-top: 1.25em;
  padding-bottom: 1.25em;
  background-color: #fff;
  box-shadow: -1px 2px 42px 0px rgba(0, 0, 0, 0.75);
  width: 100%;
  z-index: 300;
  justify-content: space-evenly;
  align-items: center; }
  .BraceletList_multipleSelectionControls__fX2hw .BraceletList_multipleSelectionInfo__2kf2I {
    display: flex;
    align-items: center; }
    .BraceletList_multipleSelectionControls__fX2hw .BraceletList_multipleSelectionInfo__2kf2I .BraceletList_multipleSelectionInfoClose__nq9Az {
      margin-right: 0.5em; }

.BraceletList_closeModalIcon__3p2Ye {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.BraceletList_sendingDataAnimation__1BFkw {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.BraceletList_floatingActiveBraceletButton__r6mY_ {
  position: fixed !important;
  bottom: 1em;
  right: 1em;
  z-index: 99 !important;
  border-radius: 25px !important;
  background-color: #fff8e3 !important;
  color: #448086 !important;
  box-shadow: 3px 7px 20px 0px black; }

.BraceletList_activeIcon__2uIlM {
  color: #448086 !important;
  margin-right: 0.5em !important; }

.BraceletList_getNewPulsIdButton__1730Y {
  background-color: #ff8f00 !important;
  color: #ffffff !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
.BraceletCard_card__140zr {
  width: 80%; }
  @media (max-width: 768px) {
    .BraceletCard_card__140zr {
      width: 95%; } }
  .BraceletCard_card__140zr .BraceletCard_actions__3XmOR {
    justify-content: flex-end; }
  .BraceletCard_card__140zr .BraceletCard_assignContainer__1tWw7 {
    display: flex;
    align-items: center;
    padding: 0 20px 20px;
    justify-content: flex-end; }
    @media (max-width: 480px) {
      .BraceletCard_card__140zr .BraceletCard_assignContainer__1tWw7 {
        justify-content: center; } }
    .BraceletCard_card__140zr .BraceletCard_assignContainer__1tWw7 .BraceletCard_assignButton__19yc8 {
      text-transform: none;
      background-color: #50a7b4;
      border: none;
      border-radius: 6px;
      font-size: 20px;
      padding: 5px 10px;
      color: #fff;
      cursor: pointer; }
    .BraceletCard_card__140zr .BraceletCard_assignContainer__1tWw7 .BraceletCard_assignButton__19yc8:hover {
      background-color: #448086 !important; }
  .BraceletCard_card__140zr .BraceletCard_cardDataContainer__3tREm {
    display: flex;
    justify-content: space-between;
    padding: 10px; }
    @media (max-width: 768px) {
      .BraceletCard_card__140zr .BraceletCard_cardDataContainer__3tREm {
        flex-direction: column; } }
    .BraceletCard_card__140zr .BraceletCard_cardDataContainer__3tREm .BraceletCard_ListItemIcon__3UcbS div {
      display: flex;
      align-items: center; }
      .BraceletCard_card__140zr .BraceletCard_cardDataContainer__3tREm .BraceletCard_ListItemIcon__3UcbS div svg {
        margin-right: 5px; }
    .BraceletCard_card__140zr .BraceletCard_cardDataContainer__3tREm li {
      justify-content: space-between; }
      .BraceletCard_card__140zr .BraceletCard_cardDataContainer__3tREm li h3 {
        margin: 0; }
    @media (max-width: 768px) {
      .BraceletCard_card__140zr .BraceletCard_cardDataContainer__3tREm .BraceletCard_responsiveRow__1lqKP {
        flex-direction: column;
        align-items: flex-start; } }
  .BraceletCard_card__140zr .BraceletCard_avatar__2Ep5a {
    flex: 0 0 auto;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    align-items: center; }
    @media (max-width: 768px) {
      .BraceletCard_card__140zr .BraceletCard_avatar__2Ep5a {
        margin-right: 0; } }
  .BraceletCard_card__140zr .BraceletCard_avatarContainer__ljQgB {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin-top: 10px; }
    .BraceletCard_card__140zr .BraceletCard_avatarContainer__ljQgB div {
      margin: 10px 0 0; }
    .BraceletCard_card__140zr .BraceletCard_avatarContainer__ljQgB .BraceletCard_logoImg__5FssH {
      width: 150px;
      margin-bottom: 10px; }
    .BraceletCard_card__140zr .BraceletCard_avatarContainer__ljQgB .BraceletCard_visibilityIcon__2satM {
      color: #50a7b4;
      font-size: 35px;
      margin-top: 10px;
      visibility: hidden;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      font-size: 45px;
      z-index: 10;
      top: 35%;
      transform: translateY(-35%);
      -webkit-filter: drop-shadow(1px 1px 5px black);
      /* Chrome, Safari, Opera */
      filter: drop-shadow(1px 1px 5px black); }
  @media (min-width: 768px) {
    .BraceletCard_card__140zr .BraceletCard_visibilityIcon__2satM {
      visibility: visible;
      z-index: 10; }
    .BraceletCard_card__140zr .BraceletCard_avatarContainer__ljQgB:hover {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      cursor: pointer; }
      .BraceletCard_card__140zr .BraceletCard_avatarContainer__ljQgB:hover .BraceletCard_qrImage__3Avu9 {
        transition: all .15s ease;
        -webkit-filter: blur(3px);
        /* Chrome, Safari, Opera */
        filter: blur(3px); }
      .BraceletCard_card__140zr .BraceletCard_avatarContainer__ljQgB:hover div {
        margin: 10px 0 0; }
      .BraceletCard_card__140zr .BraceletCard_avatarContainer__ljQgB:hover .BraceletCard_logoImg__5FssH {
        width: 150px;
        margin-bottom: 10px; }
      .BraceletCard_card__140zr .BraceletCard_avatarContainer__ljQgB:hover .BraceletCard_visibilityIcon__2satM {
        visibility: visible;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        color: #fff9e4;
        left: 0;
        right: 0;
        z-index: 10;
        top: 35%;
        transform: translateY(-35%);
        -webkit-filter: drop-shadow(1px 1px 5px black);
        /* Chrome, Safari, Opera */
        filter: drop-shadow(1px 1px 5px black); } }
  .BraceletCard_card__140zr .BraceletCard_avatarBraceletContainer__17Xsp {
    display: flex;
    align-items: center;
    flex-direction: column; }
    .BraceletCard_card__140zr .BraceletCard_avatarBraceletContainer__17Xsp div {
      margin: 10px 0 0; }
    .BraceletCard_card__140zr .BraceletCard_avatarBraceletContainer__17Xsp .BraceletCard_logoImg__5FssH {
      width: 128px; }
  .BraceletCard_card__140zr .BraceletCard_cardHeader__3NS5U {
    width: 88%;
    display: flex; }
    @media (max-width: 480px) {
      .BraceletCard_card__140zr .BraceletCard_cardHeader__3NS5U {
        flex-direction: column; } }
  .BraceletCard_card__140zr .BraceletCard_cardHeaderTitle__3bMIg {
    text-align: start;
    font-size: 19px !important;
    font-family: Blogger-sans !important; }
    @media (max-width: 480px) {
      .BraceletCard_card__140zr .BraceletCard_cardHeaderTitle__3bMIg {
        text-align: center;
        margin-top: 5px; } }
  .BraceletCard_card__140zr .BraceletCard_subheaderCardText__2CpCa {
    font-size: 19px !important; }
    @media (max-width: 480px) {
      .BraceletCard_card__140zr .BraceletCard_subheaderCardText__2CpCa {
        text-align: center; } }

.BraceletCard_paddedList__2X2c6 {
  padding-left: 15px; }

.BraceletCard_ListIcon__fUU4b {
  color: #000000; }

.BraceletCard_contactDataContainer__1gIUw {
  position: relative; }
  .BraceletCard_contactDataContainer__1gIUw .BraceletCard_contactDataEnable__xbWxJ {
    position: absolute;
    right: 0;
    bottom: 10px; }

.BraceletCard_documentTypeText__3uOHz {
  min-width: 55px; }

.BraceletCard_documentViewPhotos__1VJdV {
  font-size: 13px;
  color: #50a7b4;
  font-weight: bold;
  cursor: pointer; }
  @media (min-width: 768px) {
    .BraceletCard_documentViewPhotos__1VJdV {
      margin-left: 2em; } }

.BraceletCard_documentSecondaryBox__68Hpi {
  display: flex; }
  @media (max-width: 480px) {
    .BraceletCard_documentSecondaryBox__68Hpi {
      flex-direction: column; } }

.BraceletCard_gridStepsContainer__1O9jQ {
  background-color: #f7f7f7 !important;
  border-radius: 25px !important;
  margin-bottom: 2em !important; }

.BraceletCard_gridContainer__3bDnn {
  background-color: #f7f7f7 !important;
  border-radius: 25px !important;
  display: flex;
  flex-direction: row; }

.BraceletCard_relationshipText__22a8i {
  color: #9a9a9a;
  font-size: 12px;
  margin-left: 5px; }

.BraceletCard_subheaderText__42ugU {
  font-weight: bold;
  font-size: 16px; }

.BraceletCard_braceletStateActions__3H7D2 {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  margin-top: 1em !important;
  flex-direction: column !important; }
  @media (min-width: 768px) {
    .BraceletCard_braceletStateActions__3H7D2 {
      flex-direction: row !important; } }

@media (max-width: 768px) {
  .BraceletCard_deleteButtons__Ipcuv {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; } }

.BraceletCard_customIcon__1xfTe {
  width: 24px; }

.BraceletCard_addButton__EmyVd, .BraceletCard_saveButton__2Kvh1 {
  margin-top: 15px;
  background-color: #50a7b4 !important; }

.BraceletCard_addButton__EmyVd:hover, .BraceletCard_saveButton__2Kvh1:hover {
  background-color: #448086 !important; }

.BraceletCard_addButton__EmyVd:disabled, .BraceletCard_saveButton__2Kvh1:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important; }

.BraceletCard_addButtonIcon__2TXgK {
  margin-right: 5px; }

.BraceletCard_erasePatientDataButton__2OnTh {
  margin-right: 2em !important; }
  @media (max-width: 768px) {
    .BraceletCard_erasePatientDataButton__2OnTh {
      margin-right: 0 !important; } }

@media (max-width: 768px) {
  .BraceletCard_erasePatientDataButton__2OnTh:nth-child(2) {
    margin-top: 1rem; } }

.BraceletCard_closeModalIcon__2iAWM {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.BraceletCard_sendingDataAnimation__3dMm6 {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.BraceletCard_downloadQrButton__JsN6K {
  margin-top: 0.75rem !important;
  background-color: #50a7b4 !important;
  color: #ffffff !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
.PasswordChangeModal_formControl__3BtIG {
  margin-top: 8px;
  min-width: 120px; }

.PasswordChangeModal_flexColumn__1vnU2 {
  display: flex;
  flex-direction: column; }

.PasswordChangeModal_actionsContainer__3qE3_ button {
  background-color: #50a7b4;
  color: white; }

.PasswordChangeModal_actionsContainer__3qE3_ button:hover {
  background-color: #448086;
  color: white; }

.PasswordChangeModal_noEqualPasswordsMessage__1wmWa {
  color: #ff483f !important;
  font-style: italic;
  margin-left: 10px !important; }

.PasswordChangeModal_noEqualPasswordsMessageBox__3djCi {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center !important;
  color: #ff483f !important;
  font-style: italic; }

.PasswordChangeModal_paymentModalTitleBox__30Vqb {
  display: flex;
  align-items: center; }

.PasswordChangeModal_sendingDataAnimation__35XWU {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.PasswordChangeModal_textField__2p-ZB label {
  color: #50a7b4 !important; }

.PasswordChangeModal_textField__2p-ZB div {
  padding-right: 0 !important; }
  .PasswordChangeModal_textField__2p-ZB div fieldset {
    border-color: #50a7b4 !important; }

.PasswordChangeModal_textField__2p-ZB .PasswordChangeModal_contentText__YHgfS {
  border-bottom: 10px; }

@font-face {
    font-family: 'Roboto Medium';
    src: url(/static/media/Roboto-Medium.58aef543.ttf) format('ttf');
}
@font-face {
    font-family: 'Roboto';
    src: url(/static/media/Roboto-Bold.e07df86c.ttf) format('ttf');
}

html, body, #app, #app>div, li, span, p, input, a, h2, h3, h4, h5, h6, textarea, legend, option, div {
  /*height: 100%*/
    /*font-family: blogger-sans, serif !important;*/
    font-family: 'Roboto', sans-serif !important; ;

}


/*font-family*/
/*font-size*/
/*font-weight*/
.codeModal_mainContainer__1WWI6 {
  margin-top: 0.5rem; }

.codeModal_formControl__1ir0e {
  margin-top: 8px;
  min-width: 120px; }

.codeModal_flexColumn__2D0ej {
  display: flex;
  flex-direction: column; }
  .codeModal_flexColumn__2D0ej button {
    background-color: #50a7b4;
    color: white; }
  .codeModal_flexColumn__2D0ej button:hover {
    background-color: #448086;
    color: white; }

.codeModal_checkbox__21n9v {
  color: #50a7b4 !important; }

.codeModal_noEqualPasswordsMessage__21YsZ {
  color: red !important;
  font-style: italic;
  margin-left: 20px; }

.codeModal_noEqualPasswordsMessageBox__1o8x- {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red !important;
  font-style: italic; }

.codeModal_paymentModalTitleBox__KUR6N {
  display: flex;
  align-items: center; }

.codeModal_privacyLink__jM5f5 {
  color: #716b77; }

.codeModal_privacyLink__jM5f5:hover {
  color: #0077b5;
  text-decoration: underline; }

.codeModal_sendingDataAnimation__12xeM {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.codeModal_closeModalIcon__37gNZ {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.codeModal_forgottenPasswordText__38AyA {
  margin-top: 10px !important;
  font-style: italic; }

.codeModal_textField__1sXQz label {
  color: #50a7b4 !important; }

.codeModal_textField__1sXQz div fieldset {
  border-color: #50a7b4 !important; }

.codeModal_buttonSubmit__FdzUy {
  padding: 3em !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
@-webkit-keyframes notFound_floatBracelet__r6Gwh {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }
@keyframes notFound_floatBracelet__r6Gwh {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@-webkit-keyframes notFound_floatVerticalBracelet__29oA2 {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes notFound_floatVerticalBracelet__29oA2 {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@-webkit-keyframes notFound_backgroundGradient__2bs7R {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes notFound_backgroundGradient__2bs7R {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@-webkit-keyframes notFound_backgroundGradient2__3qnh2 {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

@keyframes notFound_backgroundGradient2__3qnh2 {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

.notFound_notFound__9egLO {
  display: flex;
  flex-direction: column;
  margin-top: 150px;
  padding: 4% 20% 4% 20%;
  text-align: justify; }
  @media (max-width: 768px) {
    .notFound_notFound__9egLO {
      padding: 4% 20px 4% 20px;
      margin-top: 50px; } }
  .notFound_notFound__9egLO .notFound_numContainer__Lk2AQ {
    display: flex;
    align-items: center;
    justify-content: center; }
    .notFound_notFound__9egLO .notFound_numContainer__Lk2AQ h1 {
      color: #50a7b4;
      font-size: 180px; }
      @media (max-width: 768px) {
        .notFound_notFound__9egLO .notFound_numContainer__Lk2AQ h1 {
          font-size: 160px; } }
    .notFound_notFound__9egLO .notFound_numContainer__Lk2AQ img {
      margin-bottom: 35px;
      margin-left: 10px;
      margin-right: -5px;
      height: 100px;
      -webkit-animation: notFound_floatVerticalBracelet__29oA2 6s ease-in-out infinite;
              animation: notFound_floatVerticalBracelet__29oA2 6s ease-in-out infinite; }
      @media (max-width: 768px) {
        .notFound_notFound__9egLO .notFound_numContainer__Lk2AQ img {
          height: 90px; } }
  .notFound_notFound__9egLO .notFound_comeBackContainer__pWjr7 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; }
    .notFound_notFound__9egLO .notFound_comeBackContainer__pWjr7 h2 {
      color: #716b77; }

.notFound_navBar__28DKy {
  background-color: #50a7b4 !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
@-webkit-keyframes tutorialCompra_floatBracelet__2Bi5i {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }
@keyframes tutorialCompra_floatBracelet__2Bi5i {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@-webkit-keyframes tutorialCompra_floatVerticalBracelet__1_IYd {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes tutorialCompra_floatVerticalBracelet__1_IYd {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@-webkit-keyframes tutorialCompra_backgroundGradient__c5GVE {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes tutorialCompra_backgroundGradient__c5GVE {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@-webkit-keyframes tutorialCompra_backgroundGradient2__3HZUg {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

@keyframes tutorialCompra_backgroundGradient2__3HZUg {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

.tutorialCompra_container__K-2_q {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #fff8e3;
  min-height: 100vh; }

.tutorialCompra_videoBox__3ucSC {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    .tutorialCompra_videoBox__3ucSC {
      margin-top: 100px; } }

.tutorialCompra_embedBox__2uhrv {
  margin: 1em auto;
  width: 65%;
  padding: 10px; }
  @media (min-width: 1024px) {
    .tutorialCompra_embedBox__2uhrv {
      padding-top: 7em; } }

.tutorialCompra_navBar__1hGyU {
  background-color: #50a7b4 !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
@-webkit-keyframes allTutorials_floatBracelet__2s-3y {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }
@keyframes allTutorials_floatBracelet__2s-3y {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@-webkit-keyframes allTutorials_floatVerticalBracelet__3F9yc {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes allTutorials_floatVerticalBracelet__3F9yc {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@-webkit-keyframes allTutorials_backgroundGradient__kz4l0 {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes allTutorials_backgroundGradient__kz4l0 {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@-webkit-keyframes allTutorials_backgroundGradient2__3p0MS {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

@keyframes allTutorials_backgroundGradient2__3p0MS {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

.allTutorials_container__3e_ZX {
  display: flex;
  padding-top: 25%;
  justify-content: center;
  align-items: stretch;
  background-color: #fff8e3; }
  @media (min-width: 480px) {
    .allTutorials_container__3e_ZX {
      padding-top: 12%; } }

.allTutorials_gridItem__1b2qq {
  padding: 1rem; }
  @media (max-width: 768px) {
    .allTutorials_gridItem__1b2qq {
      padding-top: 2rem;
      padding-bottom: 2rem; } }

.allTutorials_videoTitle__22zvi {
  text-align: center;
  padding: 10px !important; }

.allTutorials_videoBox__3MHo1 {
  margin: 15px;
  display: flex;
  background-color: black;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.allTutorials_embedBox__cceYM {
  margin: 1em auto;
  margin-top: 2rem !important;
  width: 65%;
  padding: 10px; }
  @media (max-width: 768px) {
    .allTutorials_embedBox__cceYM {
      width: 83%; } }
  .allTutorials_embedBox__cceYM div:first-child {
    border-radius: 12px !important;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23) !important; }

.allTutorials_videoBorder__p-tlh iframe {
  border-radius: 25px !important; }

.allTutorials_navBar__dDWxo {
  background-color: #50a7b4 !important; }

.allTutorials_buttonContainer__38S-w {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 2rem; }
  .allTutorials_buttonContainer__38S-w .allTutorials_button__3OhM2 {
    margin-top: 2rem;
    padding: 10px;
    background: transparent;
    color: #4FA9B3;
    border: 2px #50a7b4 solid;
    border-radius: 5px;
    font-size: 30px; }

.allTutorials_ctaContainer__2ZqBj {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem; }
  @media (max-width: 768px) {
    .allTutorials_ctaContainer__2ZqBj {
      padding-top: 2rem;
      padding-bottom: 2rem; } }

.allTutorials_ctaTitle__50tXg {
  text-align: center !important;
  margin-bottom: auto !important;
  padding: 10px; }

.allTutorials_ctaButton__2__-C {
  margin-top: 2rem !important;
  background: #00BCD4 !important;
  color: #ffff !important;
  font-family: sans-serif !important;
  font-weight: bold !important;
  font-size: 17px !important;
  margin-bottom: 1rem !important;
  border-radius: 43rem !important;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23) !important; }
  @media (min-width: 768px) {
    .allTutorials_ctaButton__2__-C {
      width: 60% !important;
      padding: 2rem 2rem !important;
      margin-bottom: auto !important; } }

/*font-family*/
/*font-size*/
/*font-weight*/
@-webkit-keyframes tutorial_floatBracelet__2tvC7 {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }
@keyframes tutorial_floatBracelet__2tvC7 {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@-webkit-keyframes tutorial_floatVerticalBracelet__QifAX {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes tutorial_floatVerticalBracelet__QifAX {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@-webkit-keyframes tutorial_backgroundGradient__LfE9W {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes tutorial_backgroundGradient__LfE9W {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@-webkit-keyframes tutorial_backgroundGradient2__3Bgp3 {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

@keyframes tutorial_backgroundGradient2__3Bgp3 {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

.tutorial_container__3-AWu {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #fff8e3;
  min-height: 100vh; }

.tutorial_videoBox__wydMY {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    .tutorial_videoBox__wydMY {
      margin-top: 100px; } }

.tutorial_embedBox__XKck0 {
  margin: 1em auto;
  width: 65%;
  padding: 10px; }
  @media (min-width: 1024px) {
    .tutorial_embedBox__XKck0 {
      padding-top: 7em; } }

.tutorial_navBar__1KlDy {
  background-color: #50a7b4 !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
.ForgottenPasswordModal_dialogTitle__3MrQ6 {
  display: flex;
  align-items: baseline; }

.ForgottenPasswordModal_formControl__1Pvbc {
  margin-top: 8px;
  min-width: 120px; }

.ForgottenPasswordModal_flexColumn__3k6su {
  display: flex;
  flex-direction: column; }
  .ForgottenPasswordModal_flexColumn__3k6su button {
    background-color: #50a7b4;
    color: white; }
  .ForgottenPasswordModal_flexColumn__3k6su button:hover {
    background-color: #448086;
    color: white; }

.ForgottenPasswordModal_checkbox__2L7Ba {
  color: #50a7b4 !important; }

.ForgottenPasswordModal_noEqualPasswordsMessage__1HHeH {
  color: red !important;
  font-style: italic;
  margin-left: 20px; }

.ForgottenPasswordModal_noEqualPasswordsMessageBox__1-cDv {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red !important;
  font-style: italic; }

.ForgottenPasswordModal_paymentModalTitleBox__2fTGk {
  display: flex;
  align-items: center; }

.ForgottenPasswordModal_privacyLink__3v0aB {
  color: #716b77; }

.ForgottenPasswordModal_privacyLink__3v0aB:hover {
  color: #0077b5;
  text-decoration: underline; }

.ForgottenPasswordModal_sendingDataAnimation__2aEK_ {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.ForgottenPasswordModal_closeModalIcon__36VQM {
  margin-right: 1em; }

.ForgottenPasswordModal_forgottenPasswordText__25Z1k {
  margin-top: 10px !important;
  font-style: italic; }

.ForgottenPasswordModal_textField__2ehqn label {
  color: #50a7b4 !important; }

.ForgottenPasswordModal_textField__2ehqn div fieldset {
  border-color: #50a7b4 !important; }

.ForgottenPasswordModal_buttonSubmit__2EHJp {
  padding: 3em !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
@-webkit-keyframes PollLanding_floatBracelet__1FzH8 {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }
@keyframes PollLanding_floatBracelet__1FzH8 {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@-webkit-keyframes PollLanding_floatVerticalBracelet__3xNFI {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes PollLanding_floatVerticalBracelet__3xNFI {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@-webkit-keyframes PollLanding_backgroundGradient__1QsP7 {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes PollLanding_backgroundGradient__1QsP7 {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@-webkit-keyframes PollLanding_backgroundGradient2__2aEWn {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

@keyframes PollLanding_backgroundGradient2__2aEWn {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

.PollLanding_gridContainer__1YAID {
  padding-top: 7em; }

.PollLanding_gridContainer__1YAID, .PollLanding_returnButtonContainer__2ugTb {
  display: flex;
  justify-content: center; }

.PollLanding_thanksMessage__3prLA {
  text-align: center; }

.PollLanding_returnButton__d6vew {
  background-color: #50a7b4 !important;
  color: #fff8e3 !important; }

/*font-family*/
/*font-size*/
/*font-weight*/
@-webkit-keyframes FAQ_floatBracelet__3gffV {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }
@keyframes FAQ_floatBracelet__3gffV {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@-webkit-keyframes FAQ_floatVerticalBracelet__2Um9t {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes FAQ_floatVerticalBracelet__2Um9t {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@-webkit-keyframes FAQ_backgroundGradient__KZanl {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes FAQ_backgroundGradient__KZanl {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@-webkit-keyframes FAQ_backgroundGradient2__BDIPo {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

@keyframes FAQ_backgroundGradient2__BDIPo {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

.FAQ_parentContainer__1YBDg {
  display: flex;
  justify-content: center;
  min-height: 100vh;
  padding-bottom: 3em;
  background-image: linear-gradient(-20deg, #e9defa 0%, #fbfcdb 100%); }

.FAQ_faqCard__10Tds {
  border-radius: 15px !important; }

.FAQ_headImageContainer__3XXP5 {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-top: 5rem; }

@media (max-width: 768px) {
  .FAQ_headImage__32Rmn {
    display: none; } }

.FAQ_headImageWrapper__3TLFV {
  border-radius: 4px;
  padding: 1.8em;
  width: 100%;
  margin-top: -1em;
  text-align: center; }

.FAQ_panelDetails__1qLP6 {
  background-color: #eaf3ff;
  border-radius: 15px;
  margin: 0.7rem; }

.FAQ_panelDetailsTypography__L6Mk4 {
  font-size: 15px !important; }

.FAQ_backButton__2jxBb {
  position: absolute;
  left: 25px;
  top: 8rem; }
  .FAQ_backButton__2jxBb .FAQ_backIcon__cEICa {
    margin-right: 0.3em; }

.FAQ_buttonContainer__3A_x3 {
  display: flex;
  flex-direction: column;
  align-items: center; }
  .FAQ_buttonContainer__3A_x3 .FAQ_button__1AAF2 {
    margin-top: 2rem;
    padding: 10px;
    background: transparent;
    color: #50a7b4;
    border: 2px #50a7b4 solid;
    border-radius: 5px;
    font-size: 30px; }
  .FAQ_buttonContainer__3A_x3 .FAQ_primaryCTAButton__1IPpI {
    margin-top: 2rem;
    color: #ffffff;
    background: #FF6966;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    padding: 1em 2.5em;
    border-radius: 10px;
    cursor: pointer;
    font-size: 30px; }
    @media (max-width: 480px) {
      .FAQ_buttonContainer__3A_x3 .FAQ_primaryCTAButton__1IPpI {
        font-size: 20px; } }
  .FAQ_buttonContainer__3A_x3 .FAQ_primaryCTAButton__1IPpI:hover {
    background-color: #ea605d; }

.FAQ_tapIcon__SMyA7 {
  margin-left: 0.5rem;
  font-size: 2rem;
  transform: rotate(-25deg); }

/*font-family*/
/*font-size*/
/*font-weight*/
@-webkit-keyframes OurHistory_floatBracelet__xHXFd {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }
@keyframes OurHistory_floatBracelet__xHXFd {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@-webkit-keyframes OurHistory_floatVerticalBracelet__33SG9 {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes OurHistory_floatVerticalBracelet__33SG9 {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@-webkit-keyframes OurHistory_backgroundGradient__3Lb1k {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes OurHistory_backgroundGradient__3Lb1k {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@-webkit-keyframes OurHistory_backgroundGradient2__1_UZz {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

@keyframes OurHistory_backgroundGradient2__1_UZz {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

.OurHistory_Landing__3daZe {
  display: flex;
  flex-direction: column;
  text-align: justify;
  align-items: center;
  overflow-x: hidden;
  font-family: Aristotelica;
  background-color: #ffffff; }
  .OurHistory_Landing__3daZe h1 {
    color: white;
    text-align: center;
    font-size: 30px; }
  .OurHistory_Landing__3daZe h2 {
    color: white;
    text-align: center;
    font-size: 30px; }
    @media (max-width: 768px) {
      .OurHistory_Landing__3daZe h2 {
        margin-top: 0;
        font-size: 20px; } }
  .OurHistory_Landing__3daZe h3 {
    font-family: Blogger-sans; }
  .OurHistory_Landing__3daZe li {
    font-family: Blogger-sans; }
  .OurHistory_Landing__3daZe a {
    font-family: Blogger-sans; }
  .OurHistory_Landing__3daZe p {
    font-size: 20px;
    font-family: Blogger-sans;
    text-align: justify; }
  .OurHistory_Landing__3daZe .OurHistory_historyContainer__10Nts {
    min-height: 100vh;
    padding: 2rem;
    padding-top: 12rem !important;
    padding-bottom: rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(to bottom, #06beb6, #74a7b2);
    margin-bottom: 5rem; }
    @media (max-width: 480px) {
      .OurHistory_Landing__3daZe .OurHistory_historyContainer__10Nts {
        padding: 0;
        padding-top: 3rem;
        flex-direction: column;
        margin-bottom: 3rem; } }
    .OurHistory_Landing__3daZe .OurHistory_historyContainer__10Nts .OurHistory_historyContainerContent__32Qdh {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .OurHistory_Landing__3daZe .OurHistory_historyContainer__10Nts .OurHistory_historyContainerContent__32Qdh h1 {
        color: #fff; }
      .OurHistory_Landing__3daZe .OurHistory_historyContainer__10Nts .OurHistory_historyContainerContent__32Qdh p {
        color: #fff; }
      @media (max-width: 768px) {
        .OurHistory_Landing__3daZe .OurHistory_historyContainer__10Nts .OurHistory_historyContainerContent__32Qdh {
          padding-right: 2rem;
          padding-left: 2rem; } }
    .OurHistory_Landing__3daZe .OurHistory_historyContainer__10Nts .OurHistory_historyImageContainer__3K1kd {
      margin-top: 2rem;
      display: flex;
      justify-content: flex-end; }
      .OurHistory_Landing__3daZe .OurHistory_historyContainer__10Nts .OurHistory_historyImageContainer__3K1kd img {
        height: auto;
        width: 100%; }
        @media (min-width: 768px) {
          .OurHistory_Landing__3daZe .OurHistory_historyContainer__10Nts .OurHistory_historyImageContainer__3K1kd img {
            width: 75%;
            border-radius: 25px; } }
  .OurHistory_Landing__3daZe .OurHistory_enviromentContainer__39orw {
    display: flex;
    justify-content: center;
    background-color: #fff8e3; }
    @media (max-width: 480px) {
      .OurHistory_Landing__3daZe .OurHistory_enviromentContainer__39orw {
        padding-top: 40px; } }
    .OurHistory_Landing__3daZe .OurHistory_enviromentContainer__39orw .OurHistory_enviromentContainerContent__3FCtQ {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center; }
      @media (max-width: 480px) {
        .OurHistory_Landing__3daZe .OurHistory_enviromentContainer__39orw .OurHistory_enviromentContainerContent__3FCtQ {
          flex-direction: column-reverse; } }
    .OurHistory_Landing__3daZe .OurHistory_enviromentContainer__39orw .OurHistory_enviromentTextContainer__2b8Kh {
      max-width: 65%;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-right: 20px;
      align-items: center; }
      @media (max-width: 480px) {
        .OurHistory_Landing__3daZe .OurHistory_enviromentContainer__39orw .OurHistory_enviromentTextContainer__2b8Kh {
          max-width: 100%; } }
      .OurHistory_Landing__3daZe .OurHistory_enviromentContainer__39orw .OurHistory_enviromentTextContainer__2b8Kh h1 {
        color: #50a7b4; }
      @media (min-width: 1024px) {
        .OurHistory_Landing__3daZe .OurHistory_enviromentContainer__39orw .OurHistory_enviromentTextContainer__2b8Kh p {
          max-width: 70%; } }
    .OurHistory_Landing__3daZe .OurHistory_enviromentContainer__39orw .OurHistory_enviromentImageContainer__3b0-f {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      @media (min-width: 768px) {
        .OurHistory_Landing__3daZe .OurHistory_enviromentContainer__39orw .OurHistory_enviromentImageContainer__3b0-f {
          margin-left: 15px; } }
      .OurHistory_Landing__3daZe .OurHistory_enviromentContainer__39orw .OurHistory_enviromentImageContainer__3b0-f img {
        width: 100%;
        height: auto; }
        @media (min-width: 768px) {
          .OurHistory_Landing__3daZe .OurHistory_enviromentContainer__39orw .OurHistory_enviromentImageContainer__3b0-f img {
            border-radius: 25px;
            margin-top: 3em;
            margin-bottom: 3em; } }
  .OurHistory_Landing__3daZe .OurHistory_sampleContainer__34oDm {
    display: flex;
    justify-content: space-around;
    padding: 0 10px 40px 10px;
    width: 100%;
    margin-top: 0.25rem;
    flex-direction: row; }
    @media (max-width: 480px) {
      .OurHistory_Landing__3daZe .OurHistory_sampleContainer__34oDm {
        flex-direction: column; } }
    .OurHistory_Landing__3daZe .OurHistory_sampleContainer__34oDm .OurHistory_contentMock__fNyo4 {
      display: flex;
      justify-content: center;
      position: relative; }
      .OurHistory_Landing__3daZe .OurHistory_sampleContainer__34oDm .OurHistory_contentMock__fNyo4 .OurHistory_sampleImg__2nKGk {
        height: 350px;
        margin-right: -35px;
        align-self: flex-end;
        padding-right: 1em; }
        @media (min-width: 768px) {
          .OurHistory_Landing__3daZe .OurHistory_sampleContainer__34oDm .OurHistory_contentMock__fNyo4 .OurHistory_sampleImg__2nKGk {
            height: 400px;
            margin-right: 20px;
            padding-right: 0.5em; } }
        @media (min-width: 1024px) {
          .OurHistory_Landing__3daZe .OurHistory_sampleContainer__34oDm .OurHistory_contentMock__fNyo4 .OurHistory_sampleImg__2nKGk {
            height: 500px;
            padding-right: 4em; } }
      .OurHistory_Landing__3daZe .OurHistory_sampleContainer__34oDm .OurHistory_contentMock__fNyo4 .OurHistory_mockStepsView__1auZ3 {
        height: 325px;
        margin-right: -35px;
        z-index: 30;
        align-self: flex-end; }
        @media (min-width: 768px) {
          .OurHistory_Landing__3daZe .OurHistory_sampleContainer__34oDm .OurHistory_contentMock__fNyo4 .OurHistory_mockStepsView__1auZ3 {
            height: 350px;
            margin-right: -2em; } }
        @media (min-width: 1024px) {
          .OurHistory_Landing__3daZe .OurHistory_sampleContainer__34oDm .OurHistory_contentMock__fNyo4 .OurHistory_mockStepsView__1auZ3 {
            height: 450px;
            margin-right: -2.50em; } }
      .OurHistory_Landing__3daZe .OurHistory_sampleContainer__34oDm .OurHistory_contentMock__fNyo4 .OurHistory_mockContainerImg__3exn1 {
        position: absolute;
        width: 250px;
        bottom: -8%;
        left: -20%;
        z-index: 50; }
        @media (max-width: 768px) {
          .OurHistory_Landing__3daZe .OurHistory_sampleContainer__34oDm .OurHistory_contentMock__fNyo4 .OurHistory_mockContainerImg__3exn1 {
            width: 160px;
            bottom: -6%;
            left: -17%; } }
        @media (max-width: 480px) {
          .OurHistory_Landing__3daZe .OurHistory_sampleContainer__34oDm .OurHistory_contentMock__fNyo4 .OurHistory_mockContainerImg__3exn1 {
            left: 0; } }
    .OurHistory_Landing__3daZe .OurHistory_sampleContainer__34oDm .OurHistory_contentList__uoZHK {
      margin-right: 40px; }
      @media (max-width: 480px) {
        .OurHistory_Landing__3daZe .OurHistory_sampleContainer__34oDm .OurHistory_contentList__uoZHK {
          margin-right: 20px;
          margin-left: 20px; } }
      .OurHistory_Landing__3daZe .OurHistory_sampleContainer__34oDm .OurHistory_contentList__uoZHK h1 {
        color: #50a7b4; }
        @media (min-width: 768px) {
          .OurHistory_Landing__3daZe .OurHistory_sampleContainer__34oDm .OurHistory_contentList__uoZHK h1 {
            padding-left: 66px; } }
      .OurHistory_Landing__3daZe .OurHistory_sampleContainer__34oDm .OurHistory_contentList__uoZHK .OurHistory_content__3zYQL {
        display: flex;
        justify-content: space-around; }
      .OurHistory_Landing__3daZe .OurHistory_sampleContainer__34oDm .OurHistory_contentList__uoZHK .OurHistory_sampleList__1gNSy {
        list-style-type: none;
        text-align: left;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-weight: bold; }
        @media (max-width: 768px) {
          .OurHistory_Landing__3daZe .OurHistory_sampleContainer__34oDm .OurHistory_contentList__uoZHK .OurHistory_sampleList__1gNSy {
            font-size: 17px;
            padding-left: 0; } }
        .OurHistory_Landing__3daZe .OurHistory_sampleContainer__34oDm .OurHistory_contentList__uoZHK .OurHistory_sampleList__1gNSy li {
          display: flex;
          align-items: center; }
  .OurHistory_Landing__3daZe .OurHistory_rateUsContainer__3dcRE {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #50a7b4;
    padding-top: 1rem;
    padding-bottom: 5rem;
    margin-bottom: -2px; }
    @media (min-width: 1024px) {
      .OurHistory_Landing__3daZe .OurHistory_rateUsContainer__3dcRE {
        padding-top: 3rem;
        padding-bottom: 4rem; } }
  .OurHistory_Landing__3daZe .OurHistory_rateUsCopy__338Jw {
    margin: 2rem; }
  .OurHistory_Landing__3daZe .OurHistory_rateIconItem__sV4pn {
    display: flex;
    justify-content: center; }
    .OurHistory_Landing__3daZe .OurHistory_rateIconItem__sV4pn .OurHistory_rateIconButton__1bZWd {
      background-color: rgba(255, 255, 255, 0.89); }
    .OurHistory_Landing__3daZe .OurHistory_rateIconItem__sV4pn .OurHistory_rateIcon__PlqP5 {
      width: 3.5rem; }
  .OurHistory_Landing__3daZe .OurHistory_reviewsContainer__thCH3 {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #50a7b4;
    padding-top: 4rem;
    margin-bottom: -2px; }
  .OurHistory_Landing__3daZe .OurHistory_reviewGridItem__12ou9 {
    margin: 1rem; }
    .OurHistory_Landing__3daZe .OurHistory_reviewGridItem__12ou9 button {
      font-size: 30px;
      background-color: transparent;
      color: #fff8e3;
      border: none;
      padding: 10px 20px;
      border-radius: 6px;
      cursor: pointer; }
    .OurHistory_Landing__3daZe .OurHistory_reviewGridItem__12ou9 .OurHistory_reviewContainerContent__3VNBj {
      display: flex;
      align-items: center;
      flex-direction: column; }
      .OurHistory_Landing__3daZe .OurHistory_reviewGridItem__12ou9 .OurHistory_reviewContainerContent__3VNBj .OurHistory_reviewHead__1p1T_ {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #ffffff; }
      .OurHistory_Landing__3daZe .OurHistory_reviewGridItem__12ou9 .OurHistory_reviewContainerContent__3VNBj .OurHistory_reviewLogo__26MTd {
        width: 150px;
        height: 150px;
        object-fit: cover;
        background-color: #ffffff;
        border-radius: 50%; }
        @media (max-width: 768px) {
          .OurHistory_Landing__3daZe .OurHistory_reviewGridItem__12ou9 .OurHistory_reviewContainerContent__3VNBj .OurHistory_reviewLogo__26MTd {
            width: 80px;
            height: 80px; } }
      .OurHistory_Landing__3daZe .OurHistory_reviewGridItem__12ou9 .OurHistory_reviewContainerContent__3VNBj .OurHistory_reviewDataContainer__2IXGt {
        display: flex;
        color: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: start; }
        @media (max-width: 768px) {
          .OurHistory_Landing__3daZe .OurHistory_reviewGridItem__12ou9 .OurHistory_reviewContainerContent__3VNBj .OurHistory_reviewDataContainer__2IXGt {
            max-width: 500px;
            margin-top: 20px;
            text-align: justify; } }
        .OurHistory_Landing__3daZe .OurHistory_reviewGridItem__12ou9 .OurHistory_reviewContainerContent__3VNBj .OurHistory_reviewDataContainer__2IXGt .OurHistory_reviewTitle__2HXWR {
          margin-top: 1rem;
          color: #ffffff;
          text-align: center; }
        .OurHistory_Landing__3daZe .OurHistory_reviewGridItem__12ou9 .OurHistory_reviewContainerContent__3VNBj .OurHistory_reviewDataContainer__2IXGt .OurHistory_starsContainer__2PcAs {
          display: flex;
          align-items: center; }
        .OurHistory_Landing__3daZe .OurHistory_reviewGridItem__12ou9 .OurHistory_reviewContainerContent__3VNBj .OurHistory_reviewDataContainer__2IXGt a {
          text-decoration: underline;
          color: white;
          margin-bottom: 10px; }
        .OurHistory_Landing__3daZe .OurHistory_reviewGridItem__12ou9 .OurHistory_reviewContainerContent__3VNBj .OurHistory_reviewDataContainer__2IXGt p {
          font-weight: bold;
          font-size: 17px;
          margin-bottom: 8px; }
        @media (max-width: 768px) {
          .OurHistory_Landing__3daZe .OurHistory_reviewGridItem__12ou9 .OurHistory_reviewContainerContent__3VNBj .OurHistory_reviewDataContainer__2IXGt h6 {
            max-width: 200px;
            text-align: start;
            margin: 10px 0 0; } }
  .OurHistory_Landing__3daZe .OurHistory_newsContainer__hNE3r {
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    width: 100%; }
    .OurHistory_Landing__3daZe .OurHistory_newsContainer__hNE3r button {
      font-size: 30px;
      background-color: transparent;
      color: #fff8e3;
      border: none;
      padding: 10px 20px;
      border-radius: 6px;
      cursor: pointer; }
    .OurHistory_Landing__3daZe .OurHistory_newsContainer__hNE3r .OurHistory_newsContainerContent__3j53n {
      display: flex;
      align-items: center;
      background-color: #50a7b4;
      padding-bottom: 3rem;
      min-height: 20rem;
      padding-left: 150px;
      padding-right: 150px; }
      @media (max-width: 768px) {
        .OurHistory_Landing__3daZe .OurHistory_newsContainer__hNE3r .OurHistory_newsContainerContent__3j53n {
          padding-right: 10px;
          padding-left: 10px;
          flex-direction: column;
          min-height: 27rem; } }
      .OurHistory_Landing__3daZe .OurHistory_newsContainer__hNE3r .OurHistory_newsContainerContent__3j53n .OurHistory_newsLogo__GIGdH {
        width: 150px;
        height: 150px;
        object-fit: contain;
        background-color: #ffffff;
        border-radius: 50%; }
        @media (max-width: 768px) {
          .OurHistory_Landing__3daZe .OurHistory_newsContainer__hNE3r .OurHistory_newsContainerContent__3j53n .OurHistory_newsLogo__GIGdH {
            width: 50px;
            height: 50px;
            position: absolute;
            margin: 15px 0 0 90px; } }
      .OurHistory_Landing__3daZe .OurHistory_newsContainer__hNE3r .OurHistory_newsContainerContent__3j53n .OurHistory_newsDataContainer__JQQmH {
        padding-left: 50px;
        display: flex;
        color: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        text-align: start; }
        @media (max-width: 768px) {
          .OurHistory_Landing__3daZe .OurHistory_newsContainer__hNE3r .OurHistory_newsContainerContent__3j53n .OurHistory_newsDataContainer__JQQmH {
            max-width: 500px;
            margin-top: 20px;
            margin-right: 50px;
            text-align: justify; } }
        .OurHistory_Landing__3daZe .OurHistory_newsContainer__hNE3r .OurHistory_newsContainerContent__3j53n .OurHistory_newsDataContainer__JQQmH a {
          text-decoration: underline;
          color: white;
          margin-bottom: 10px; }
        .OurHistory_Landing__3daZe .OurHistory_newsContainer__hNE3r .OurHistory_newsContainerContent__3j53n .OurHistory_newsDataContainer__JQQmH p {
          font-weight: bold;
          font-size: 17px;
          margin-bottom: 8px;
          margin-top: 30px; }
        @media (max-width: 768px) {
          .OurHistory_Landing__3daZe .OurHistory_newsContainer__hNE3r .OurHistory_newsContainerContent__3j53n .OurHistory_newsDataContainer__JQQmH h2 {
            max-width: 200px;
            text-align: start;
            margin: 10px 0 0; } }
  .OurHistory_Landing__3daZe .OurHistory_footerContainer__1Ak2S {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    @media (max-width: 768px) {
      .OurHistory_Landing__3daZe .OurHistory_footerContainer__1Ak2S {
        align-items: center; } }
    .OurHistory_Landing__3daZe .OurHistory_footerContainer__1Ak2S .OurHistory_sevi__28VnJ {
      cursor: pointer;
      align-self: center;
      color: #716b77;
      margin-top: 20px;
      text-align: center; }
    .OurHistory_Landing__3daZe .OurHistory_footerContainer__1Ak2S .OurHistory_codeModal__1gwYw {
      cursor: pointer;
      align-self: center;
      margin: 10px;
      text-align: center;
      text-decoration: underline; }
    .OurHistory_Landing__3daZe .OurHistory_footerContainer__1Ak2S .OurHistory_footerContent__sUsI6 {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center; }
      @media (max-width: 768px) {
        .OurHistory_Landing__3daZe .OurHistory_footerContainer__1Ak2S .OurHistory_footerContent__sUsI6 {
          flex-direction: column; } }
      .OurHistory_Landing__3daZe .OurHistory_footerContainer__1Ak2S .OurHistory_footerContent__sUsI6 .OurHistory_footerLawsContainer__3OzdC {
        display: flex;
        margin: 0.5rem; }
        .OurHistory_Landing__3daZe .OurHistory_footerContainer__1Ak2S .OurHistory_footerContent__sUsI6 .OurHistory_footerLawsContainer__3OzdC p {
          margin: 0.25rem;
          font-size: 15px;
          color: #716b77; }
      .OurHistory_Landing__3daZe .OurHistory_footerContainer__1Ak2S .OurHistory_footerContent__sUsI6 .OurHistory_seviLogo__3Rzd2 {
        width: 150px; }
      .OurHistory_Landing__3daZe .OurHistory_footerContainer__1Ak2S .OurHistory_footerContent__sUsI6 .OurHistory_footerLogosContainer__kakLJ {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 25rem;
        margin-top: 2rem; }
      .OurHistory_Landing__3daZe .OurHistory_footerContainer__1Ak2S .OurHistory_footerContent__sUsI6 .OurHistory_footerContentServices__3voD7 {
        display: flex;
        flex-direction: column; }
        .OurHistory_Landing__3daZe .OurHistory_footerContainer__1Ak2S .OurHistory_footerContent__sUsI6 .OurHistory_footerContentServices__3voD7 div {
          max-width: 400px; }

.OurHistory_modalInfoHeader__3Wkvj {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px; }

.OurHistory_modalInfoContent__3x-Ey {
  display: flex;
  align-items: center;
  justify-content: space-around; }
  @media (max-width: 768px) {
    .OurHistory_modalInfoContent__3x-Ey {
      flex-direction: column; } }
  .OurHistory_modalInfoContent__3x-Ey li {
    font-size: 20px;
    margin-top: 10px; }
  .OurHistory_modalInfoContent__3x-Ey .OurHistory_imageHand__1S8Pg {
    width: 15rem;
    height: 15rem; }
  .OurHistory_modalInfoContent__3x-Ey .OurHistory_imageKid__2OH1U {
    width: 11rem;
    height: 22rem; }

.OurHistory_seeMoreText__2db50 {
  text-decoration: underline; }

.OurHistory_cookiesLayer__1K3hq {
  width: 100%;
  height: 100%;
  background: rgba(128, 128, 128, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 6000; }
  .OurHistory_cookiesLayer__1K3hq .OurHistory_snackbar__1Fzb7 {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 6rem;
    width: 100%;
    height: 22rem;
    font-size: 1.2rem;
    text-align: center; }
    @media (max-width: 1024px) {
      .OurHistory_cookiesLayer__1K3hq .OurHistory_snackbar__1Fzb7 {
        width: 75%;
        padding: 2rem; } }
    .OurHistory_cookiesLayer__1K3hq .OurHistory_snackbar__1Fzb7 .OurHistory_moreInfoCookieText__1ytMx {
      color: white;
      margin: 5px; }
    .OurHistory_cookiesLayer__1K3hq .OurHistory_snackbar__1Fzb7 button {
      background-color: transparent;
      color: white;
      border: none; }

.OurHistory_finalImgContainer__21j0Q {
  position: relative;
  margin: 0; }
  .OurHistory_finalImgContainer__21j0Q .OurHistory_asterisco__2rtoN {
    position: absolute;
    bottom: 2px;
    font-size: 15px !important;
    color: #efe9d6;
    right: 20%; }
    @media (max-width: 768px) {
      .OurHistory_finalImgContainer__21j0Q .OurHistory_asterisco__2rtoN {
        right: 41%; } }

.OurHistory_cookiesMoreInfoButton__3jdaB {
  background-color: #50a7b4 !important; }

.OurHistory_cookiesMoreInfoButton__3jdaB:hover {
  background-color: #448086 !important; }

.OurHistory_privacy__5RyLG {
  cursor: pointer; }

.OurHistory_privacy__5RyLG:hover {
  text-decoration: underline; }

.OurHistory_shareButtonsBox__1aPXp {
  padding: 2em;
  padding-bottom: 1em !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly; }

.OurHistory_shareButton__1068W:hover {
  cursor: pointer; }

.OurHistory_floatingButtonGetIt__8QUk_, .OurHistory_floatingButtonGetItLogged__1r8ya {
  position: fixed !important;
  bottom: 1em !important;
  right: 1em !important;
  box-shadow: 3px 7px 20px 0px black;
  color: #448086 !important;
  background-color: #fff8e3 !important;
  z-index: 10; }
  @media (min-width: 768px) {
    .OurHistory_floatingButtonGetIt__8QUk_, .OurHistory_floatingButtonGetItLogged__1r8ya {
      display: none !important; } }

.OurHistory_floatingButtonGetItLogged__1r8ya {
  bottom: 5em !important; }

.OurHistory_floatingButtonGetItIcon__1dUEf {
  margin-right: 0.3em; }

.OurHistory_companiesTrustContainer__3F9X_ {
  margin-top: 3em !important;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 768px) {
    .OurHistory_companiesTrustContainer__3F9X_ {
      padding-bottom: 5em;
      flex-direction: column; } }
  .OurHistory_companiesTrustContainer__3F9X_ .OurHistory_companiesTrustContent__Q2Y5M {
    display: flex;
    align-items: center; }
  .OurHistory_companiesTrustContainer__3F9X_ .OurHistory_companiesTrustLogoEnisa__2JIlV {
    width: 150px;
    margin-right: 1.25rem;
    height: auto; }
    @media (max-width: 480px) {
      .OurHistory_companiesTrustContainer__3F9X_ .OurHistory_companiesTrustLogoEnisa__2JIlV {
        max-height: 8rem;
        margin-right: 0.5rem;
        width: 75px; } }
  .OurHistory_companiesTrustContainer__3F9X_ .OurHistory_companiesTrustLogo__21Js0 {
    width: 190px;
    height: auto; }
    @media (max-width: 480px) {
      .OurHistory_companiesTrustContainer__3F9X_ .OurHistory_companiesTrustLogo__21Js0 {
        max-height: 8rem;
        width: 135px; } }

.OurHistory_companiesTrustClaim__3Dtgp {
  color: #ffffff !important;
  font-size: 80px !important;
  text-align: center !important;
  padding-bottom: 6rem !important;
  margin-top: 1rem !important;
  min-width: 75%;
  max-width: 90%;
  border-bottom: 0.25rem solid #fff; }
  @media (max-width: 480px) {
    .OurHistory_companiesTrustClaim__3Dtgp {
      min-width: 90% !important;
      max-width: 90% !important;
      font-size: 30px !important;
      padding-bottom: 2.25rem !important;
      margin-bottom: 1rem !important; } }

.OurHistory_floatingActiveBraceletButton__1VoUS {
  position: fixed !important;
  border-radius: 25px !important;
  background-color: #fff8e3 !important;
  color: #448086 !important;
  bottom: 1em;
  right: 1em;
  box-shadow: 3px 7px 20px 0px black; }

.OurHistory_activeIcon__1N16Y {
  color: #448086 !important;
  margin-right: 0.5em !important; }

.OurHistory_FbLikeButtonContainer__1amRX {
  padding-top: 1em; }
  @media (min-width: 768px) {
    .OurHistory_FbLikeButtonContainer__1amRX {
      padding-top: 3em; } }

.OurHistory_facebookLikeButton__3R1zT {
  display: flex !important;
  align-items: center !important;
  margin-top: 2em !important;
  padding-right: 1.5em !important;
  font-size: 11px !important;
  background-color: #3C5A99 !important;
  border-radius: 6px; }
  @media (max-width: 768px) {
    .OurHistory_facebookLikeButton__3R1zT {
      display: none !important; } }
  @media (min-width: 768px) {
    .OurHistory_facebookLikeButton__3R1zT {
      font-size: 13px !important;
      padding-right: 1.7em !important; } }

.OurHistory_facebookLike__rGpcl {
  height: 30px;
  width: 30px; }
  @media (min-width: 768px) {
    .OurHistory_facebookLike__rGpcl {
      height: 45px;
      width: 45px; } }

.OurHistory_pulsidHeadImage__hMwdV {
  display: none;
  height: 100px;
  width: 135px; }
  @media (max-width: 768px) {
    .OurHistory_pulsidHeadImage__hMwdV {
      display: block; } }

.OurHistory_pulsidPriceBadgeDesktop__1zS0l {
  min-width: 4rem;
  min-height: 4rem;
  background-color: #c3dd36;
  font-size: large !important;
  font-weight: bold; }
  @media (max-width: 768px) {
    .OurHistory_pulsidPriceBadgeDesktop__1zS0l {
      display: none !important; } }

.OurHistory_pulsidPriceBadgeAlways__Atpll {
  min-width: 4rem;
  min-height: 4rem;
  background-color: #c3dd36;
  font-size: large !important;
  font-weight: bold; }

.OurHistory_pulsidPriceBadge__1AYhV {
  display: none !important; }
  @media (max-width: 768px) {
    .OurHistory_pulsidPriceBadge__1AYhV {
      display: block !important; } }
  .OurHistory_pulsidPriceBadge__1AYhV span:last-child {
    min-width: 3rem;
    background-color: #c3dd36 !important;
    font-size: medium;
    font-weight: bold;
    min-height: 3rem; }

.OurHistory_discountSticker__Wr3nT {
  position: absolute;
  right: -25px;
  top: -25px;
  border-radius: 50%;
  font-size: 15px;
  padding: 1.3em 1em;
  background-color: #ff5a5a;
  color: #ffffff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12); }

.OurHistory_offerSnackbar__3q2lm {
  width: 100%;
  max-width: 100% !important;
  text-align: center;
  font-size: 17px !important; }
  .OurHistory_offerSnackbar__3q2lm div:first-child {
    width: 100%;
    max-width: 100% !important;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    font-size: 17px !important; }

.OurHistory_snackOfferButton__25HKJ {
  background-color: #448086;
  color: #fff8e3; }

.OurHistory_offerBanner__31Ujd {
  position: fixed;
  display: flex;
  z-index: 55;
  bottom: 0;
  width: 100%;
  padding: 1em;
  text-align: center;
  font-size: 20px;
  background-color: #ff0012;
  color: white; }
  @media (max-width: 768px) {
    .OurHistory_offerBanner__31Ujd {
      flex-direction: column;
      display: none; } }

.OurHistory_offerBannerMobile__sz-4G {
  position: fixed;
  display: none;
  z-index: 55;
  bottom: 0;
  width: 100%;
  padding: 1em;
  text-align: center;
  font-size: 20px;
  background-color: #FF0012;
  color: white; }
  @media (max-width: 768px) {
    .OurHistory_offerBannerMobile__sz-4G {
      display: flex;
      justify-content: space-around; } }

.OurHistory_closeOfferBanner__2ceZI {
  position: absolute;
  right: 25px;
  top: 8px; }
  @media (max-width: 768px) {
    .OurHistory_closeOfferBanner__2ceZI {
      position: absolute;
      right: 5px;
      top: 5px; } }

.OurHistory_offerBannerCountdown__i5YCl {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 768px) {
    .OurHistory_offerBannerCountdown__i5YCl {
      margin-top: 1.4em !important; } }

@media (max-width: 768px) {
  .OurHistory_offerBannerCountdown__i5YCl, .OurHistory_offerClaim__2QtUk {
    display: flex;
    flex-direction: column; } }

.OurHistory_offerClaim__2QtUk span:first-child {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    .OurHistory_offerClaim__2QtUk span:first-child {
      display: inline-flex !important; } }

.OurHistory_offerButtonBox__2-nD5 {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 768px) {
    .OurHistory_offerButtonBox__2-nD5 {
      margin-top: 1em !important; } }

.OurHistory_offerBannerGetItButton__2lN0_ {
  background-color: #00B32C !important;
  color: #ffffff !important; }

.OurHistory_closeOfferBannerIcon__SqwZ3 {
  color: #ffffff; }

.OurHistory_whatsappPostSection__VShq7 {
  display: flex;
  justify-content: space-around;
  background-color: #74a7b1;
  width: 100%;
  padding-top: 2rem;
  margin-bottom: -2px;
  color: #fff; }

.OurHistory_whatsappPostClaim__2xi2q {
  text-align: center !important; }

.OurHistory_whatsappPostItem__3NzNP {
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 2rem !important; }
  .OurHistory_whatsappPostItem__3NzNP .OurHistory_downloadDocumentButton__1lmHy {
    background-color: #fff8e3; }

.OurHistory_whatsappPostImage__3PvXh {
  height: 250px; }

.OurHistory_newsletterSectionGrid__2WxHQ {
  padding-top: 0;
  padding-bottom: 2rem;
  margin-top: -4px;
  margin-bottom: -4px;
  background-color: #ffffff; }
  @media (min-width: 768px) {
    .OurHistory_newsletterSectionGrid__2WxHQ {
      background-color: #50a7b4;
      padding-top: 2rem;
      padding-bottom: 2rem; } }

.OurHistory_freeShippingClaim__WSnUs {
  color: #ffffff;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: 'Roboto Bold', sans-serif;
  padding-left: 0.4rem;
  padding-right: 1.2rem; }
  .OurHistory_freeShippingClaim__WSnUs .OurHistory_countDown__12LWC {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    font-family: 'Roboto Bold', sans-serif !important;
    font-size: 17px; }
    @media (min-width: 768px) {
      .OurHistory_freeShippingClaim__WSnUs .OurHistory_countDown__12LWC {
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 30px; } }
  .OurHistory_freeShippingClaim__WSnUs span {
    font-family: 'Roboto Bold', sans-serif !important;
    font-size: 20px; }
    @media (min-width: 768px) {
      .OurHistory_freeShippingClaim__WSnUs span {
        font-size: 30px; } }

.OurHistory_CTAContainer__-taHl {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 4rem; }
  .OurHistory_CTAContainer__-taHl .OurHistory_tapIcon__1C8KS {
    margin-left: 0.5rem;
    font-size: 2rem;
    transform: rotate(-25deg); }
  .OurHistory_CTAContainer__-taHl .OurHistory_primaryCTAButton__2_Vf7 {
    color: #ffffff;
    background: #FF6966;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    padding: 1em 2.5em;
    border-radius: 10px;
    cursor: pointer;
    font-size: 30px; }
    @media (max-width: 480px) {
      .OurHistory_CTAContainer__-taHl .OurHistory_primaryCTAButton__2_Vf7 {
        font-size: 20px; } }
  .OurHistory_CTAContainer__-taHl .OurHistory_primaryCTAButton__2_Vf7:hover {
    background-color: #ea605d; }
  .OurHistory_CTAContainer__-taHl .OurHistory_secondaryCTAButton__3QEOa {
    color: #50a7b4 !important;
    border: 2px solid !important;
    padding: 1em 2.5em !important;
    border-radius: 10px !important;
    cursor: pointer !important;
    font-size: 30px !important; }
    @media (max-width: 480px) {
      .OurHistory_CTAContainer__-taHl .OurHistory_secondaryCTAButton__3QEOa {
        font-size: 20px !important; } }

.OurHistory_buyedClaimContainer__3nrHU {
  display: flex;
  justify-content: center;
  margin-bottom: 0.25rem !important; }
  @media (min-width: 1024px) {
    .OurHistory_buyedClaimContainer__3nrHU {
      margin-bottom: 1.25rem !important; } }

.OurHistory_buyedClaimBox__yAlZF, .OurHistory_buyNowClaimBox__qob57 {
  width: 100%;
  height: 2.5rem;
  color: #000;
  background-color: #ffffff;
  font-size: 17px;
  text-align: center;
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: 5rem; }
  @media (min-width: 768px) {
    .OurHistory_buyedClaimBox__yAlZF, .OurHistory_buyNowClaimBox__qob57 {
      font-size: 20px;
      margin-right: -1rem;
      margin-left: -1rem; } }

.OurHistory_buyNowClaimBox__qob57 {
  background-color: #FF6966; }

.OurHistory_countUp__1BCE1 {
  margin-top: 3rem !important;
  color: #fff;
  font-size: 70px !important;
  text-align: center; }
  @media (min-width: 768px) {
    .OurHistory_countUp__1BCE1 {
      margin-top: 6rem !important; } }

.OurHistory_footerVideo___Pg1D {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 3rem; }
  @media (min-width: 1024px) {
    .OurHistory_footerVideo___Pg1D {
      width: 50%;
      box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.13); } }

.OurHistory_navidadCollage__1qS1y {
  width: 11rem;
  margin-top: 2rem; }
  @media (min-width: 1024px) {
    .OurHistory_navidadCollage__1qS1y {
      margin-top: 6rem;
      width: 14rem; } }

/*font-family*/
/*font-size*/
/*font-weight*/
@-webkit-keyframes WeAreEco_floatBracelet__28A1f {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }
@keyframes WeAreEco_floatBracelet__28A1f {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@-webkit-keyframes WeAreEco_floatVerticalBracelet__3HlEY {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes WeAreEco_floatVerticalBracelet__3HlEY {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@-webkit-keyframes WeAreEco_backgroundGradient__-vldn {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes WeAreEco_backgroundGradient__-vldn {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@-webkit-keyframes WeAreEco_backgroundGradient2__2MAgF {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

@keyframes WeAreEco_backgroundGradient2__2MAgF {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

.WeAreEco_Landing__3WtSs {
  display: flex;
  flex-direction: column;
  text-align: justify;
  align-items: center;
  overflow-x: hidden;
  font-family: Aristotelica;
  background-color: #ffffff; }
  .WeAreEco_Landing__3WtSs h1 {
    color: white;
    text-align: center;
    font-size: 30px; }
  .WeAreEco_Landing__3WtSs h2 {
    color: white;
    text-align: center;
    font-size: 30px; }
    @media (max-width: 768px) {
      .WeAreEco_Landing__3WtSs h2 {
        margin-top: 0;
        font-size: 20px; } }
  .WeAreEco_Landing__3WtSs h3 {
    font-family: Blogger-sans; }
  .WeAreEco_Landing__3WtSs li {
    font-family: Blogger-sans; }
  .WeAreEco_Landing__3WtSs a {
    font-family: Blogger-sans; }
  .WeAreEco_Landing__3WtSs p {
    font-size: 20px;
    font-family: Blogger-sans;
    text-align: justify; }
  .WeAreEco_Landing__3WtSs .WeAreEco_historyContainer__4LGmU {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 2rem;
    padding-top: 8rem;
    background: linear-gradient(to bottom, #06beb6, #74a7b2);
    margin-bottom: 5rem; }
    @media (max-width: 480px) {
      .WeAreEco_Landing__3WtSs .WeAreEco_historyContainer__4LGmU {
        padding: 0;
        padding-top: 3rem;
        margin-bottom: 3rem;
        flex-direction: column; } }
    .WeAreEco_Landing__3WtSs .WeAreEco_historyContainer__4LGmU .WeAreEco_historyContainerContent__3MNyx {
      display: flex;
      flex-direction: column;
      align-items: center; }
      .WeAreEco_Landing__3WtSs .WeAreEco_historyContainer__4LGmU .WeAreEco_historyContainerContent__3MNyx h1, .WeAreEco_Landing__3WtSs .WeAreEco_historyContainer__4LGmU .WeAreEco_historyContainerContent__3MNyx h3, .WeAreEco_Landing__3WtSs .WeAreEco_historyContainer__4LGmU .WeAreEco_historyContainerContent__3MNyx p {
        color: #fff; }
      @media (max-width: 768px) {
        .WeAreEco_Landing__3WtSs .WeAreEco_historyContainer__4LGmU .WeAreEco_historyContainerContent__3MNyx {
          padding-right: 2rem;
          padding-left: 2rem; } }
    .WeAreEco_Landing__3WtSs .WeAreEco_historyContainer__4LGmU .WeAreEco_historyImageContainer__r_RXr {
      margin-top: 2rem;
      display: flex;
      justify-content: flex-start; }
      .WeAreEco_Landing__3WtSs .WeAreEco_historyContainer__4LGmU .WeAreEco_historyImageContainer__r_RXr img {
        height: auto;
        width: 100%; }
        @media (min-width: 768px) {
          .WeAreEco_Landing__3WtSs .WeAreEco_historyContainer__4LGmU .WeAreEco_historyImageContainer__r_RXr img {
            width: 75%;
            border-radius: 25px; } }
  .WeAreEco_Landing__3WtSs .WeAreEco_enviromentContainer__I2AQB {
    display: flex;
    justify-content: center;
    background-color: #fff8e3; }
    @media (max-width: 480px) {
      .WeAreEco_Landing__3WtSs .WeAreEco_enviromentContainer__I2AQB {
        padding-top: 40px; } }
    .WeAreEco_Landing__3WtSs .WeAreEco_enviromentContainer__I2AQB .WeAreEco_enviromentContainerContent__3wQnC {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center; }
      @media (max-width: 480px) {
        .WeAreEco_Landing__3WtSs .WeAreEco_enviromentContainer__I2AQB .WeAreEco_enviromentContainerContent__3wQnC {
          flex-direction: column-reverse; } }
    .WeAreEco_Landing__3WtSs .WeAreEco_enviromentContainer__I2AQB .WeAreEco_enviromentTextContainer__34apS {
      max-width: 65%;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-right: 20px;
      align-items: center; }
      @media (max-width: 480px) {
        .WeAreEco_Landing__3WtSs .WeAreEco_enviromentContainer__I2AQB .WeAreEco_enviromentTextContainer__34apS {
          max-width: 100%; } }
      .WeAreEco_Landing__3WtSs .WeAreEco_enviromentContainer__I2AQB .WeAreEco_enviromentTextContainer__34apS h1 {
        color: #50a7b4; }
      @media (min-width: 1024px) {
        .WeAreEco_Landing__3WtSs .WeAreEco_enviromentContainer__I2AQB .WeAreEco_enviromentTextContainer__34apS p {
          max-width: 70%; } }
    .WeAreEco_Landing__3WtSs .WeAreEco_enviromentContainer__I2AQB .WeAreEco_enviromentImageContainer__2k7p0 {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      @media (min-width: 768px) {
        .WeAreEco_Landing__3WtSs .WeAreEco_enviromentContainer__I2AQB .WeAreEco_enviromentImageContainer__2k7p0 {
          margin-left: 15px; } }
      .WeAreEco_Landing__3WtSs .WeAreEco_enviromentContainer__I2AQB .WeAreEco_enviromentImageContainer__2k7p0 img {
        width: 100%;
        height: auto; }
        @media (min-width: 768px) {
          .WeAreEco_Landing__3WtSs .WeAreEco_enviromentContainer__I2AQB .WeAreEco_enviromentImageContainer__2k7p0 img {
            border-radius: 25px;
            margin-top: 3em;
            margin-bottom: 3em; } }
  .WeAreEco_Landing__3WtSs .WeAreEco_sampleContainer__3WT0O {
    display: flex;
    justify-content: space-around;
    padding: 0 10px 40px 10px;
    width: 100%;
    margin-top: 0.25rem;
    flex-direction: row; }
    @media (max-width: 480px) {
      .WeAreEco_Landing__3WtSs .WeAreEco_sampleContainer__3WT0O {
        flex-direction: column; } }
    .WeAreEco_Landing__3WtSs .WeAreEco_sampleContainer__3WT0O .WeAreEco_contentMock__3OU03 {
      display: flex;
      justify-content: center;
      position: relative; }
      .WeAreEco_Landing__3WtSs .WeAreEco_sampleContainer__3WT0O .WeAreEco_contentMock__3OU03 .WeAreEco_sampleImg__3tCZ6 {
        height: 350px;
        margin-right: -35px;
        align-self: flex-end;
        padding-right: 1em; }
        @media (min-width: 768px) {
          .WeAreEco_Landing__3WtSs .WeAreEco_sampleContainer__3WT0O .WeAreEco_contentMock__3OU03 .WeAreEco_sampleImg__3tCZ6 {
            height: 400px;
            margin-right: 20px;
            padding-right: 0.5em; } }
        @media (min-width: 1024px) {
          .WeAreEco_Landing__3WtSs .WeAreEco_sampleContainer__3WT0O .WeAreEco_contentMock__3OU03 .WeAreEco_sampleImg__3tCZ6 {
            height: 500px;
            padding-right: 4em; } }
      .WeAreEco_Landing__3WtSs .WeAreEco_sampleContainer__3WT0O .WeAreEco_contentMock__3OU03 .WeAreEco_mockStepsView__2J346 {
        height: 325px;
        margin-right: -35px;
        z-index: 30;
        align-self: flex-end; }
        @media (min-width: 768px) {
          .WeAreEco_Landing__3WtSs .WeAreEco_sampleContainer__3WT0O .WeAreEco_contentMock__3OU03 .WeAreEco_mockStepsView__2J346 {
            height: 350px;
            margin-right: -2em; } }
        @media (min-width: 1024px) {
          .WeAreEco_Landing__3WtSs .WeAreEco_sampleContainer__3WT0O .WeAreEco_contentMock__3OU03 .WeAreEco_mockStepsView__2J346 {
            height: 450px;
            margin-right: -2.50em; } }
      .WeAreEco_Landing__3WtSs .WeAreEco_sampleContainer__3WT0O .WeAreEco_contentMock__3OU03 .WeAreEco_mockContainerImg__3pCbc {
        position: absolute;
        width: 250px;
        bottom: -8%;
        left: -20%;
        z-index: 50; }
        @media (max-width: 768px) {
          .WeAreEco_Landing__3WtSs .WeAreEco_sampleContainer__3WT0O .WeAreEco_contentMock__3OU03 .WeAreEco_mockContainerImg__3pCbc {
            width: 160px;
            bottom: -6%;
            left: -17%; } }
        @media (max-width: 480px) {
          .WeAreEco_Landing__3WtSs .WeAreEco_sampleContainer__3WT0O .WeAreEco_contentMock__3OU03 .WeAreEco_mockContainerImg__3pCbc {
            left: 0; } }
    .WeAreEco_Landing__3WtSs .WeAreEco_sampleContainer__3WT0O .WeAreEco_contentList__2hVTW {
      margin-right: 40px; }
      @media (max-width: 480px) {
        .WeAreEco_Landing__3WtSs .WeAreEco_sampleContainer__3WT0O .WeAreEco_contentList__2hVTW {
          margin-right: 20px;
          margin-left: 20px; } }
      .WeAreEco_Landing__3WtSs .WeAreEco_sampleContainer__3WT0O .WeAreEco_contentList__2hVTW h1 {
        color: #50a7b4; }
        @media (min-width: 768px) {
          .WeAreEco_Landing__3WtSs .WeAreEco_sampleContainer__3WT0O .WeAreEco_contentList__2hVTW h1 {
            padding-left: 66px; } }
      .WeAreEco_Landing__3WtSs .WeAreEco_sampleContainer__3WT0O .WeAreEco_contentList__2hVTW .WeAreEco_content__4ZecU {
        display: flex;
        justify-content: space-around; }
      .WeAreEco_Landing__3WtSs .WeAreEco_sampleContainer__3WT0O .WeAreEco_contentList__2hVTW .WeAreEco_sampleList__p6qNb {
        list-style-type: none;
        text-align: left;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-weight: bold; }
        @media (max-width: 768px) {
          .WeAreEco_Landing__3WtSs .WeAreEco_sampleContainer__3WT0O .WeAreEco_contentList__2hVTW .WeAreEco_sampleList__p6qNb {
            font-size: 17px;
            padding-left: 0; } }
        .WeAreEco_Landing__3WtSs .WeAreEco_sampleContainer__3WT0O .WeAreEco_contentList__2hVTW .WeAreEco_sampleList__p6qNb li {
          display: flex;
          align-items: center; }
  .WeAreEco_Landing__3WtSs .WeAreEco_rateUsContainer__3SnHc {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #50a7b4;
    padding-top: 1rem;
    padding-bottom: 5rem;
    margin-bottom: -2px; }
    @media (min-width: 1024px) {
      .WeAreEco_Landing__3WtSs .WeAreEco_rateUsContainer__3SnHc {
        padding-top: 3rem;
        padding-bottom: 4rem; } }
  .WeAreEco_Landing__3WtSs .WeAreEco_rateUsCopy__BrPv4 {
    margin: 2rem; }
  .WeAreEco_Landing__3WtSs .WeAreEco_rateIconItem__3n8cI {
    display: flex;
    justify-content: center; }
    .WeAreEco_Landing__3WtSs .WeAreEco_rateIconItem__3n8cI .WeAreEco_rateIconButton__tZzC_ {
      background-color: rgba(255, 255, 255, 0.89); }
    .WeAreEco_Landing__3WtSs .WeAreEco_rateIconItem__3n8cI .WeAreEco_rateIcon__3QQ4Q {
      width: 3.5rem; }
  .WeAreEco_Landing__3WtSs .WeAreEco_reviewsContainer__1r21U {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: #50a7b4;
    padding-top: 4rem;
    margin-bottom: -2px; }
  .WeAreEco_Landing__3WtSs .WeAreEco_reviewGridItem__Yc47G {
    margin: 1rem; }
    .WeAreEco_Landing__3WtSs .WeAreEco_reviewGridItem__Yc47G button {
      font-size: 30px;
      background-color: transparent;
      color: #fff8e3;
      border: none;
      padding: 10px 20px;
      border-radius: 6px;
      cursor: pointer; }
    .WeAreEco_Landing__3WtSs .WeAreEco_reviewGridItem__Yc47G .WeAreEco_reviewContainerContent__Frmxv {
      display: flex;
      align-items: center;
      flex-direction: column; }
      .WeAreEco_Landing__3WtSs .WeAreEco_reviewGridItem__Yc47G .WeAreEco_reviewContainerContent__Frmxv .WeAreEco_reviewHead__3XDM8 {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #ffffff; }
      .WeAreEco_Landing__3WtSs .WeAreEco_reviewGridItem__Yc47G .WeAreEco_reviewContainerContent__Frmxv .WeAreEco_reviewLogo__Oi8_E {
        width: 150px;
        height: 150px;
        object-fit: cover;
        background-color: #ffffff;
        border-radius: 50%; }
        @media (max-width: 768px) {
          .WeAreEco_Landing__3WtSs .WeAreEco_reviewGridItem__Yc47G .WeAreEco_reviewContainerContent__Frmxv .WeAreEco_reviewLogo__Oi8_E {
            width: 80px;
            height: 80px; } }
      .WeAreEco_Landing__3WtSs .WeAreEco_reviewGridItem__Yc47G .WeAreEco_reviewContainerContent__Frmxv .WeAreEco_reviewDataContainer__aSVYO {
        display: flex;
        color: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: start; }
        @media (max-width: 768px) {
          .WeAreEco_Landing__3WtSs .WeAreEco_reviewGridItem__Yc47G .WeAreEco_reviewContainerContent__Frmxv .WeAreEco_reviewDataContainer__aSVYO {
            max-width: 500px;
            margin-top: 20px;
            text-align: justify; } }
        .WeAreEco_Landing__3WtSs .WeAreEco_reviewGridItem__Yc47G .WeAreEco_reviewContainerContent__Frmxv .WeAreEco_reviewDataContainer__aSVYO .WeAreEco_reviewTitle__2imx5 {
          margin-top: 1rem;
          color: #ffffff;
          text-align: center; }
        .WeAreEco_Landing__3WtSs .WeAreEco_reviewGridItem__Yc47G .WeAreEco_reviewContainerContent__Frmxv .WeAreEco_reviewDataContainer__aSVYO .WeAreEco_starsContainer__25TEO {
          display: flex;
          align-items: center; }
        .WeAreEco_Landing__3WtSs .WeAreEco_reviewGridItem__Yc47G .WeAreEco_reviewContainerContent__Frmxv .WeAreEco_reviewDataContainer__aSVYO a {
          text-decoration: underline;
          color: white;
          margin-bottom: 10px; }
        .WeAreEco_Landing__3WtSs .WeAreEco_reviewGridItem__Yc47G .WeAreEco_reviewContainerContent__Frmxv .WeAreEco_reviewDataContainer__aSVYO p {
          font-weight: bold;
          font-size: 17px;
          margin-bottom: 8px; }
        @media (max-width: 768px) {
          .WeAreEco_Landing__3WtSs .WeAreEco_reviewGridItem__Yc47G .WeAreEco_reviewContainerContent__Frmxv .WeAreEco_reviewDataContainer__aSVYO h6 {
            max-width: 200px;
            text-align: start;
            margin: 10px 0 0; } }
  .WeAreEco_Landing__3WtSs .WeAreEco_newsContainer__3cuQ2 {
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    width: 100%; }
    .WeAreEco_Landing__3WtSs .WeAreEco_newsContainer__3cuQ2 button {
      font-size: 30px;
      background-color: transparent;
      color: #fff8e3;
      border: none;
      padding: 10px 20px;
      border-radius: 6px;
      cursor: pointer; }
    .WeAreEco_Landing__3WtSs .WeAreEco_newsContainer__3cuQ2 .WeAreEco_newsContainerContent__2crjo {
      display: flex;
      align-items: center;
      background-color: #50a7b4;
      padding-bottom: 3rem;
      min-height: 20rem;
      padding-left: 150px;
      padding-right: 150px; }
      @media (max-width: 768px) {
        .WeAreEco_Landing__3WtSs .WeAreEco_newsContainer__3cuQ2 .WeAreEco_newsContainerContent__2crjo {
          padding-right: 10px;
          padding-left: 10px;
          flex-direction: column;
          min-height: 27rem; } }
      .WeAreEco_Landing__3WtSs .WeAreEco_newsContainer__3cuQ2 .WeAreEco_newsContainerContent__2crjo .WeAreEco_newsLogo__3hsNk {
        width: 150px;
        height: 150px;
        object-fit: contain;
        background-color: #ffffff;
        border-radius: 50%; }
        @media (max-width: 768px) {
          .WeAreEco_Landing__3WtSs .WeAreEco_newsContainer__3cuQ2 .WeAreEco_newsContainerContent__2crjo .WeAreEco_newsLogo__3hsNk {
            width: 50px;
            height: 50px;
            position: absolute;
            margin: 15px 0 0 90px; } }
      .WeAreEco_Landing__3WtSs .WeAreEco_newsContainer__3cuQ2 .WeAreEco_newsContainerContent__2crjo .WeAreEco_newsDataContainer__2vx_M {
        padding-left: 50px;
        display: flex;
        color: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        text-align: start; }
        @media (max-width: 768px) {
          .WeAreEco_Landing__3WtSs .WeAreEco_newsContainer__3cuQ2 .WeAreEco_newsContainerContent__2crjo .WeAreEco_newsDataContainer__2vx_M {
            max-width: 500px;
            margin-top: 20px;
            margin-right: 50px;
            text-align: justify; } }
        .WeAreEco_Landing__3WtSs .WeAreEco_newsContainer__3cuQ2 .WeAreEco_newsContainerContent__2crjo .WeAreEco_newsDataContainer__2vx_M a {
          text-decoration: underline;
          color: white;
          margin-bottom: 10px; }
        .WeAreEco_Landing__3WtSs .WeAreEco_newsContainer__3cuQ2 .WeAreEco_newsContainerContent__2crjo .WeAreEco_newsDataContainer__2vx_M p {
          font-weight: bold;
          font-size: 17px;
          margin-bottom: 8px;
          margin-top: 30px; }
        @media (max-width: 768px) {
          .WeAreEco_Landing__3WtSs .WeAreEco_newsContainer__3cuQ2 .WeAreEco_newsContainerContent__2crjo .WeAreEco_newsDataContainer__2vx_M h2 {
            max-width: 200px;
            text-align: start;
            margin: 10px 0 0; } }
  .WeAreEco_Landing__3WtSs .WeAreEco_footerContainer__18LzP {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    @media (max-width: 768px) {
      .WeAreEco_Landing__3WtSs .WeAreEco_footerContainer__18LzP {
        align-items: center; } }
    .WeAreEco_Landing__3WtSs .WeAreEco_footerContainer__18LzP .WeAreEco_sevi__3hOa3 {
      cursor: pointer;
      align-self: center;
      color: #716b77;
      margin-top: 20px;
      text-align: center; }
    .WeAreEco_Landing__3WtSs .WeAreEco_footerContainer__18LzP .WeAreEco_codeModal__2UOPr {
      cursor: pointer;
      align-self: center;
      margin: 10px;
      text-align: center;
      text-decoration: underline; }
    .WeAreEco_Landing__3WtSs .WeAreEco_footerContainer__18LzP .WeAreEco_footerContent__3vQZ5 {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center; }
      @media (max-width: 768px) {
        .WeAreEco_Landing__3WtSs .WeAreEco_footerContainer__18LzP .WeAreEco_footerContent__3vQZ5 {
          flex-direction: column; } }
      .WeAreEco_Landing__3WtSs .WeAreEco_footerContainer__18LzP .WeAreEco_footerContent__3vQZ5 .WeAreEco_footerLawsContainer__2YMzQ {
        display: flex;
        margin: 0.5rem; }
        .WeAreEco_Landing__3WtSs .WeAreEco_footerContainer__18LzP .WeAreEco_footerContent__3vQZ5 .WeAreEco_footerLawsContainer__2YMzQ p {
          margin: 0.25rem;
          font-size: 15px;
          color: #716b77; }
      .WeAreEco_Landing__3WtSs .WeAreEco_footerContainer__18LzP .WeAreEco_footerContent__3vQZ5 .WeAreEco_seviLogo__2mHrv {
        width: 150px; }
      .WeAreEco_Landing__3WtSs .WeAreEco_footerContainer__18LzP .WeAreEco_footerContent__3vQZ5 .WeAreEco_footerLogosContainer__2Wdrc {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 25rem;
        margin-top: 2rem; }
      .WeAreEco_Landing__3WtSs .WeAreEco_footerContainer__18LzP .WeAreEco_footerContent__3vQZ5 .WeAreEco_footerContentServices__3Kd-k {
        display: flex;
        flex-direction: column; }
        .WeAreEco_Landing__3WtSs .WeAreEco_footerContainer__18LzP .WeAreEco_footerContent__3vQZ5 .WeAreEco_footerContentServices__3Kd-k div {
          max-width: 400px; }

.WeAreEco_modalInfoHeader__wmuCg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px; }

.WeAreEco_modalInfoContent__3H65P {
  display: flex;
  align-items: center;
  justify-content: space-around; }
  @media (max-width: 768px) {
    .WeAreEco_modalInfoContent__3H65P {
      flex-direction: column; } }
  .WeAreEco_modalInfoContent__3H65P li {
    font-size: 20px;
    margin-top: 10px; }
  .WeAreEco_modalInfoContent__3H65P .WeAreEco_imageHand__2cVnS {
    width: 15rem;
    height: 15rem; }
  .WeAreEco_modalInfoContent__3H65P .WeAreEco_imageKid__2hsRP {
    width: 11rem;
    height: 22rem; }

.WeAreEco_seeMoreText__3YLfr {
  text-decoration: underline; }

.WeAreEco_cookiesLayer__dTwBE {
  width: 100%;
  height: 100%;
  background: rgba(128, 128, 128, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 6000; }
  .WeAreEco_cookiesLayer__dTwBE .WeAreEco_snackbar__bKiii {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 6rem;
    width: 100%;
    height: 22rem;
    font-size: 1.2rem;
    text-align: center; }
    @media (max-width: 1024px) {
      .WeAreEco_cookiesLayer__dTwBE .WeAreEco_snackbar__bKiii {
        width: 75%;
        padding: 2rem; } }
    .WeAreEco_cookiesLayer__dTwBE .WeAreEco_snackbar__bKiii .WeAreEco_moreInfoCookieText__3oraP {
      color: white;
      margin: 5px; }
    .WeAreEco_cookiesLayer__dTwBE .WeAreEco_snackbar__bKiii button {
      background-color: transparent;
      color: white;
      border: none; }

.WeAreEco_finalImgContainer__GcTqh {
  position: relative;
  margin: 0; }
  .WeAreEco_finalImgContainer__GcTqh .WeAreEco_asterisco__1kr5G {
    position: absolute;
    bottom: 2px;
    font-size: 15px !important;
    color: #efe9d6;
    right: 20%; }
    @media (max-width: 768px) {
      .WeAreEco_finalImgContainer__GcTqh .WeAreEco_asterisco__1kr5G {
        right: 41%; } }

.WeAreEco_cookiesMoreInfoButton__3jkPp {
  background-color: #50a7b4 !important; }

.WeAreEco_cookiesMoreInfoButton__3jkPp:hover {
  background-color: #448086 !important; }

.WeAreEco_privacy__1eP6Y {
  cursor: pointer; }

.WeAreEco_privacy__1eP6Y:hover {
  text-decoration: underline; }

.WeAreEco_shareButtonsBox__2HznX {
  padding: 2em;
  padding-bottom: 1em !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly; }

.WeAreEco_shareButton__2iV9f:hover {
  cursor: pointer; }

.WeAreEco_floatingButtonGetIt__1dZr3, .WeAreEco_floatingButtonGetItLogged__9MCzT {
  position: fixed !important;
  bottom: 1em !important;
  right: 1em !important;
  box-shadow: 3px 7px 20px 0px black;
  color: #448086 !important;
  background-color: #fff8e3 !important;
  z-index: 10; }
  @media (min-width: 768px) {
    .WeAreEco_floatingButtonGetIt__1dZr3, .WeAreEco_floatingButtonGetItLogged__9MCzT {
      display: none !important; } }

.WeAreEco_floatingButtonGetItLogged__9MCzT {
  bottom: 5em !important; }

.WeAreEco_floatingButtonGetItIcon__1BfTa {
  margin-right: 0.3em; }

.WeAreEco_companiesTrustContainer__1KSsD {
  margin-top: 3em !important;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 768px) {
    .WeAreEco_companiesTrustContainer__1KSsD {
      padding-bottom: 5em;
      flex-direction: column; } }
  .WeAreEco_companiesTrustContainer__1KSsD .WeAreEco_companiesTrustContent__16i5X {
    display: flex;
    align-items: center; }
  .WeAreEco_companiesTrustContainer__1KSsD .WeAreEco_companiesTrustLogoEnisa__1mKL8 {
    width: 150px;
    margin-right: 1.25rem;
    height: auto; }
    @media (max-width: 480px) {
      .WeAreEco_companiesTrustContainer__1KSsD .WeAreEco_companiesTrustLogoEnisa__1mKL8 {
        max-height: 8rem;
        margin-right: 0.5rem;
        width: 75px; } }
  .WeAreEco_companiesTrustContainer__1KSsD .WeAreEco_companiesTrustLogo__3jsAP {
    width: 190px;
    height: auto; }
    @media (max-width: 480px) {
      .WeAreEco_companiesTrustContainer__1KSsD .WeAreEco_companiesTrustLogo__3jsAP {
        max-height: 8rem;
        width: 135px; } }

.WeAreEco_companiesTrustClaim__2Qs3- {
  color: #ffffff !important;
  font-size: 80px !important;
  text-align: center !important;
  padding-bottom: 6rem !important;
  margin-top: 1rem !important;
  min-width: 75%;
  max-width: 90%;
  border-bottom: 0.25rem solid #fff; }
  @media (max-width: 480px) {
    .WeAreEco_companiesTrustClaim__2Qs3- {
      min-width: 90% !important;
      max-width: 90% !important;
      font-size: 30px !important;
      padding-bottom: 2.25rem !important;
      margin-bottom: 1rem !important; } }

.WeAreEco_floatingActiveBraceletButton__1GmdN {
  position: fixed !important;
  border-radius: 25px !important;
  background-color: #fff8e3 !important;
  color: #448086 !important;
  bottom: 1em;
  right: 1em;
  box-shadow: 3px 7px 20px 0px black; }

.WeAreEco_activeIcon__2gIx8 {
  color: #448086 !important;
  margin-right: 0.5em !important; }

.WeAreEco_FbLikeButtonContainer__3MQoc {
  padding-top: 1em; }
  @media (min-width: 768px) {
    .WeAreEco_FbLikeButtonContainer__3MQoc {
      padding-top: 3em; } }

.WeAreEco_facebookLikeButton__3Km7B {
  display: flex !important;
  align-items: center !important;
  margin-top: 2em !important;
  padding-right: 1.5em !important;
  font-size: 11px !important;
  background-color: #3C5A99 !important;
  border-radius: 6px; }
  @media (max-width: 768px) {
    .WeAreEco_facebookLikeButton__3Km7B {
      display: none !important; } }
  @media (min-width: 768px) {
    .WeAreEco_facebookLikeButton__3Km7B {
      font-size: 13px !important;
      padding-right: 1.7em !important; } }

.WeAreEco_facebookLike__3LTJq {
  height: 30px;
  width: 30px; }
  @media (min-width: 768px) {
    .WeAreEco_facebookLike__3LTJq {
      height: 45px;
      width: 45px; } }

.WeAreEco_pulsidHeadImage__1UoTe {
  display: none;
  height: 100px;
  width: 135px; }
  @media (max-width: 768px) {
    .WeAreEco_pulsidHeadImage__1UoTe {
      display: block; } }

.WeAreEco_pulsidPriceBadgeDesktop__2WxAD {
  min-width: 4rem;
  min-height: 4rem;
  background-color: #c3dd36;
  font-size: large !important;
  font-weight: bold; }
  @media (max-width: 768px) {
    .WeAreEco_pulsidPriceBadgeDesktop__2WxAD {
      display: none !important; } }

.WeAreEco_pulsidPriceBadgeAlways__O2rub {
  min-width: 4rem;
  min-height: 4rem;
  background-color: #c3dd36;
  font-size: large !important;
  font-weight: bold; }

.WeAreEco_pulsidPriceBadge__33PHI {
  display: none !important; }
  @media (max-width: 768px) {
    .WeAreEco_pulsidPriceBadge__33PHI {
      display: block !important; } }
  .WeAreEco_pulsidPriceBadge__33PHI span:last-child {
    min-width: 3rem;
    background-color: #c3dd36 !important;
    font-size: medium;
    font-weight: bold;
    min-height: 3rem; }

.WeAreEco_discountSticker__3JVw5 {
  position: absolute;
  right: -25px;
  top: -25px;
  border-radius: 50%;
  font-size: 15px;
  padding: 1.3em 1em;
  background-color: #ff5a5a;
  color: #ffffff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12); }

.WeAreEco_offerSnackbar__3QC8v {
  width: 100%;
  max-width: 100% !important;
  text-align: center;
  font-size: 17px !important; }
  .WeAreEco_offerSnackbar__3QC8v div:first-child {
    width: 100%;
    max-width: 100% !important;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    font-size: 17px !important; }

.WeAreEco_snackOfferButton__1izVh {
  background-color: #448086;
  color: #fff8e3; }

.WeAreEco_offerBanner__2fk9G {
  position: fixed;
  display: flex;
  z-index: 55;
  bottom: 0;
  width: 100%;
  padding: 1em;
  text-align: center;
  font-size: 20px;
  background-color: #ff0012;
  color: white; }
  @media (max-width: 768px) {
    .WeAreEco_offerBanner__2fk9G {
      flex-direction: column;
      display: none; } }

.WeAreEco_offerBannerMobile__34wQt {
  position: fixed;
  display: none;
  z-index: 55;
  bottom: 0;
  width: 100%;
  padding: 1em;
  text-align: center;
  font-size: 20px;
  background-color: #FF0012;
  color: white; }
  @media (max-width: 768px) {
    .WeAreEco_offerBannerMobile__34wQt {
      display: flex;
      justify-content: space-around; } }

.WeAreEco_closeOfferBanner__2IH7m {
  position: absolute;
  right: 25px;
  top: 8px; }
  @media (max-width: 768px) {
    .WeAreEco_closeOfferBanner__2IH7m {
      position: absolute;
      right: 5px;
      top: 5px; } }

.WeAreEco_offerBannerCountdown__T9SMa {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 768px) {
    .WeAreEco_offerBannerCountdown__T9SMa {
      margin-top: 1.4em !important; } }

@media (max-width: 768px) {
  .WeAreEco_offerBannerCountdown__T9SMa, .WeAreEco_offerClaim__2T34t {
    display: flex;
    flex-direction: column; } }

.WeAreEco_offerClaim__2T34t span:first-child {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    .WeAreEco_offerClaim__2T34t span:first-child {
      display: inline-flex !important; } }

.WeAreEco_offerButtonBox__3j6ZB {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 768px) {
    .WeAreEco_offerButtonBox__3j6ZB {
      margin-top: 1em !important; } }

.WeAreEco_offerBannerGetItButton__2xxkC {
  background-color: #00B32C !important;
  color: #ffffff !important; }

.WeAreEco_closeOfferBannerIcon__3HYIj {
  color: #ffffff; }

.WeAreEco_whatsappPostSection__3CL7g {
  display: flex;
  justify-content: space-around;
  background-color: #74a7b1;
  width: 100%;
  padding-top: 2rem;
  margin-bottom: -2px;
  color: #fff; }

.WeAreEco_whatsappPostClaim__wPC9W {
  text-align: center !important; }

.WeAreEco_whatsappPostItem__2EaCk {
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 2rem !important; }
  .WeAreEco_whatsappPostItem__2EaCk .WeAreEco_downloadDocumentButton__2qDBl {
    background-color: #fff8e3; }

.WeAreEco_whatsappPostImage__e0HLs {
  height: 250px; }

.WeAreEco_newsletterSectionGrid__1SH3b {
  padding-top: 0;
  padding-bottom: 2rem;
  margin-top: -4px;
  margin-bottom: -4px;
  background-color: #ffffff; }
  @media (min-width: 768px) {
    .WeAreEco_newsletterSectionGrid__1SH3b {
      background-color: #4FA9B3;
      padding-top: 2rem;
      padding-bottom: 2rem; } }

.WeAreEco_freeShippingClaim__qDwtt {
  color: #ffffff;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  font-family: 'Roboto Bold', sans-serif;
  padding-left: 0.4rem;
  padding-right: 1.2rem; }
  .WeAreEco_freeShippingClaim__qDwtt .WeAreEco_countDown__2IiB4 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    font-family: 'Roboto Bold', sans-serif !important;
    font-size: 17px; }
    @media (min-width: 768px) {
      .WeAreEco_freeShippingClaim__qDwtt .WeAreEco_countDown__2IiB4 {
        margin-left: 1rem;
        margin-right: 1rem;
        font-size: 30px; } }
  .WeAreEco_freeShippingClaim__qDwtt span {
    font-family: 'Roboto Bold', sans-serif !important;
    font-size: 20px; }
    @media (min-width: 768px) {
      .WeAreEco_freeShippingClaim__qDwtt span {
        font-size: 30px; } }

.WeAreEco_CTAContainer__2-swQ {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 4rem; }
  .WeAreEco_CTAContainer__2-swQ .WeAreEco_tapIcon__1NLdV {
    margin-left: 0.5rem;
    font-size: 2rem;
    transform: rotate(-25deg); }
  .WeAreEco_CTAContainer__2-swQ .WeAreEco_primaryCTAButton__1HK3R {
    color: #ffffff;
    background: #FF6966;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    padding: 1em 2.5em;
    border-radius: 10px;
    cursor: pointer;
    font-size: 30px; }
    @media (max-width: 480px) {
      .WeAreEco_CTAContainer__2-swQ .WeAreEco_primaryCTAButton__1HK3R {
        font-size: 20px; } }
  .WeAreEco_CTAContainer__2-swQ .WeAreEco_primaryCTAButton__1HK3R:hover {
    background-color: #ea605d; }
  .WeAreEco_CTAContainer__2-swQ .WeAreEco_secondaryCTAButton__3WjAQ {
    color: #50a7b4 !important;
    border: 2px solid !important;
    padding: 1em 2.5em !important;
    border-radius: 10px !important;
    cursor: pointer !important;
    font-size: 30px !important; }
    @media (max-width: 480px) {
      .WeAreEco_CTAContainer__2-swQ .WeAreEco_secondaryCTAButton__3WjAQ {
        font-size: 20px !important; } }

.WeAreEco_buyedClaimContainer__30GbY {
  display: flex;
  justify-content: center;
  margin-bottom: 0.25rem !important; }
  @media (min-width: 1024px) {
    .WeAreEco_buyedClaimContainer__30GbY {
      margin-bottom: 1.25rem !important; } }

.WeAreEco_buyedClaimBox__nfIcP, .WeAreEco_buyNowClaimBox__-ofei {
  width: 100%;
  height: 2.5rem;
  color: #000;
  background-color: #ffffff;
  font-size: 17px;
  text-align: center;
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: 5rem; }
  @media (min-width: 768px) {
    .WeAreEco_buyedClaimBox__nfIcP, .WeAreEco_buyNowClaimBox__-ofei {
      font-size: 20px;
      margin-right: -1rem;
      margin-left: -1rem; } }

.WeAreEco_buyNowClaimBox__-ofei {
  background-color: #FF6966; }

.WeAreEco_countUp__1LMKM {
  margin-top: 3rem !important;
  color: #fff;
  font-size: 70px !important;
  text-align: center; }
  @media (min-width: 768px) {
    .WeAreEco_countUp__1LMKM {
      margin-top: 6rem !important; } }

.WeAreEco_footerVideo__2A9xi {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 3rem; }
  @media (min-width: 1024px) {
    .WeAreEco_footerVideo__2A9xi {
      width: 50%;
      box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.13); } }

.WeAreEco_navidadCollage__2HCZq {
  width: 11rem;
  margin-top: 2rem; }
  @media (min-width: 1024px) {
    .WeAreEco_navidadCollage__2HCZq {
      margin-top: 6rem;
      width: 14rem; } }

/*font-family*/
/*font-size*/
/*font-weight*/
@-webkit-keyframes OldLanding_floatBracelet__36FC6 {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }
@keyframes OldLanding_floatBracelet__36FC6 {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@-webkit-keyframes OldLanding_floatVerticalBracelet__3Mabj {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes OldLanding_floatVerticalBracelet__3Mabj {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@-webkit-keyframes OldLanding_backgroundGradient__24iF3 {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes OldLanding_backgroundGradient__24iF3 {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@-webkit-keyframes OldLanding_backgroundGradient2__Iz2s9 {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

@keyframes OldLanding_backgroundGradient2__Iz2s9 {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

.OldLanding_Landing__8J6fE {
  display: flex;
  flex-direction: column;
  text-align: justify;
  align-items: center;
  overflow-x: hidden;
  font-family: Aristotelica;
  background-color: #fff8e3; }
  .OldLanding_Landing__8J6fE h1 {
    color: white;
    text-align: center;
    font-size: 30px; }
  .OldLanding_Landing__8J6fE h2 {
    color: white;
    text-align: center;
    font-size: 30px;
    margin-top: -30px; }
    @media (max-width: 768px) {
      .OldLanding_Landing__8J6fE h2 {
        margin-top: 0;
        font-size: 20px; } }
  .OldLanding_Landing__8J6fE h3 {
    font-family: Blogger-sans; }
  .OldLanding_Landing__8J6fE li {
    font-family: Blogger-sans; }
  .OldLanding_Landing__8J6fE a {
    font-family: Blogger-sans; }
  .OldLanding_Landing__8J6fE p {
    font-size: 20px;
    font-family: Blogger-sans;
    text-align: justify; }
  .OldLanding_Landing__8J6fE .OldLanding_headContainer__3kssV {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap !important;
    width: 100%;
    min-height: 100vh;
    padding-top: 1em;
    align-items: center;
    justify-content: center;
    background: url(/static/media/bg-masthead-blur2.5bb4617d.png) no-repeat center;
    background-size: cover; }
    @media (max-width: 768px) {
      .OldLanding_Landing__8J6fE .OldLanding_headContainer__3kssV {
        background: url(/static/media/bg-masthead-blur.06a6ea49.png) no-repeat center;
        background-size: cover; } }
    @media (max-width: 360px) {
      .OldLanding_Landing__8J6fE .OldLanding_headContainer__3kssV {
        padding-top: 3em !important;
        padding-bottom: 3em !important; } }
    .OldLanding_Landing__8J6fE .OldLanding_headContainer__3kssV h1 {
      font-size: 80px; }
      @media (max-width: 768px) {
        .OldLanding_Landing__8J6fE .OldLanding_headContainer__3kssV h1 {
          font-size: 40px; } }
    .OldLanding_Landing__8J6fE .OldLanding_headContainer__3kssV .OldLanding_primaryCTAButton__3TgoL {
      color: #fff8e3;
      border: 2px solid;
      padding: 1em 2.5em;
      border-radius: 10px;
      cursor: pointer;
      font-size: 30px; }
      @media (max-width: 480px) {
        .OldLanding_Landing__8J6fE .OldLanding_headContainer__3kssV .OldLanding_primaryCTAButton__3TgoL {
          font-size: 20px; } }
    @media (min-width: 1024px) {
      .OldLanding_Landing__8J6fE .OldLanding_headContainer__3kssV .OldLanding_headContainerImg__5GsiA {
        margin-top: -200px; } }
    .OldLanding_Landing__8J6fE .OldLanding_headContainer__3kssV .OldLanding_headContainerContent__3gcHa {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 80%;
      padding-top: 1em; }
      .OldLanding_Landing__8J6fE .OldLanding_headContainer__3kssV .OldLanding_headContainerContent__3gcHa .OldLanding_buy24Container__167Hx {
        background-color: #fff8e3;
        color: #50a7b4;
        font-size: 17px;
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        margin-top: 4rem;
        border-radius: 4px; }
        @media (max-width: 480px) {
          .OldLanding_Landing__8J6fE .OldLanding_headContainer__3kssV .OldLanding_headContainerContent__3gcHa .OldLanding_buy24Container__167Hx {
            margin-top: 2rem;
            width: 100%; } }
  .OldLanding_Landing__8J6fE .OldLanding_weHelpYouSection__1coH- {
    display: flex;
    justify-content: space-around;
    background-color: #74a7b1;
    width: 100%;
    margin-bottom: -2px; }
    .OldLanding_Landing__8J6fE .OldLanding_weHelpYouSection__1coH- p {
      text-align: center;
      color: #ffffff; }
    .OldLanding_Landing__8J6fE .OldLanding_weHelpYouSection__1coH- .OldLanding_documentsButtonContainer__17M8d {
      margin-top: 1em;
      display: flex !important;
      justify-content: center !important; }
      .OldLanding_Landing__8J6fE .OldLanding_weHelpYouSection__1coH- .OldLanding_documentsButtonContainer__17M8d .OldLanding_downloadDocumentButton__f9qmT {
        background-color: #fff8e3 !important; }
      .OldLanding_Landing__8J6fE .OldLanding_weHelpYouSection__1coH- .OldLanding_documentsButtonContainer__17M8d .OldLanding_documentsDownloadIcon__3GcZU {
        margin-right: 0.2em; }
  .OldLanding_Landing__8J6fE .OldLanding_peopleContainer__2wOtr {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 0 10px;
    color: #fff8e3;
    position: relative; }
    .OldLanding_Landing__8J6fE .OldLanding_peopleContainer__2wOtr p {
      max-width: 800px; }
      @media (max-width: 768px) {
        .OldLanding_Landing__8J6fE .OldLanding_peopleContainer__2wOtr p {
          max-width: 400px; } }
    .OldLanding_Landing__8J6fE .OldLanding_peopleContainer__2wOtr .OldLanding_fondoOlasImgs__3hvAX {
      position: absolute; }
    .OldLanding_Landing__8J6fE .OldLanding_peopleContainer__2wOtr .OldLanding_peopleContainerContent__wp7mb {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 1rem;
      max-width: 90vw; }
    .OldLanding_Landing__8J6fE .OldLanding_peopleContainer__2wOtr .OldLanding_peopleIconContainer__kvl7B {
      display: flex; }
      .OldLanding_Landing__8J6fE .OldLanding_peopleContainer__2wOtr .OldLanding_peopleIconContainer__kvl7B .OldLanding_peopleIconContent__1b_kV {
        display: flex;
        flex-direction: column;
        align-items: center; }
      @media (max-width: 480px) {
        .OldLanding_Landing__8J6fE .OldLanding_peopleContainer__2wOtr .OldLanding_peopleIconContainer__kvl7B p {
          text-align: center; } }
      .OldLanding_Landing__8J6fE .OldLanding_peopleContainer__2wOtr .OldLanding_peopleIconContainer__kvl7B .OldLanding_peopleIconContent__1b_kV:hover {
        cursor: pointer; }
      .OldLanding_Landing__8J6fE .OldLanding_peopleContainer__2wOtr .OldLanding_peopleIconContainer__kvl7B .OldLanding_peopleIconLeft__2DdKm {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 50px; }
      .OldLanding_Landing__8J6fE .OldLanding_peopleContainer__2wOtr .OldLanding_peopleIconContainer__kvl7B .OldLanding_peopleIconRigth__1Yun4 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; }
  .OldLanding_Landing__8J6fE .OldLanding_whyContainer__2bbCW {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; }
    .OldLanding_Landing__8J6fE .OldLanding_whyContainer__2bbCW h1 {
      color: #50a7b4; }
    .OldLanding_Landing__8J6fE .OldLanding_whyContainer__2bbCW .OldLanding_whyList__2F_A8 {
      list-style-type: none;
      text-align: left;
      font-size: 20px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-weight: bold; }
      @media (max-width: 768px) {
        .OldLanding_Landing__8J6fE .OldLanding_whyContainer__2bbCW .OldLanding_whyList__2F_A8 {
          font-size: 17px;
          padding-left: 0;
          padding-right: 20px;
          margin-left: -20px; } }
      .OldLanding_Landing__8J6fE .OldLanding_whyContainer__2bbCW .OldLanding_whyList__2F_A8 li {
        display: flex;
        align-items: center; }
    .OldLanding_Landing__8J6fE .OldLanding_whyContainer__2bbCW .OldLanding_whyArrowImg__bYHI9 {
      transform: rotate(45deg); }
  .OldLanding_Landing__8J6fE .OldLanding_ownBraceletsContainer__1hUT4 {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%; }
    .OldLanding_Landing__8J6fE .OldLanding_ownBraceletsContainer__1hUT4 .OldLanding_braceletsContainerImg__dPqFe {
      display: flex;
      align-items: center; }
      .OldLanding_Landing__8J6fE .OldLanding_ownBraceletsContainer__1hUT4 .OldLanding_braceletsContainerImg__dPqFe .OldLanding_braceletsContainerImgBracelet__1XeNe {
        -webkit-animation: OldLanding_floatBracelet__36FC6 6s ease-in-out infinite;
                animation: OldLanding_floatBracelet__36FC6 6s ease-in-out infinite;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 250px; }
        @media (max-width: 480px) {
          .OldLanding_Landing__8J6fE .OldLanding_ownBraceletsContainer__1hUT4 .OldLanding_braceletsContainerImg__dPqFe .OldLanding_braceletsContainerImgBracelet__1XeNe {
            margin-bottom: 50px; } }
        .OldLanding_Landing__8J6fE .OldLanding_ownBraceletsContainer__1hUT4 .OldLanding_braceletsContainerImg__dPqFe .OldLanding_braceletsContainerImgBracelet__1XeNe img {
          height: 160px; }
          @media (max-width: 768px) {
            .OldLanding_Landing__8J6fE .OldLanding_ownBraceletsContainer__1hUT4 .OldLanding_braceletsContainerImg__dPqFe .OldLanding_braceletsContainerImgBracelet__1XeNe img {
              width: 250px; } }
        .OldLanding_Landing__8J6fE .OldLanding_ownBraceletsContainer__1hUT4 .OldLanding_braceletsContainerImg__dPqFe .OldLanding_braceletsContainerImgBracelet__1XeNe p {
          color: #448086;
          font-size: 30px; }
      .OldLanding_Landing__8J6fE .OldLanding_ownBraceletsContainer__1hUT4 .OldLanding_braceletsContainerImg__dPqFe .OldLanding_braceletsContainerImgHandQR__1SERv {
        width: 180px;
        transform: rotate(-10deg); }
        @media (max-width: 480px) {
          .OldLanding_Landing__8J6fE .OldLanding_ownBraceletsContainer__1hUT4 .OldLanding_braceletsContainerImg__dPqFe .OldLanding_braceletsContainerImgHandQR__1SERv {
            display: none; } }
    .OldLanding_Landing__8J6fE .OldLanding_ownBraceletsContainer__1hUT4 .OldLanding_braceletsContainerImgArrowLeft__2yZw3 {
      margin-top: -200px;
      margin-left: 70px; }
      @media (max-width: 768px) {
        .OldLanding_Landing__8J6fE .OldLanding_ownBraceletsContainer__1hUT4 .OldLanding_braceletsContainerImgArrowLeft__2yZw3 {
          display: none; } }
    .OldLanding_Landing__8J6fE .OldLanding_ownBraceletsContainer__1hUT4 .OldLanding_braceletsContainerImgArrowMobile__1sf6j {
      margin-top: -90px;
      margin-left: 70px; }
      @media (min-width: 768px) {
        .OldLanding_Landing__8J6fE .OldLanding_ownBraceletsContainer__1hUT4 .OldLanding_braceletsContainerImgArrowMobile__1sf6j {
          display: none; } }
    .OldLanding_Landing__8J6fE .OldLanding_ownBraceletsContainer__1hUT4 .OldLanding_content__1qmkn {
      display: flex;
      justify-content: space-around;
      align-items: center; }
      @media (max-width: 768px) {
        .OldLanding_Landing__8J6fE .OldLanding_ownBraceletsContainer__1hUT4 .OldLanding_content__1qmkn {
          flex-direction: column;
          align-items: center; } }
    .OldLanding_Landing__8J6fE .OldLanding_ownBraceletsContainer__1hUT4 .OldLanding_contentVideo__3FAFm {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px; }
      @media (max-width: 768px) {
        .OldLanding_Landing__8J6fE .OldLanding_ownBraceletsContainer__1hUT4 .OldLanding_contentVideo__3FAFm {
          flex-direction: column-reverse;
          align-items: center; } }
    .OldLanding_Landing__8J6fE .OldLanding_ownBraceletsContainer__1hUT4 .OldLanding_ownBraceletsTextContainer__2wSq1 {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 600px; }
      @media (max-width: 768px) {
        .OldLanding_Landing__8J6fE .OldLanding_ownBraceletsContainer__1hUT4 .OldLanding_ownBraceletsTextContainer__2wSq1 {
          padding: 0 20px; } }
      .OldLanding_Landing__8J6fE .OldLanding_ownBraceletsContainer__1hUT4 .OldLanding_ownBraceletsTextContainer__2wSq1 h1 {
        color: #50a7b4; }
    .OldLanding_Landing__8J6fE .OldLanding_ownBraceletsContainer__1hUT4 .OldLanding_iframeVideo__329ue {
      width: 600px;
      height: 350px; }
      @media (max-width: 480px) {
        .OldLanding_Landing__8J6fE .OldLanding_ownBraceletsContainer__1hUT4 .OldLanding_iframeVideo__329ue {
          width: 85%;
          height: 200px;
          margin-bottom: 20px; } }
  .OldLanding_Landing__8J6fE .OldLanding_historyContainer__1XhYR {
    display: flex;
    background-color: #efe9d6; }
    @media (max-width: 480px) {
      .OldLanding_Landing__8J6fE .OldLanding_historyContainer__1XhYR {
        flex-direction: column; } }
    .OldLanding_Landing__8J6fE .OldLanding_historyContainer__1XhYR .OldLanding_historyContainerContent__W4hdt {
      max-width: 65%;
      padding: 10px 60px;
      display: flex;
      flex-direction: column;
      align-items: center; }
      @media (max-width: 480px) {
        .OldLanding_Landing__8J6fE .OldLanding_historyContainer__1XhYR .OldLanding_historyContainerContent__W4hdt {
          max-width: 100%;
          padding: 0 20px; } }
      .OldLanding_Landing__8J6fE .OldLanding_historyContainer__1XhYR .OldLanding_historyContainerContent__W4hdt h1 {
        color: #50a7b4; }
      @media (min-width: 1024px) {
        .OldLanding_Landing__8J6fE .OldLanding_historyContainer__1XhYR .OldLanding_historyContainerContent__W4hdt p {
          max-width: 65%; } }
    .OldLanding_Landing__8J6fE .OldLanding_historyContainer__1XhYR .OldLanding_historyImageContainer__3NaTo {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      @media (min-width: 768px) {
        .OldLanding_Landing__8J6fE .OldLanding_historyContainer__1XhYR .OldLanding_historyImageContainer__3NaTo {
          margin-right: 15px; } }
      .OldLanding_Landing__8J6fE .OldLanding_historyContainer__1XhYR .OldLanding_historyImageContainer__3NaTo img {
        width: 100%;
        height: auto; }
        @media (min-width: 768px) {
          .OldLanding_Landing__8J6fE .OldLanding_historyContainer__1XhYR .OldLanding_historyImageContainer__3NaTo img {
            margin-top: 3em;
            margin-bottom: 3em;
            border-radius: 25px; } }
  .OldLanding_Landing__8J6fE .OldLanding_enviromentContainer__158nI {
    display: flex;
    justify-content: center;
    background-color: #fff8e3; }
    @media (max-width: 480px) {
      .OldLanding_Landing__8J6fE .OldLanding_enviromentContainer__158nI {
        padding-top: 40px; } }
    .OldLanding_Landing__8J6fE .OldLanding_enviromentContainer__158nI .OldLanding_enviromentContainerContent__2v_KC {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center; }
      @media (max-width: 480px) {
        .OldLanding_Landing__8J6fE .OldLanding_enviromentContainer__158nI .OldLanding_enviromentContainerContent__2v_KC {
          flex-direction: column-reverse; } }
    .OldLanding_Landing__8J6fE .OldLanding_enviromentContainer__158nI .OldLanding_enviromentTextContainer__1NdE3 {
      max-width: 65%;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-right: 20px;
      align-items: center; }
      @media (max-width: 480px) {
        .OldLanding_Landing__8J6fE .OldLanding_enviromentContainer__158nI .OldLanding_enviromentTextContainer__1NdE3 {
          max-width: 100%; } }
      .OldLanding_Landing__8J6fE .OldLanding_enviromentContainer__158nI .OldLanding_enviromentTextContainer__1NdE3 h1 {
        color: #50a7b4; }
      @media (min-width: 1024px) {
        .OldLanding_Landing__8J6fE .OldLanding_enviromentContainer__158nI .OldLanding_enviromentTextContainer__1NdE3 p {
          max-width: 70%; } }
    .OldLanding_Landing__8J6fE .OldLanding_enviromentContainer__158nI .OldLanding_enviromentImageContainer__2tBWf {
      display: flex;
      justify-content: flex-end;
      align-items: center; }
      @media (min-width: 768px) {
        .OldLanding_Landing__8J6fE .OldLanding_enviromentContainer__158nI .OldLanding_enviromentImageContainer__2tBWf {
          margin-left: 15px; } }
      .OldLanding_Landing__8J6fE .OldLanding_enviromentContainer__158nI .OldLanding_enviromentImageContainer__2tBWf img {
        width: 100%;
        height: auto; }
        @media (min-width: 768px) {
          .OldLanding_Landing__8J6fE .OldLanding_enviromentContainer__158nI .OldLanding_enviromentImageContainer__2tBWf img {
            border-radius: 25px;
            margin-top: 3em;
            margin-bottom: 3em; } }
  .OldLanding_Landing__8J6fE .OldLanding_sampleContainer__3P86e {
    display: flex;
    justify-content: space-around;
    padding: 0 10px 40px 10px;
    width: 100%;
    margin-top: 60px;
    flex-direction: row; }
    @media (max-width: 480px) {
      .OldLanding_Landing__8J6fE .OldLanding_sampleContainer__3P86e {
        flex-direction: column; } }
    .OldLanding_Landing__8J6fE .OldLanding_sampleContainer__3P86e .OldLanding_contentMock__aAlb7 {
      display: flex;
      justify-content: center;
      position: relative; }
      .OldLanding_Landing__8J6fE .OldLanding_sampleContainer__3P86e .OldLanding_contentMock__aAlb7 .OldLanding_sampleImg__bxMaV {
        height: 350px;
        margin-right: -35px;
        align-self: flex-end;
        padding-right: 1em; }
        @media (min-width: 768px) {
          .OldLanding_Landing__8J6fE .OldLanding_sampleContainer__3P86e .OldLanding_contentMock__aAlb7 .OldLanding_sampleImg__bxMaV {
            height: 400px;
            margin-right: 20px;
            padding-right: 0.5em; } }
        @media (min-width: 1024px) {
          .OldLanding_Landing__8J6fE .OldLanding_sampleContainer__3P86e .OldLanding_contentMock__aAlb7 .OldLanding_sampleImg__bxMaV {
            height: 500px;
            padding-right: 4em; } }
      .OldLanding_Landing__8J6fE .OldLanding_sampleContainer__3P86e .OldLanding_contentMock__aAlb7 .OldLanding_mockStepsView__kdCyn {
        height: 325px;
        margin-right: -35px;
        z-index: 30;
        align-self: flex-end; }
        @media (min-width: 768px) {
          .OldLanding_Landing__8J6fE .OldLanding_sampleContainer__3P86e .OldLanding_contentMock__aAlb7 .OldLanding_mockStepsView__kdCyn {
            height: 350px;
            margin-right: -2em; } }
        @media (min-width: 1024px) {
          .OldLanding_Landing__8J6fE .OldLanding_sampleContainer__3P86e .OldLanding_contentMock__aAlb7 .OldLanding_mockStepsView__kdCyn {
            height: 450px;
            margin-right: -2.50em; } }
      .OldLanding_Landing__8J6fE .OldLanding_sampleContainer__3P86e .OldLanding_contentMock__aAlb7 .OldLanding_mockContainerImg__2ggDN {
        position: absolute;
        width: 250px;
        bottom: -8%;
        left: -20%;
        z-index: 50; }
        @media (max-width: 768px) {
          .OldLanding_Landing__8J6fE .OldLanding_sampleContainer__3P86e .OldLanding_contentMock__aAlb7 .OldLanding_mockContainerImg__2ggDN {
            width: 160px;
            bottom: -6%;
            left: -17%; } }
        @media (max-width: 480px) {
          .OldLanding_Landing__8J6fE .OldLanding_sampleContainer__3P86e .OldLanding_contentMock__aAlb7 .OldLanding_mockContainerImg__2ggDN {
            left: 0; } }
    .OldLanding_Landing__8J6fE .OldLanding_sampleContainer__3P86e .OldLanding_contentList__1mm13 {
      margin-right: 40px; }
      @media (max-width: 480px) {
        .OldLanding_Landing__8J6fE .OldLanding_sampleContainer__3P86e .OldLanding_contentList__1mm13 {
          margin-right: 20px;
          margin-left: 20px; } }
      .OldLanding_Landing__8J6fE .OldLanding_sampleContainer__3P86e .OldLanding_contentList__1mm13 h1 {
        color: #50a7b4; }
        @media (min-width: 768px) {
          .OldLanding_Landing__8J6fE .OldLanding_sampleContainer__3P86e .OldLanding_contentList__1mm13 h1 {
            padding-left: 66px; } }
      .OldLanding_Landing__8J6fE .OldLanding_sampleContainer__3P86e .OldLanding_contentList__1mm13 .OldLanding_content__1qmkn {
        display: flex;
        justify-content: space-around; }
      .OldLanding_Landing__8J6fE .OldLanding_sampleContainer__3P86e .OldLanding_contentList__1mm13 .OldLanding_sampleList__1uDZQ {
        list-style-type: none;
        text-align: left;
        font-size: 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-weight: bold; }
        @media (max-width: 768px) {
          .OldLanding_Landing__8J6fE .OldLanding_sampleContainer__3P86e .OldLanding_contentList__1mm13 .OldLanding_sampleList__1uDZQ {
            font-size: 17px;
            padding-left: 0; } }
        .OldLanding_Landing__8J6fE .OldLanding_sampleContainer__3P86e .OldLanding_contentList__1mm13 .OldLanding_sampleList__1uDZQ li {
          display: flex;
          align-items: center; }
  .OldLanding_Landing__8J6fE .OldLanding_reviewsContainer__3n5Fg {
    display: flex;
    justify-content: center;
    width: 100%; }
    .OldLanding_Landing__8J6fE .OldLanding_reviewsContainer__3n5Fg button {
      font-size: 30px;
      background-color: transparent;
      color: #fff8e3;
      border: none;
      padding: 10px 20px;
      border-radius: 6px;
      cursor: pointer; }
    .OldLanding_Landing__8J6fE .OldLanding_reviewsContainer__3n5Fg .OldLanding_reviewContainerContent__1b_Ei {
      display: flex;
      align-items: center;
      background-color: #50a7b4;
      height: 350px;
      padding-left: 150px;
      padding-right: 150px; }
      @media (max-width: 768px) {
        .OldLanding_Landing__8J6fE .OldLanding_reviewsContainer__3n5Fg .OldLanding_reviewContainerContent__1b_Ei {
          padding-right: 10px;
          padding-left: 10px;
          flex-direction: column; } }
      .OldLanding_Landing__8J6fE .OldLanding_reviewsContainer__3n5Fg .OldLanding_reviewContainerContent__1b_Ei .OldLanding_reviewLogo__185k5 {
        width: 150px;
        height: 150px;
        object-fit: contain;
        background-color: #ffffff;
        border-radius: 50%; }
        @media (max-width: 768px) {
          .OldLanding_Landing__8J6fE .OldLanding_reviewsContainer__3n5Fg .OldLanding_reviewContainerContent__1b_Ei .OldLanding_reviewLogo__185k5 {
            width: 50px;
            height: 50px;
            position: absolute;
            margin: 15px 0 0 90px; } }
      .OldLanding_Landing__8J6fE .OldLanding_reviewsContainer__3n5Fg .OldLanding_reviewContainerContent__1b_Ei .OldLanding_reviewDataContainer__30P4E {
        padding-left: 50px;
        display: flex;
        color: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        text-align: start; }
        @media (max-width: 768px) {
          .OldLanding_Landing__8J6fE .OldLanding_reviewsContainer__3n5Fg .OldLanding_reviewContainerContent__1b_Ei .OldLanding_reviewDataContainer__30P4E {
            max-width: 500px;
            margin-top: 20px;
            margin-right: 50px;
            text-align: justify; } }
        .OldLanding_Landing__8J6fE .OldLanding_reviewsContainer__3n5Fg .OldLanding_reviewContainerContent__1b_Ei .OldLanding_reviewDataContainer__30P4E .OldLanding_starsContainer__mmlB6 {
          display: flex;
          align-items: center; }
        .OldLanding_Landing__8J6fE .OldLanding_reviewsContainer__3n5Fg .OldLanding_reviewContainerContent__1b_Ei .OldLanding_reviewDataContainer__30P4E a {
          text-decoration: underline;
          color: white;
          margin-bottom: 10px; }
        .OldLanding_Landing__8J6fE .OldLanding_reviewsContainer__3n5Fg .OldLanding_reviewContainerContent__1b_Ei .OldLanding_reviewDataContainer__30P4E p {
          font-weight: bold;
          font-size: 17px;
          margin-bottom: 8px; }
        @media (max-width: 768px) {
          .OldLanding_Landing__8J6fE .OldLanding_reviewsContainer__3n5Fg .OldLanding_reviewContainerContent__1b_Ei .OldLanding_reviewDataContainer__30P4E h2 {
            max-width: 200px;
            text-align: start;
            margin: 10px 0 0; } }
  .OldLanding_Landing__8J6fE .OldLanding_newsContainer__LFZVH {
    display: flex;
    justify-content: center;
    width: 100%; }
    .OldLanding_Landing__8J6fE .OldLanding_newsContainer__LFZVH button {
      font-size: 30px;
      background-color: transparent;
      color: #fff8e3;
      border: none;
      padding: 10px 20px;
      border-radius: 6px;
      cursor: pointer; }
    .OldLanding_Landing__8J6fE .OldLanding_newsContainer__LFZVH .OldLanding_newsContainerContent__2VGBc {
      display: flex;
      align-items: center;
      background-color: #50a7b4;
      height: 350px;
      padding-left: 150px;
      padding-right: 150px; }
      @media (max-width: 768px) {
        .OldLanding_Landing__8J6fE .OldLanding_newsContainer__LFZVH .OldLanding_newsContainerContent__2VGBc {
          padding-right: 10px;
          padding-left: 10px;
          flex-direction: column; } }
      .OldLanding_Landing__8J6fE .OldLanding_newsContainer__LFZVH .OldLanding_newsContainerContent__2VGBc .OldLanding_newsLogo__1mn5f {
        width: 150px;
        height: 150px;
        object-fit: contain;
        background-color: #ffffff;
        border-radius: 50%; }
        @media (max-width: 768px) {
          .OldLanding_Landing__8J6fE .OldLanding_newsContainer__LFZVH .OldLanding_newsContainerContent__2VGBc .OldLanding_newsLogo__1mn5f {
            width: 50px;
            height: 50px;
            position: absolute;
            margin: 15px 0 0 90px; } }
      .OldLanding_Landing__8J6fE .OldLanding_newsContainer__LFZVH .OldLanding_newsContainerContent__2VGBc .OldLanding_newsDataContainer__P0kCL {
        padding-left: 50px;
        display: flex;
        color: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        text-align: start; }
        @media (max-width: 768px) {
          .OldLanding_Landing__8J6fE .OldLanding_newsContainer__LFZVH .OldLanding_newsContainerContent__2VGBc .OldLanding_newsDataContainer__P0kCL {
            max-width: 500px;
            margin-top: 20px;
            margin-right: 50px;
            text-align: justify; } }
        .OldLanding_Landing__8J6fE .OldLanding_newsContainer__LFZVH .OldLanding_newsContainerContent__2VGBc .OldLanding_newsDataContainer__P0kCL a {
          text-decoration: underline;
          color: white;
          margin-bottom: 10px; }
        .OldLanding_Landing__8J6fE .OldLanding_newsContainer__LFZVH .OldLanding_newsContainerContent__2VGBc .OldLanding_newsDataContainer__P0kCL p {
          font-weight: bold;
          font-size: 17px;
          margin-bottom: 8px;
          margin-top: 30px; }
        @media (max-width: 768px) {
          .OldLanding_Landing__8J6fE .OldLanding_newsContainer__LFZVH .OldLanding_newsContainerContent__2VGBc .OldLanding_newsDataContainer__P0kCL h2 {
            max-width: 200px;
            text-align: start;
            margin: 10px 0 0; } }
  .OldLanding_Landing__8J6fE .OldLanding_footerContainer__1OWKh {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    flex-direction: column; }
    @media (max-width: 768px) {
      .OldLanding_Landing__8J6fE .OldLanding_footerContainer__1OWKh {
        align-items: center; } }
    .OldLanding_Landing__8J6fE .OldLanding_footerContainer__1OWKh .OldLanding_sevi__1w1Oo {
      cursor: pointer;
      align-self: center;
      color: #716b77;
      margin-top: 20px;
      text-align: center; }
    .OldLanding_Landing__8J6fE .OldLanding_footerContainer__1OWKh .OldLanding_codeModal__1t4x- {
      cursor: pointer;
      align-self: center;
      margin: 10px;
      text-align: center;
      text-decoration: underline; }
    .OldLanding_Landing__8J6fE .OldLanding_footerContainer__1OWKh .OldLanding_footerContent__11T__ {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center; }
      @media (max-width: 768px) {
        .OldLanding_Landing__8J6fE .OldLanding_footerContainer__1OWKh .OldLanding_footerContent__11T__ {
          flex-direction: column; } }
      .OldLanding_Landing__8J6fE .OldLanding_footerContainer__1OWKh .OldLanding_footerContent__11T__ .OldLanding_footerLawsContainer__1HVgs {
        display: flex;
        width: 210px;
        justify-content: space-around; }
        .OldLanding_Landing__8J6fE .OldLanding_footerContainer__1OWKh .OldLanding_footerContent__11T__ .OldLanding_footerLawsContainer__1HVgs p {
          font-size: 15px;
          color: #716b77; }
      .OldLanding_Landing__8J6fE .OldLanding_footerContainer__1OWKh .OldLanding_footerContent__11T__ .OldLanding_seviLogo__3lvh4 {
        width: 150px; }
      .OldLanding_Landing__8J6fE .OldLanding_footerContainer__1OWKh .OldLanding_footerContent__11T__ .OldLanding_footerContentServices__3zEZN {
        display: flex;
        flex-direction: column; }
        .OldLanding_Landing__8J6fE .OldLanding_footerContainer__1OWKh .OldLanding_footerContent__11T__ .OldLanding_footerContentServices__3zEZN div {
          max-width: 400px; }

.OldLanding_modalInfoHeader__2yZFc {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px; }

.OldLanding_modalInfoContent__2E1P5 {
  display: flex;
  align-items: center;
  justify-content: space-around; }
  @media (max-width: 768px) {
    .OldLanding_modalInfoContent__2E1P5 {
      flex-direction: column; } }
  .OldLanding_modalInfoContent__2E1P5 li {
    font-size: 20px;
    margin-top: 10px; }
  .OldLanding_modalInfoContent__2E1P5 .OldLanding_imageHand__3DzFJ {
    width: 15rem;
    height: 15rem; }
  .OldLanding_modalInfoContent__2E1P5 .OldLanding_imageKid__3inDj {
    width: 11rem;
    height: 22rem; }

.OldLanding_seeMoreText__P52sZ {
  text-decoration: underline; }

.OldLanding_cookiesLayer__2zLU5 {
  width: 100%;
  height: 100%;
  background: rgba(128, 128, 128, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 6000; }
  .OldLanding_cookiesLayer__2zLU5 .OldLanding_snackbar__2zUwI {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 6rem;
    width: 100%;
    height: 22rem;
    font-size: 1.2rem;
    text-align: center; }
    @media (max-width: 1024px) {
      .OldLanding_cookiesLayer__2zLU5 .OldLanding_snackbar__2zUwI {
        width: 75%;
        padding: 2rem; } }
    .OldLanding_cookiesLayer__2zLU5 .OldLanding_snackbar__2zUwI .OldLanding_moreInfoCookieText__27h9- {
      color: white;
      margin: 5px; }
    .OldLanding_cookiesLayer__2zLU5 .OldLanding_snackbar__2zUwI button {
      background-color: transparent;
      color: white;
      border: none; }

.OldLanding_finalImgContainer__3PaLd {
  position: relative;
  margin: 0; }
  .OldLanding_finalImgContainer__3PaLd .OldLanding_asterisco__M7sNZ {
    position: absolute;
    bottom: 2px;
    font-size: 15px !important;
    color: #efe9d6;
    right: 20%; }
    @media (max-width: 768px) {
      .OldLanding_finalImgContainer__3PaLd .OldLanding_asterisco__M7sNZ {
        right: 41%; } }

.OldLanding_cookiesMoreInfoButton__1GDiY {
  background-color: #50a7b4 !important; }

.OldLanding_cookiesMoreInfoButton__1GDiY:hover {
  background-color: #448086 !important; }

.OldLanding_privacy__1AGT- {
  cursor: pointer; }

.OldLanding_privacy__1AGT-:hover {
  text-decoration: underline; }

.OldLanding_shareButtonsBox__37COb {
  padding: 2em;
  padding-bottom: 1em !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly; }

.OldLanding_shareButton__6S7ns:hover {
  cursor: pointer; }

.OldLanding_floatingButtonGetIt__2B4T1, .OldLanding_floatingButtonGetItLogged__3kt0_ {
  position: fixed !important;
  bottom: 1em !important;
  right: 1em !important;
  box-shadow: 3px 7px 20px 0px black;
  color: #448086 !important;
  background-color: #fff8e3 !important;
  z-index: 10; }
  @media (min-width: 768px) {
    .OldLanding_floatingButtonGetIt__2B4T1, .OldLanding_floatingButtonGetItLogged__3kt0_ {
      display: none !important; } }

.OldLanding_floatingButtonGetItLogged__3kt0_ {
  bottom: 5em !important; }

.OldLanding_floatingButtonGetItIcon__19j1g {
  margin-right: 0.3em; }

.OldLanding_companiesTrustContainer__3CMTW {
  margin-top: 3em !important;
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 768px) {
    .OldLanding_companiesTrustContainer__3CMTW {
      padding-bottom: 5em; } }
  .OldLanding_companiesTrustContainer__3CMTW .OldLanding_companiesTrustContent__i9RfR {
    display: flex;
    align-items: center; }
  .OldLanding_companiesTrustContainer__3CMTW .OldLanding_companiesTrustLogo__1yOU1 {
    width: 150px; }
    @media (max-width: 480px) {
      .OldLanding_companiesTrustContainer__3CMTW .OldLanding_companiesTrustLogo__1yOU1 {
        width: 75px; } }

.OldLanding_companiesTrustClaim___X-nG {
  color: #ffffff !important;
  font-size: 20px !important;
  text-align: center !important; }
  @media (max-width: 480px) {
    .OldLanding_companiesTrustClaim___X-nG {
      font-size: 15px !important; } }

.OldLanding_floatingActiveBraceletButton__1opj_ {
  position: fixed !important;
  border-radius: 25px !important;
  background-color: #fff8e3 !important;
  color: #448086 !important;
  bottom: 1em;
  right: 1em;
  box-shadow: 3px 7px 20px 0px black; }

.OldLanding_activeIcon__38EgO {
  color: #448086 !important;
  margin-right: 0.5em !important; }

.OldLanding_FbLikeButtonContainer__prPYs {
  padding-top: 1em; }
  @media (min-width: 768px) {
    .OldLanding_FbLikeButtonContainer__prPYs {
      padding-top: 3em; } }

.OldLanding_facebookLikeButton__2Xcqk {
  display: flex !important;
  align-items: center !important;
  margin-top: 2em !important;
  padding-right: 1.5em !important;
  font-size: 11px !important;
  background-color: #3C5A99 !important;
  border-radius: 6px; }
  @media (max-width: 768px) {
    .OldLanding_facebookLikeButton__2Xcqk {
      display: none !important; } }
  @media (min-width: 768px) {
    .OldLanding_facebookLikeButton__2Xcqk {
      font-size: 13px !important;
      padding-right: 1.7em !important; } }

.OldLanding_facebookLike__2avC3 {
  height: 30px;
  width: 30px; }
  @media (min-width: 768px) {
    .OldLanding_facebookLike__2avC3 {
      height: 45px;
      width: 45px; } }

.OldLanding_pulsidHeadImage__1CbI- {
  display: none;
  height: 100px;
  width: 135px; }
  @media (max-width: 768px) {
    .OldLanding_pulsidHeadImage__1CbI- {
      display: block; } }

.OldLanding_pulsidPriceBadgeDesktop__20OxH {
  min-width: 4rem;
  min-height: 4rem;
  background-color: #c3dd36;
  font-size: large !important;
  font-weight: bold; }
  @media (max-width: 768px) {
    .OldLanding_pulsidPriceBadgeDesktop__20OxH {
      display: none !important; } }

.OldLanding_pulsidPriceBadgeAlways__2bM4S {
  min-width: 4rem;
  min-height: 4rem;
  background-color: #c3dd36;
  font-size: large !important;
  font-weight: bold; }

.OldLanding_pulsidPriceBadge__3vlXe {
  display: none !important; }
  @media (max-width: 768px) {
    .OldLanding_pulsidPriceBadge__3vlXe {
      display: block !important; } }
  .OldLanding_pulsidPriceBadge__3vlXe span:last-child {
    min-width: 3rem;
    background-color: #c3dd36 !important;
    font-size: medium;
    font-weight: bold;
    min-height: 3rem; }

.OldLanding_discountSticker__3GgGg {
  position: absolute;
  right: -25px;
  top: -25px;
  border-radius: 50%;
  font-size: 15px;
  padding: 1.3em 1em;
  background-color: #ff5a5a;
  color: #ffffff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12); }

.OldLanding_offerSnackbar__OnD53 {
  width: 100%;
  max-width: 100% !important;
  text-align: center;
  font-size: 17px !important; }
  .OldLanding_offerSnackbar__OnD53 div:first-child {
    width: 100%;
    max-width: 100% !important;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    font-size: 17px !important; }

.OldLanding_snackOfferButton__3n9vM {
  background-color: #448086;
  color: #fff8e3; }

.OldLanding_offerBanner__9uvJB {
  position: fixed;
  display: flex;
  z-index: 55;
  bottom: 0;
  width: 100%;
  padding: 1em;
  text-align: center;
  font-size: 20px;
  background-color: #ff0012;
  color: white; }
  @media (max-width: 768px) {
    .OldLanding_offerBanner__9uvJB {
      flex-direction: column;
      display: none; } }

.OldLanding_offerBannerMobile__1B47F {
  position: fixed;
  display: none;
  z-index: 55;
  bottom: 0;
  width: 100%;
  padding: 1em;
  text-align: center;
  font-size: 20px;
  background-color: #FF0012;
  color: white; }
  @media (max-width: 768px) {
    .OldLanding_offerBannerMobile__1B47F {
      display: flex;
      justify-content: space-around; } }

.OldLanding_closeOfferBanner__nI0Lx {
  position: absolute;
  right: 25px;
  top: 8px; }
  @media (max-width: 768px) {
    .OldLanding_closeOfferBanner__nI0Lx {
      position: absolute;
      right: 5px;
      top: 5px; } }

.OldLanding_offerBannerCountdown__1YY9_ {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 768px) {
    .OldLanding_offerBannerCountdown__1YY9_ {
      margin-top: 1.4em !important; } }

@media (max-width: 768px) {
  .OldLanding_offerBannerCountdown__1YY9_, .OldLanding_offerClaim__3DrYj {
    display: flex;
    flex-direction: column; } }

.OldLanding_offerClaim__3DrYj span:first-child {
  display: flex;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    .OldLanding_offerClaim__3DrYj span:first-child {
      display: inline-flex !important; } }

.OldLanding_offerButtonBox__3SvJ5 {
  display: flex;
  align-items: center;
  justify-content: center; }
  @media (max-width: 768px) {
    .OldLanding_offerButtonBox__3SvJ5 {
      margin-top: 1em !important; } }

.OldLanding_offerBannerGetItButton__3IOqS {
  background-color: #00B32C !important;
  color: #ffffff !important; }

.OldLanding_closeOfferBannerIcon__3w18- {
  color: #ffffff; }

.OldLanding_whatsappPostSection__3cf10 {
  display: flex;
  justify-content: space-around;
  background-color: #74a7b1;
  width: 100%;
  padding-top: 2rem;
  margin-bottom: -2px;
  color: #fff; }

.OldLanding_whatsappPostClaim__1S8Bc {
  text-align: center !important; }

.OldLanding_whatsappPostItem__Pxcdx {
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 2rem !important; }
  .OldLanding_whatsappPostItem__Pxcdx .OldLanding_downloadDocumentButton__f9qmT {
    background-color: #fff8e3; }

.OldLanding_whatsappPostImage__Cn-3m {
  height: 250px; }

.OldLanding_newsletterSectionGrid__2Lged {
  padding-top: 2rem;
  padding-bottom: 4rem;
  background-color: #fff8e3; }
  @media (min-width: 768px) {
    .OldLanding_newsletterSectionGrid__2Lged {
      padding-top: 5rem;
      padding-bottom: 3.5rem; } }

.OldLanding_CTAContainer__1pMsu {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em !important; }
  .OldLanding_CTAContainer__1pMsu p {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    display: flex;
    align-items: center;
    font-size: 17px;
    background-color: #ffffff;
    border-radius: 8px;
    padding-left: 0.4rem;
    padding-right: 1.2rem; }
  .OldLanding_CTAContainer__1pMsu .OldLanding_secondaryCTAButton__3KQpM {
    color: #50a7b4 !important;
    border: 2px solid !important;
    padding: 1em 2.5em !important;
    border-radius: 10px !important;
    cursor: pointer !important;
    font-size: 30px !important; }
    @media (max-width: 480px) {
      .OldLanding_CTAContainer__1pMsu .OldLanding_secondaryCTAButton__3KQpM {
        font-size: 20px !important; } }

.OldLanding_navidadCollage__1KXec {
  width: 11rem;
  margin-top: 2rem; }
  @media (min-width: 1024px) {
    .OldLanding_navidadCollage__1KXec {
      margin-top: 6rem;
      width: 14rem; } }

/*font-family*/
/*font-size*/
/*font-weight*/
.oldBuyModals_mainDialogContent__2mw6c {
  background: #f8f8f8; }
  @media (min-width: 1024px) {
    .oldBuyModals_mainDialogContent__2mw6c {
      border-radius: 8px;
      margin: 0 11rem;
      margin-top: 1rem !important;
      margin-bottom: 1rem !important; } }

.oldBuyModals_parentContainer__VcSnc {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: inherit !important; }

.oldBuyModals_termsContainer__3M-j9 {
  display: flex;
  flex-direction: column;
  align-items: center; }

.oldBuyModals_formControl__-q_jq {
  margin-top: 8px;
  min-width: 120px;
  display: flex !important; }
  .oldBuyModals_formControl__-q_jq label {
    color: #50a7b4 !important; }
  .oldBuyModals_formControl__-q_jq div fieldset {
    border-color: #50a7b4 !important; }
  .oldBuyModals_formControl__-q_jq svg {
    color: #50a7b4 !important; }

.oldBuyModals_checkbox__1Bi73 {
  color: #50a7b4 !important; }

.oldBuyModals_countryGridItem__1H9s0 {
  visibility: hidden; }
  .oldBuyModals_countryGridItem__1H9s0 .oldBuyModals_formRedsys__3viOm {
    width: 100%; }

.oldBuyModals_cityZipcodeContainer__1bcMA {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.oldBuyModals_privacyLink__28gZn {
  color: #716b77; }

.oldBuyModals_privacyLink__28gZn:hover {
  color: #0077b5;
  text-decoration: underline; }

.oldBuyModals_closeModalIcon__3hoS3 {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.oldBuyModals_fixModalPadding__BnOyI {
  padding: 0 !important; }

.oldBuyModals_paymentModal__34JDj {
  padding: 0 !important; }
  .oldBuyModals_paymentModal__34JDj [class^="MuiPaper-root"] {
    min-width: 350px; }
  .oldBuyModals_paymentModal__34JDj .oldBuyModals_paymentModalTitleBox__N7DHd {
    display: flex;
    align-items: center;
    margin-top: -20px; }

.oldBuyModals_sendingDataAnimation__380WW {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.oldBuyModals_textField__11-yB label {
  color: #50a7b4 !important; }

.oldBuyModals_textField__11-yB div fieldset {
  border-color: #50a7b4 !important; }

.oldBuyModals_textFieldError__3iejg label {
  color: #ff3d00 !important; }

.oldBuyModals_textFieldError__3iejg div fieldset {
  border-color: #ff3d00 !important; }

.oldBuyModals_contentText__3b_Al {
  margin-bottom: 10px !important; }

.oldBuyModals_contentInnerText__3OlDn {
  margin-left: 8px !important;
  font-size: 2.5rem !important;
  align-self: start !important; }

.oldBuyModals_formContainer__DqV0Q {
  display: flex;
  flex-direction: column !important;
  flex-wrap: wrap;
  justify-content: space-evenly; }

.oldBuyModals_sizeCardContainer__3b6l7 {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  justify-content: space-around; }
  @media (max-width: 480px) {
    .oldBuyModals_sizeCardContainer__3b6l7 {
      flex-direction: column-reverse;
      margin-top: 10px !important; } }
  .oldBuyModals_sizeCardContainer__3b6l7 .oldBuyModals_sizeCard__19mD5 {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    margin: 0.75em; }
    @media (max-width: 480px) {
      .oldBuyModals_sizeCardContainer__3b6l7 .oldBuyModals_sizeCard__19mD5 {
        margin: 1.00em;
        padding: 1em; } }
    .oldBuyModals_sizeCardContainer__3b6l7 .oldBuyModals_sizeCard__19mD5 .oldBuyModals_sizeCardImg__3yjVe {
      width: 250px;
      margin: 1em !important; }
      @media (max-width: 480px) {
        .oldBuyModals_sizeCardContainer__3b6l7 .oldBuyModals_sizeCard__19mD5 .oldBuyModals_sizeCardImg__3yjVe {
          width: 200px; } }
    .oldBuyModals_sizeCardContainer__3b6l7 .oldBuyModals_sizeCard__19mD5 .oldBuyModals_sizeCardSize__3R6QL {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: #448086; }
      .oldBuyModals_sizeCardContainer__3b6l7 .oldBuyModals_sizeCard__19mD5 .oldBuyModals_sizeCardSize__3R6QL .oldBuyModals_braceletSizeBox__2ueJs {
        display: flex;
        align-items: center;
        flex-direction: row;
        color: #448086;
        margin-top: 5px;
        margin-bottom: 10px; }
      .oldBuyModals_sizeCardContainer__3b6l7 .oldBuyModals_sizeCard__19mD5 .oldBuyModals_sizeCardSize__3R6QL .oldBuyModals_braceletSizeCode__11D1n {
        margin-top: 10px; }
      .oldBuyModals_sizeCardContainer__3b6l7 .oldBuyModals_sizeCard__19mD5 .oldBuyModals_sizeCardSize__3R6QL svg {
        font-size: 15px; }
    .oldBuyModals_sizeCardContainer__3b6l7 .oldBuyModals_sizeCard__19mD5 p {
      color: #448086;
      font-weight: bold; }
    .oldBuyModals_sizeCardContainer__3b6l7 .oldBuyModals_sizeCard__19mD5 .oldBuyModals_sizePicker__2VT4N {
      margin: 1em; }
      .oldBuyModals_sizeCardContainer__3b6l7 .oldBuyModals_sizeCard__19mD5 .oldBuyModals_sizePicker__2VT4N label {
        font-size: 15px;
        margin-left: -3px; }
  .oldBuyModals_sizeCardContainer__3b6l7 .oldBuyModals_braceletsSelectorGrid__3TeNF {
    display: flex;
    flex-direction: row;
    align-items: center; }
    @media (max-width: 480px) {
      .oldBuyModals_sizeCardContainer__3b6l7 .oldBuyModals_braceletsSelectorGrid__3TeNF {
        flex-direction: column-reverse; } }

.oldBuyModals_sizeSelectorActions__GxweE {
  display: flex; }

@media (min-width: 768px) {
  .oldBuyModals_sizeSelectorMobileButton__3aGiX {
    display: none !important; } }

.oldBuyModals_sizeStock__zIGE4 {
  color: red !important;
  margin: 0;
  padding-bottom: 0.5rem; }

.oldBuyModals_sizeCardContainerCart__3L0QL {
  justify-content: center;
  margin-bottom: 1em !important; }
  .oldBuyModals_sizeCardContainerCart__3L0QL .oldBuyModals_sizeCardCart__PFrqx {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-left: solid 5px #4FA9B3; }
    @media (min-width: 480px) {
      .oldBuyModals_sizeCardContainerCart__3L0QL .oldBuyModals_sizeCardCart__PFrqx {
        padding: 0 1rem;
        margin: 1rem; } }
    @media (max-width: 480px) {
      .oldBuyModals_sizeCardContainerCart__3L0QL .oldBuyModals_sizeCardCart__PFrqx {
        width: 100% !important; } }
    .oldBuyModals_sizeCardContainerCart__3L0QL .oldBuyModals_sizeCardCart__PFrqx .oldBuyModals_sizeCardImg__3yjVe {
      width: 100px;
      margin: 1em !important; }
      @media (max-width: 480px) {
        .oldBuyModals_sizeCardContainerCart__3L0QL .oldBuyModals_sizeCardCart__PFrqx .oldBuyModals_sizeCardImg__3yjVe {
          width: 90px; } }
    .oldBuyModals_sizeCardContainerCart__3L0QL .oldBuyModals_sizeCardCart__PFrqx p, .oldBuyModals_sizeCardContainerCart__3L0QL .oldBuyModals_sizeCardCart__PFrqx span {
      color: #448086;
      font-weight: bold;
      margin: 5px; }
    .oldBuyModals_sizeCardContainerCart__3L0QL .oldBuyModals_sizeCardCart__PFrqx span {
      margin-top: 5px; }
    .oldBuyModals_sizeCardContainerCart__3L0QL .oldBuyModals_sizeCardCart__PFrqx .oldBuyModals_productImageBox__1-PNM {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .oldBuyModals_sizeCardContainerCart__3L0QL .oldBuyModals_sizeCardCart__PFrqx .oldBuyModals_productDetails__2zvvj {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }

.oldBuyModals_cartItem__2V-s5 {
  display: flex !important;
  justify-content: center;
  padding: 10px 0 !important; }

.oldBuyModals_discountContainer__3I_2i {
  display: flex;
  align-items: center; }
  .oldBuyModals_discountContainer__3I_2i .oldBuyModals_discountButton__1wp-W {
    margin: 10px;
    border: 1px solid #4FA9B3;
    color: #50a7b4 !important; }
  .oldBuyModals_discountContainer__3I_2i .oldBuyModals_discountButton__1wp-W:disabled {
    border: 1px solid #aaaaaa;
    color: #e0e0e0 !important; }

.oldBuyModals_snackTotalBraceletsMessage__3K0Dn {
  font-weight: bold; }

.oldBuyModals_snackTotalBracelets__1cQbv {
  padding: 15px; }
  .oldBuyModals_snackTotalBracelets__1cQbv button {
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer; }
  .oldBuyModals_snackTotalBracelets__1cQbv span {
    background-color: darkorange; }
  .oldBuyModals_snackTotalBracelets__1cQbv a {
    color: #fff; }
  .oldBuyModals_snackTotalBracelets__1cQbv a:hover {
    color: blue; }

.oldBuyModals_packagingSection__k02Jl {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }

.oldBuyModals_packagingCheck__1Im8u {
  align-self: flex-start !important; }

.oldBuyModals_packagingCheckLabel__1fLEV {
  color: #716b77 !important; }

.oldBuyModals_packagingQuantitySelector__2B5_f {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 75%;
  padding: 5px; }
  @media (max-width: 480px) {
    .oldBuyModals_packagingQuantitySelector__2B5_f {
      width: 75%; } }

.oldBuyModals_packagingImgContainer__9XOMn {
  display: flex;
  align-items: center;
  justify-content: center; }

.oldBuyModals_packagingImg__vK9gX {
  width: 270px;
  height: auto;
  border-radius: 15px;
  margin: 1em !important; }
  @media (max-width: 480px) {
    .oldBuyModals_packagingImg__vK9gX {
      height: auto;
      width: 220px; } }

.oldBuyModals_packagingInput__3DbVb {
  width: 100px; }

.oldBuyModals_packagingCartImg__3pevA {
  margin-top: 0.5em;
  width: 100%;
  border-radius: 15px; }
  @media (max-width: 480px) {
    .oldBuyModals_packagingCartImg__3pevA {
      width: 150px; } }

.oldBuyModals_priceChip__3ivx8 {
  margin-bottom: 1em;
  margin-top: 0.5em;
  font-size: 1.5rem !important; }

.oldBuyModals_totalPriceChip__2ItZ4 {
  padding: 22px !important;
  font-size: 2rem !important;
  color: #ffffff !important;
  background-color: #50a7b4 !important;
  margin-left: 1em; }

.oldBuyModals_oldPriceChip__4_i16 {
  margin-bottom: 1em;
  margin-top: 0.5em;
  text-decoration: line-through !important; }

.oldBuyModals_totalPriceWrapper__1d7CQ {
  margin-top: 1em;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-right: 1rem;
  flex-direction: column; }

.oldBuyModals_parentShippingPriceBox__1NiCm {
  display: flex;
  justify-content: center;
  align-items: center; }

.oldBuyModals_shippingPriceBox__1lPUs {
  background-color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-left: 5px solid #CCBD88;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12); }
  .oldBuyModals_shippingPriceBox__1lPUs h6 {
    text-align: center; }
  .oldBuyModals_shippingPriceBox__1lPUs .oldBuyModals_centerContainer__dV2Hl {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center; }

.oldBuyModals_offerCard__1ostF {
  margin: auto;
  width: 75%;
  flex-direction: column;
  padding: 1em !important;
  text-align: center !important;
  border-radius: 50px !important;
  margin-bottom: 0.5em !important;
  margin-top: 0.5em !important;
  background-image: linear-gradient(-20deg, #fdbeb0 0%, #fdf8bd 100%); }
  @media (min-width: 768px) {
    .oldBuyModals_offerCard__1ostF {
      width: 25%; } }

.oldBuyModals_dialogActions__2o4Rk, .oldBuyModals_dialogActionsFirstStep__3s8uA {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  padding: 0.5em;
  box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  margin: 0 !important;
  z-index: 300;
  text-align: center; }

.oldBuyModals_dialogActionButton__32rAh {
  padding: 0.5em 5em !important;
  margin: 5px !important;
  border-radius: 1em !important;
  color: #ffffff !important;
  background-color: #50a7b4 !important; }

.oldBuyModals_dialogActionButton__32rAh:disabled {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  background-color: transparent !important;
  background-color: initial !important;
  color: #808080 !important; }

.oldBuyModals_disabledButton__lL4mR {
  padding: 0.5em 5em !important;
  margin: 5px !important;
  border-radius: 1em !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  background-color: transparent !important;
  color: #808080 !important;
  box-shadow: none !important;
  cursor: default !important; }

.oldBuyModals_loadingPaymentActionsModal__1Q3Sa [class^="MuiPaper-root"] {
  transition: height .45s ease-in-out !important; }

.oldBuyModals_redsysAnimationContainer__1eNJs {
  transition: opacity .45s ease-in-out !important;
  opacity: 0;
  text-align: center; }

@media only screen and (min-device-width: 320px) and (max-device-width: 480px) {
  .oldBuyModals_cityZipcodeContainer__1bcMA {
    display: flex;
    background-color: #4fb457;
    flex-direction: column;
    justify-content: space-between; } }

/*font-family*/
/*font-size*/
/*font-weight*/
.BeforeCloseAlertModal_dialogContentText__2DLUs {
  font-size: 20px !important;
  margin-bottom: 1em !important;
  text-align: center; }

.BeforeCloseAlertModal_mainActionButton__3KnX9 {
  border-radius: 25px !important;
  padding: 1rem 3rem !important; }

.BeforeCloseAlertModal_closeModalIcon__tG0pm {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.BeforeCloseAlertModal_dialogActions__2lpJ5 {
  justify-content: center !important; }

.BeforeCloseAlertModal_sendingDataAnimation__1VlmR {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

/*font-family*/
/*font-size*/
/*font-weight*/
.NewsletterModal_parentContainer__3OZiM {
  display: flex;
  justify-content: center; }

.NewsletterModal_dialogContentText__2EJLN {
  font-size: 20px !important;
  text-align: center;
  margin-bottom: 1em !important; }

.NewsletterModal_mainActionButtonContainer__3QrZo {
  display: flex;
  justify-content: center; }

.NewsletterModal_mainActionButton__G-A2A {
  margin-top: 1em !important;
  border-radius: 50px !important;
  padding: 1.25rem !important; }

.NewsletterModal_dialogActions__-4j4g {
  justify-content: center !important; }

.NewsletterModal_closeModalIcon__2nGwS {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.NewsletterModal_sendingDataAnimation__1qr9v {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

/*font-family*/
/*font-size*/
/*font-weight*/
.PresaleModal_parentContainer__1f9NG {
  display: flex;
  justify-content: center; }

.PresaleModal_dialogContentText__1sZHc {
  font-size: 20px !important;
  text-align: center;
  margin-bottom: 1em !important;
  margin-top: 1em !important; }

.PresaleModal_mainActionButtonContainer__3pcjd {
  display: flex;
  justify-content: center; }

.PresaleModal_mainActionButton__3VFzH {
  margin-top: 1em !important;
  border-radius: 50px !important;
  padding: 1.25rem !important; }

.PresaleModal_dialogActions__2kgxY {
  justify-content: center !important; }

.PresaleModal_closeModalIcon__3BEAQ {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.PresaleModal_sendingDataAnimation__2U7fP {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

/*font-family*/
/*font-size*/
/*font-weight*/
.NewsletterSection_parentContainer__2RHFq {
  display: flex;
  justify-content: center; }

.NewsletterSection_dialogContentText__PauMQ {
  font-size: 20px !important;
  text-align: center !important;
  margin-bottom: 1em !important; }

.NewsletterSection_mainActionButtonContainer__1t-R8 {
  display: flex;
  justify-content: center; }

.NewsletterSection_mainActionButton__3ZXlf {
  margin-top: 1em !important;
  border-radius: 50px !important;
  padding: 1.25rem !important; }
  @media (min-width: 768px) {
    .NewsletterSection_mainActionButton__3ZXlf {
      margin-left: 2rem !important; } }

.NewsletterSection_dialogActions__11pZB {
  justify-content: center !important; }

.NewsletterSection_closeModalIcon__3uAkM {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.NewsletterSection_sendingDataAnimation__1rol8 {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

@media (min-width: 768px) {
  .NewsletterSection_cardContent__2vRaq {
    display: flex;
    justify-content: center;
    align-items: center; } }

@media (min-width: 768px) {
  .NewsletterSection_inputAndClaimParentContainer__1lJOd {
    margin-right: 2rem;
    margin-left: 2rem; } }

.NewsletterSection_inputAndButtonContainer__19jyi {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  @media (min-width: 768px) {
    .NewsletterSection_inputAndButtonContainer__19jyi {
      flex-direction: row; } }

.NewsletterSection_succeedAnimationContainer__2JJS8 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .NewsletterSection_succeedAnimationContainer__2JJS8 .NewsletterSection_succeedAnimationClaim__32T-P {
    margin-top: 1rem; }

/*font-family*/
/*font-size*/
/*font-weight*/
@-webkit-keyframes ImpetraThanksPage_floatBracelet__3dN3c {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }
@keyframes ImpetraThanksPage_floatBracelet__3dN3c {
  0% {
    transform: translatey(0px) rotate(-10deg); }
  50% {
    transform: translatey(-20px) rotate(-10deg); }
  100% {
    transform: translatey(0px) rotate(-10deg); } }

@-webkit-keyframes ImpetraThanksPage_floatVerticalBracelet__3h8Ra {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@keyframes ImpetraThanksPage_floatVerticalBracelet__3h8Ra {
  0% {
    transform: translatey(0px) rotate(-90deg); }
  50% {
    transform: translatey(-20px) rotate(-90deg); }
  100% {
    transform: translatey(0px) rotate(-90deg); } }

@-webkit-keyframes ImpetraThanksPage_backgroundGradient__2kCTN {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@keyframes ImpetraThanksPage_backgroundGradient__2kCTN {
  0% {
    background-position: 0 50%; }
  50% {
    background-position: 100% 50%; }
  100% {
    background-position: 0 50%; } }

@-webkit-keyframes ImpetraThanksPage_backgroundGradient2__3yMm6 {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

@keyframes ImpetraThanksPage_backgroundGradient2__3yMm6 {
  0% {
    background-position: 0 46%; }
  50% {
    background-position: 100% 55%; }
  100% {
    background-position: 0 46%; } }

.ImpetraThanksPage_rootContainer__fMhH7 {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  -webkit-animation: ImpetraThanksPage_backgroundGradient__2kCTN 15s ease-in-out infinite;
          animation: ImpetraThanksPage_backgroundGradient__2kCTN 15s ease-in-out infinite;
  overflow: hidden; }

.ImpetraThanksPage_title__3R4lo {
  font-family: Comfortaa, sans-serif !important;
  color: #FFFFFF !important;
  padding: 1rem;
  text-align: center !important; }
  @media (min-width: 768px) {
    .ImpetraThanksPage_title__3R4lo {
      padding: 4rem 2rem 2rem;
      font-size: 2rem !important; } }

.ImpetraThanksPage_animationAndHashtagContainer__22qaS {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 !important; }
  @media (min-width: 768px) {
    .ImpetraThanksPage_animationAndHashtagContainer__22qaS {
      flex-direction: row; } }

.ImpetraThanksPage_hashtag__DXIzO {
  font-family: Comfortaa, sans-serif !important;
  color: #FFFFFF !important;
  display: flex !important;
  justify-content: flex-end;
  align-items: center;
  font-size: 1.05rem !important; }
  @media (min-width: 768px) {
    .ImpetraThanksPage_hashtag__DXIzO {
      font-size: 1.75rem !important; } }
  .ImpetraThanksPage_hashtag__DXIzO .ImpetraThanksPage_hashtagIcon__qHLtF {
    font-family: Comfortaa, sans-serif !important;
    font-size: 3rem; }
    @media (min-width: 768px) {
      .ImpetraThanksPage_hashtag__DXIzO .ImpetraThanksPage_hashtagIcon__qHLtF {
        font-size: 5rem !important; } }

.ImpetraThanksPage_logosContainer__178Zb {
  display: flex;
  justify-content: center;
  align-items: center; }

.ImpetraThanksPage_pulsidLogo__11RjB {
  width: 60%; }
  @media (min-width: 768px) {
    .ImpetraThanksPage_pulsidLogo__11RjB {
      width: 10rem; } }

.ImpetraThanksPage_impetraLogo__3W6Ri {
  width: 85%; }
  @media (min-width: 768px) {
    .ImpetraThanksPage_impetraLogo__3W6Ri {
      width: 13rem; } }

