/*font-family*/
$font-family-primary: HelveticaNeue, sans-serif;

/*font-size*/
$micro-font-size: 11px;
$tiny-font-size: 13px;
$small-font-size: 15px;
$medium-font-size: 17px;
$large-font-size: 20px;
$big-font-size: 30px;
$title-font-size: 40px;
$bigger-font-size: 70px;
$huge-font-size: 80px;

/*font-weight*/
$light-font-weight: 300;
$regular-font-weight: 400;
$medium-font-weight: 500;
$bold-font-weight: 700;



