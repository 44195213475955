@import "../../styles/colors";
@import "../../styles/palette";
@import "../../styles/typography.sass";
@import "../../styles/responsive.sass";
@import "../../styles/animations";

.Landing {
  display: flex;
  flex-direction: column;
  text-align: justify;
  align-items: center;
  overflow-x: hidden;
  font-family: Aristotelica;
  background-color: #ffffff;

  h1 {
    color: white;
    text-align: center;
    font-size: $big-font-size; }

  h2 {
    color: white;
    text-align: center;
    font-size: $big-font-size;
    @media (max-width: $tablet) {
      margin-top: 0;
      font-size: $large-font-size; } }

  h3 {
    font-family: Blogger-sans; }

  li {
    font-family: Blogger-sans; }

  a {
    font-family: Blogger-sans; }

  p {
    font-size: $large-font-size;
    font-family: Blogger-sans;
    text-align: justify; }

  .headContainer {
    display: flex;
    min-height: 100vh;
    padding: 7rem 0.5rem 0.5rem;
    align-items: center;
    //justify-content: center
    //background: url(../../img/bg-masthead-blur2.png) no-repeat center
    //background-image: linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)
    background: linear-gradient(to bottom, #06beb6, #74a7b2);
    background-size: cover;


    h1 {
      margin-bottom: 2rem;
      font-size: 60px;
      @media (max-width: $tablet) {
        font-size: $title-font-size; } }

    .headContainerImg {
      @media (min-width: $computer) {
        margin-top: -200px; } }

    .headContainerContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 1.5rem;
      @media (min-width: $tablet) {
        padding-left: 3rem;
        padding-right: 2rem; } }

    .videoPlayerGridItem {
      @media (min-width: $tablet) {
        padding-left: 2rem;
        padding-right: 3rem; } } }
  .dataIntroduction {
    margin-top: 6rem;
    margin-bottom: -2rem;
    @media (max-width: $computer) {
      margin-top: 4rem;
      margin-bottom: 4rem; }
    h1 {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bolder !important;
      @media (max-width: $computer) {
        flex-direction: column; }
      svg {
        font-size: 2rem;
        @media (min-width: $computer) {
          margin-left: 1rem; } }
      svg:first-child {
        margin-left: 0;
        @media (min-width: $computer) {
          margin-right: 1rem; } }
      svg:last-child {
        display: none;
        @media (min-width: $computer) {
          display: block; } } }
    .liClass {
      @media (max-width: $mobile) {
        padding-left: 0; } }
    p {
      color: #FFFFFF;
      font-weight: bold;
      @media (max-width: $mobile) {
        font-size: 17px;
        padding-left: 0; } }
    .dataIntroductionIcon {
      color: #ffffff;
      @media (max-width: $mobile) {
        margin-right: 0; }
      svg {
        font-size: 2.25rem; } }

    @media (max-width: $mobile) {
      margin-right: 0;
      padding-right: 0; } }


  .weHelpYouSection {
    display: flex;
    justify-content: space-around;
    background-color: #4FA9B3;
    width: 100%;
    margin-bottom: -2px;

    p {
      text-align: center;
      color: #ffffff; }

    .documentsButtonContainer {
      margin-top: 1em;
      display: flex !important;
      justify-content: center !important;

      .downloadDocumentButton {
        background-color: #ffcf2f !important; }

      .documentsDownloadIcon {
        margin-right: 0.2em; } } }

  .peopleContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 0 10px;
    color: #ffffff;
    position: relative;
    margin-top: -4px;
    p {
      max-width: 800px;
      @media (max-width: $tablet) {
        max-width: 400px; } }

    .fondoOlasImgs {
      position: absolute; }

    .peopleContainerContent {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 1rem;
      max-width: 90vw; }


    .peopleIconContainer {
      display: flex;

      .peopleIconContent {
        display: flex;
        flex-direction: column;
        align-items: center; }

      p {
        @media (max-width: $mobile) {
          text-align: center; } }

      .peopleIconContent:hover {
        cursor: pointer; }

      .peopleIconLeft {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 50px; }

      .peopleIconRigth {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; } } }

  .whyContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      color: $color-primary-1; }


    .whyList {
      list-style-type: none;
      text-align: left;
      font-size: $large-font-size;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-weight: bold;
      @media (max-width: $tablet) {
        font-size: $medium-font-size;
        padding-left: 0;
        padding-right: 20px;
        margin-left: -20px; }

      li {
        display: flex;
        align-items: center; } }

    .whyArrowImg {
      transform: rotate(45deg); } }

  .ownBraceletsContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    .braceletsContainerImg {
      display: flex;
      align-items: center;

      .braceletsContainerImgBracelet {
        animation: floatBracelet 6s ease-in-out infinite;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 250px;
        @media (max-width: $mobile) {
          margin-bottom: 50px; }

        img {
          height: 130px;
          @media (max-width: $tablet) {
            width: 210px; } }

        p {
          color: $primary-color-hover;
          font-size: $big-font-size; } }

      .braceletsContainerImgHandQR {
        width: 150px;
        transform: rotate(-10deg);
        margin-left: -2rem;
        @media (max-width: $mobile) {
          margin-left: -5rem; } } }

    .braceletsContainerImgArrowLeft {
      margin-top: -200px;
      margin-left: 70px;
      @media (max-width: $tablet) {
        display: none; } }

    .braceletsContainerImgArrowMobile {
      margin-top: -90px;
      margin-left: 70px;
      @media (min-width: $tablet) {
        display: none; } }

    .content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      @media (max-width: $tablet) {
        flex-direction: column;
        align-items: center; } }

    .contentVideo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      @media (max-width: $tablet) {
        flex-direction: column-reverse;
        align-items: center; } }

    .ownBraceletsTextContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 600px;
      @media (max-width: $tablet) {
        padding: 0 20px; }

      h1 {
        color: $color-primary-1; } }


    .iframeVideo {
      width: 600px;
      height: 350px;
      @media (max-width: $mobile) {
        width: 85%;
        height: 200px;
        margin-bottom: 20px; } } }

  .historyContainer {
    display: flex;
    background-color: $secondary-color-off;
    @media (max-width: $mobile) {
      flex-direction: column; }

    .historyContainerContent {
      max-width: 65%;
      padding: 10px 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: $mobile) {
        max-width: 100%;
        padding: 0 20px; }

      h1 {
        color: $color-primary-1; }


      p {
        @media (min-width: $computer) {
          max-width: 65%; } } }

    .historyImageContainer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media (min-width: $tablet) {
        margin-right: 15px; }

      img {
        width: 100%;
        height: auto;
        @media (min-width: $tablet) {
          margin-top: 3em;
          margin-bottom: 3em;
          border-radius: 25px; } } } }

  .enviromentContainer {
    display: flex;
    justify-content: center;
    background-color: $secondary-color;
    @media (max-width: $mobile) {
      padding-top: 40px; }

    .enviromentContainerContent {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      @media (max-width: $mobile) {
        flex-direction: column-reverse; } }

    .enviromentTextContainer {
      max-width: 65%;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-right: 20px;
      align-items: center;
      @media (max-width: $mobile) {
        max-width: 100%; }

      h1 {
        color: $color-primary-1; }


      p {
        @media (min-width: $computer) {
          max-width: 70%; } } }

    .enviromentImageContainer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media (min-width: $tablet) {
        margin-left: 15px; }

      img {
        width: 100%;
        height: auto;
        @media (min-width: $tablet) {
          border-radius: 25px;
          margin-top: 3em;
          margin-bottom: 3em; } } } }

  .sampleContainer {
    display: flex;
    justify-content: space-around;
    padding: 0 10px 40px 10px;
    width: 100%;
    margin-top: 0.25rem;
    flex-direction: row;
    @media (max-width: $mobile) {
      flex-direction: column; }

    .contentMock {
      display: flex;
      justify-content: center;
      position: relative;

      .sampleImg {
        height: 350px;
        margin-right: -35px;
        align-self: flex-end;
        padding-right: 1em;
        @media (min-width: $tablet) {
          height: 400px;
          margin-right: 20px;
          padding-right: 0.5em; }
        @media (min-width: $computer) {
          height: 500px;
          padding-right: 4em; } }
      .sampleImg:hover {
        cursor: pointer; }

      .mockStepsView {
        height: 325px;
        margin-right: -35px;
        z-index: 30;
        align-self: flex-end;
        @media (min-width: $tablet) {
          height: 350px;
          margin-right: -2em; }
        @media (min-width: $computer) {
          height: 450px;
          margin-right: -2.50em; } }
      .mockStepsView:hover {
        cursor: pointer; }

      .mockContainerImg {
        position: absolute;
        width: 250px;
        bottom: -8%;
        left: -20%;
        z-index: 50;
        @media (max-width: $tablet) {
          width: 160px;
          bottom: -6%;
          left: -17%; }
        @media (max-width: $mobile) {
          left: 0; } } }


    .contentList {
      margin-right: 40px;
      @media (max-width: $mobile) {
        margin-right: 20px;
        margin-left: 20px; }

      h1 {
        color: $color-primary-1;

        @media (min-width: $tablet) {
          padding-left: 66px; } }

      .content {
        display: flex;
        justify-content: space-around; }

      .sampleList {
        list-style-type: none;
        text-align: left;
        font-size: $large-font-size;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-weight: bold;
        @media (max-width: $tablet) {
          font-size: $medium-font-size;
          padding-left: 0; }

        li {
          display: flex;
          align-items: center; } } } }
  .rateUsContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: $color-primary-1;

    padding-top: 1rem;
    padding-bottom: 5rem;
    margin-bottom: -2px; //fix white line between sections
    @media (min-width: $computer) {
      padding-top: 3rem;
      padding-bottom: 4rem; } }

  .rateUsCopy {
    margin: 2rem; }
  .rateIconItem {
    display: flex;
    justify-content: center;
    .rateIconButton {
      background-color: rgba(255, 255, 255, 0.89); }
    .rateIcon {
      width: 3.5rem; } }

  .reviewsContainer {
    display: flex;
    justify-content: center;
    width: 100%;
    background-color: $color-primary-1;

    padding-top: 4rem;
    margin-bottom: -2px; } //fix white line between sections

  .reviewGridItem {
    margin: 1rem;
    button {
      font-size: $big-font-size;
      background-color: transparent;
      color: $secondary-color;
      border: none;
      padding: 10px 20px;
      border-radius: 6px;
      cursor: pointer; }

    .reviewContainerContent {
      display: flex;
      align-items: center;
      flex-direction: column;

      .reviewHead {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        color: #ffffff; }

      .reviewLogo {
        width: 150px;
        height: 150px;
        object-fit: cover;
        background-color: #ffffff;
        border-radius: 50%;
        @media (max-width: $tablet) {
          width: 80px;
          height: 80px; } }

      .reviewDataContainer {
        display: flex;
        color: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        text-align: start;
        @media (max-width: $tablet) {
          max-width: 500px;
          margin-top: 20px;
          text-align: justify; }

        .reviewTitle {
          margin-top: 1rem;
          color: #ffffff;
          text-align: center; }

        .starsContainer {
          display: flex;
          align-items: center; }

        a {
          text-decoration: underline;
          color: white;
          margin-bottom: 10px; }

        p {
          font-weight: bold;
          font-size: $medium-font-size;
          margin-bottom: 8px; }

        h6 {
          @media (max-width: $tablet) {
            max-width: 200px;
            text-align: start;
            margin: 10px 0 0; } } } } }

  .newsContainer {
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    width: 100%;

    button {
      font-size: $big-font-size;
      background-color: transparent;
      color: $secondary-color;
      border: none;
      padding: 10px 20px;
      border-radius: 6px;
      cursor: pointer; }

    .newsContainerContent {
      display: flex;
      align-items: center;
      background-color: $color-primary-1;

      padding-bottom: 3rem;
      min-height: 20rem;
      padding-left: 150px;
      padding-right: 150px;
      @media (max-width: $tablet) {
        padding-right: 10px;
        padding-left: 10px;
        flex-direction: column;
        min-height: 27rem; }

      .newsLogo {
        width: 150px;
        height: 150px;
        object-fit: contain;
        background-color: #ffffff;
        border-radius: 50%;
        @media (max-width: $tablet) {
          width: 50px;
          height: 50px;
          position: absolute;
          margin: 15px 0 0 90px; } }

      .newsDataContainer {
        padding-left: 50px;
        display: flex;
        color: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        text-align: start;
        @media (max-width: $tablet) {
          max-width: 500px;
          margin-top: 20px;
          margin-right: 50px;
          text-align: justify; }

        a {
          text-decoration: underline;
          color: white;
          margin-bottom: 10px; }


        p {
          font-weight: bold;
          font-size: $medium-font-size;
          margin-bottom: 8px;
          margin-top: 30px; }

        h2 {
          @media (max-width: $tablet) {
            max-width: 200px;
            text-align: start;
            margin: 10px 0 0; } } } } }

  .footerContainer {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media (max-width: $tablet) {
      align-items: center; }

    .sevi {
      max-width: 85%;
      cursor: pointer;
      align-self: center;
      color: $complementary-concrete-color;
      margin-top: 20px;
      text-align: center; }

    .codeModal {
      cursor: pointer;
      align-self: center;
      margin: 10px;
      text-align: center;
      text-decoration: underline; }

    .footerContent {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center;
      @media (max-width: $tablet) {
        flex-direction: column; }

      .footerLawsContainer {
        display: flex;
        margin: 0.5rem;
        p {
          margin: 0.25rem;
          font-size: $small-font-size;
          color: $complementary-concrete-color; } }

      .seviLogo {
        width: 150px; }

      .footerLogosContainer {
        display: flex;
        justify-content: space-around;
        align-items: center;
        width: 25rem;
        margin-top: 2rem;
        @media(max-width: $mobile) {
          flex-direction: column; }
        img {
          margin: 1rem; } }
      .footerContentServices {
        display: flex;
        flex-direction: column;

        div {
          max-width: 400px; } } } } }

.modalInfoHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px; }

.modalInfoContent {
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: $tablet) {
    flex-direction: column; }

  li {
    font-size: $large-font-size;
    margin-top: 10px; }

  .imageHand {
    width: 15rem;
    height: 15rem; }

  .imageKid {
    width: 11rem;
    height: 22rem; } }

.seeMoreText {
  text-decoration: underline; }

.cookiesLayer {
  width: 100%;
  height: 100%;
  background: transparentize(gray, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 6000;

  .snackbar {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 6rem;
    width: 100%;
    height: 22rem;
    font-size: 1.2rem;
    text-align: center;
    @media (max-width: $computer) {
      width: 75%;
      padding: 2rem; }

    .moreInfoCookieText {
      color: white;
      margin: 5px; }

    button {
      background-color: transparent;
      color: white;
      border: none; } } }

.finalImgContainer {
  position: relative;
  margin: 0;

  .asterisco {
    position: absolute;
    bottom: 2px;
    font-size: $small-font-size !important;
    color: $secondary-color-off;
    right: 20%;
    @media (max-width: $tablet) {
      right: 41%; } } }

.cookiesMoreInfoButton {
  background-color: $color-primary-1 !important; }

.cookiesMoreInfoButton:hover {
  background-color: $primary-color-hover !important; }

.privacy {
  cursor: pointer; }

.privacy:hover {
  text-decoration: underline; }

.shareButtonsBox {
  padding: 2em;
  padding-bottom: 1em !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly; }

.shareButton:hover {
  cursor: pointer; }

.floatingButtonGetIt, .floatingButtonGetItLogged {
  position: fixed !important;
  bottom: 1em !important;
  right: 1em !important;
  box-shadow: 3px 7px 20px 0px black;
  color: $primary-color-hover !important;
  background-color: $color-secondary-1-0  !important;
  z-index: 10;
  @media (min-width: $tablet) {
    display: none !important; } }

.floatingButtonGetItLogged {
  bottom: 5em !important; }

.floatingButtonGetItIcon {
  margin-right: 0.3em; }

.companiesTrustContainer {
  margin-top: 3em !important;
  //position: absolute
  //bottom: -5rem
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: $tablet) {
    padding-bottom: 5em;
    flex-direction: column; }

  .companiesTrustContent {
    display: flex;
    align-items: center; }

  .companiesTrustLogoEnisa {
    width: 150px;
    margin-right: 1.25rem;
    height: auto;
    @media (max-width: $mobile) {
      max-height: 8rem;
      margin-right: 0;
      width: 55px; } }

  .companiesTrustLogo {
    width: 190px;
    height: auto;
    // height: 150px
    @media (max-width: $mobile) {
      max-height: 8rem;
      width: 105px; } } }
//height: 75px

.companiesTrustClaim {
  color: #ffffff !important;
  font-size: $huge-font-size !important;
  text-align: center !important;
  padding-bottom: 6rem !important;
  margin-top: 1rem !important;
  min-width: 75%;
  max-width: 90%;
  border-bottom: 0.25rem solid #fff;
  @media (max-width: $mobile) {
    min-width: 90% !important;
    max-width: 90% !important;
    font-size: $big-font-size !important;
    padding-bottom: 2.25rem !important;
    margin-bottom: 1rem !important; } }


.floatingActiveBraceletButton {
  position: fixed !important;
  border-radius: 25px !important;
  background-color: $color-secondary-1-0  !important;
  color: $primary-color-hover !important;
  bottom: 1em;
  right: 1em;
  box-shadow: 3px 7px 20px 0px rgba(0, 0, 0, 1); }


.activeIcon {
  color: $primary-color-hover !important;
  margin-right: 0.5em !important; }

.FbLikeButtonContainer {
  padding-top: 1em;
  @media (min-width: $tablet) {
    padding-top: 3em; } }


.facebookLikeButton {
  display: flex !important;
  align-items: center !important;
  margin-top: 2em !important;
  padding-right: 1.5em !important;
  font-size: $micro-font-size !important;
  background-color: #3C5A99 !important;
  border-radius: 6px;
  @media (max-width: $tablet) {
    display: none !important; }
  @media (min-width: $tablet) {
    font-size: $tiny-font-size !important;
    padding-right: 1.7em !important; } }

.facebookLike {
  height: 30px;
  width: 30px;
  @media (min-width: $tablet) {
    height: 45px;
    width: 45px; } }


.pulsidHeadImage {
  display: none;
  height: 100px;
  width: 135px;
  @media (max-width: $tablet) {
    display: block; } }

.pulsidPriceBadgeDesktop {
  min-width: 4rem;
  min-height: 4rem;
  background-color: #c3dd36;
  font-size: large !important;
  font-weight: bold;
  @media (max-width: $tablet) {
    display: none !important; } }

.pulsidPriceBadgeAlways {
  min-width: 4rem;
  min-height: 4rem;
  background-color: #c3dd36;
  font-size: large !important;
  font-weight: bold; }

.pulsidPriceBadge {
  display: none !important;
  @media (max-width: $tablet) {
    display: block !important; }

  span:last-child {
    min-width: 3rem;
    background-color: #c3dd36 !important;
    font-size: medium;
    font-weight: bold;
    min-height: 3rem; } }

.discountSticker {
  position: absolute;
  right: -25px;
  top: -25px;
  border-radius: 50%;
  font-size: $small-font-size;
  padding: 1.3em 1em;
  background-color: #ff5a5a;
  color: #ffffff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12); }

.offerSnackbar {
  width: 100%;
  max-width: 100% !important;
  text-align: center;
  font-size: $medium-font-size !important;

  div:first-child {
    width: 100%;
    max-width: 100% !important;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    font-size: $medium-font-size !important; } }


.snackOfferButton {
  background-color: $primary-color-hover;
  color: $secondary-color; }

.offerBanner {
  position: fixed;
  display: flex;
  z-index: 55;
  bottom: 0;
  width: 100%;
  padding: 1em;
  text-align: center;
  font-size: $large-font-size;
  background-color: #ff0012;
  color: white;
  @media (max-width: $tablet) {
    flex-direction: column;
    display: none; } }

.offerBannerMobile {
  position: fixed;
  display: none;
  z-index: 55;
  bottom: 0;
  width: 100%;
  padding: 1em;
  text-align: center;
  font-size: $large-font-size;
  background-color: #FF0012;
  color: white;
  @media (max-width: $tablet) {
    display: flex;
    justify-content: space-around; } }

.closeOfferBanner {
  position: absolute;
  right: 25px;
  top: 8px;
  @media (max-width: $tablet) {
    position: absolute;
    right: 5px;
    top: 5px; } }

.offerBannerCountdown {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: $tablet) {
    margin-top: 1.4em !important; } }

.offerBannerCountdown, .offerClaim {
  @media (max-width: $tablet) {
    display: flex;
    flex-direction: column; } }

.offerClaim {
  span:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: $tablet) {
      display: inline-flex !important; } } }

.offerButtonBox {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: $tablet) {
    margin-top: 1em !important; } }

.offerBannerGetItButton {
  background-color: #00B32C !important;
  color: #ffffff !important; }

.closeOfferBannerIcon {
  color: #ffffff; }

.whatsappPostSection {
  display: flex;
  justify-content: space-around;
  background-color: #74a7b1;
  width: 100%;
  padding-top: 2rem;
  margin-bottom: -2px;
  color: #fff; }

.whatsappPostClaim {
  text-align: center !important; }

.whatsappPostItem {
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 2rem !important;

  .downloadDocumentButton {
    background-color: $secondary-color; } }

.whatsappPostImage {
  height: 250px; }

.newsletterSectionGrid {
  padding-top: 0;
  padding-bottom: 2rem;
  margin-top: -4px;
  margin-bottom: -4px;
  background-color: #ffffff;
  @media (min-width: $tablet) {
    background-color: $primary-color;
    padding-top: 2rem;
    padding-bottom: 2rem; } }

.freeShippingClaim {
  color: #ffffff;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: $large-font-size;
  font-family: 'Roboto Bold', sans-serif;
  padding-left: 0.4rem;
  padding-right: 1.2rem;
  .countDown {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
    font-family: 'Roboto Bold', sans-serif !important;
    font-size: $medium-font-size;
    @media (min-width: $tablet) {
      margin-left: 1rem;
      margin-right: 1rem;
      font-size: $big-font-size; } }

  span {
    font-family: 'Roboto Bold', sans-serif !important;
    font-size: $large-font-size;
    @media (min-width: $tablet) {
      font-size: $big-font-size; } } }

.CTAContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em !important;


  .tapIcon {
    margin-left: 0.5rem;
    font-size: 2rem;
    transform: rotate(-25deg); }

  .primaryCTAButton {
    color: #ffffff;
    background: #FF6966;
    //border: 2px solid
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    padding: 1em 2.5em;
    border-radius: 10px;
    cursor: pointer;
    font-size: $big-font-size;
    @media (max-width: $mobile) {
      font-size: $large-font-size; } }
  .primaryCTAButton:hover {
    background-color: #ea605d; }
  .secondaryCTAButton {
    color: $color-primary-1 !important;
    border: 2px solid !important;
    padding: 1em 2.5em !important;
    border-radius: 10px !important;
    cursor: pointer !important;
    font-size: $big-font-size !important;
    @media (max-width: $mobile) {
      font-size: $large-font-size !important; } } }

.buyedClaimContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 0.25rem !important;
  @media (min-width: $computer) {
    margin-bottom: 1.25rem !important; } }

.buyedClaimBox, .buyNowClaimBox, .womenDayBanner {
  width: 100%;
  height: 2.5rem;
  color: #000;
  background-color: #ffffff;
  font-size: $medium-font-size;
  text-align: center;
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-top: 5rem;
  @media (min-width: $tablet) {
    font-size: $large-font-size;
    margin-right: -1rem; //to fix non fullwidth banner on desktop limit by grid
    margin-left: -1rem; } }
.buyNowClaimBox {
  background-color: #FF6966; }
.womenDayBanner {
  background-color: #d228ac; }
.countUp {
  margin-top: 3rem !important;
  color: #fff;
  font-size: $bigger-font-size !important;
  text-align: center;
  @media (min-width: $tablet) {
    margin-top: 6rem !important; } }

.footerVideo {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 3rem;
  @media (min-width: $computer) {
    width: 50%;
    box-shadow: 0 0 15px 5px rgba(0,0,0,0.13); } }


.navidadCollage {
  width: 11rem;
  margin-top: 2rem;
  @media (min-width: $computer) {
    margin-top: 6rem;
    width: 14rem; } }
