$color-primary-1: #50a7b4;
// Main Primary color */
$color-primary-2: #B1E3EA;
$color-primary-3: #7BC6D1;
$color-primary-4: #318B98;
$color-primary-5: #177D8B;

$color-secondary-1-0: #fff8e3;	// Main Secondary color (1) */
//$color-secondary-1-0: #FFD16F	// Main Secondary color (1) */
$color-secondary-1-1: #FFEBC0;
$color-secondary-1-2: #FFDD94;
$color-secondary-1-3: #F8C24D;
$color-secondary-1-4: #E2A623;

$color-secondary-2-0: #FF796F;	// Main Secondary color (2) */
$color-secondary-2-1: #FFC4C0;
$color-secondary-2-2: #FF9C94;
$color-secondary-2-3: #F8594D;
$color-secondary-2-4: #E23123;

$color-complement-0: #FFB46F;	// Main Complement color */
$color-complement-1: #FFDEC0;
$color-complement-2: #FFC794;
$color-complement-3: #F89F4D;
$color-complement-4: #E27F23;



// As RGBa codes */

$rgba-primary-0: rgba( 80,167,180,1);	// Main Primary color */
$rgba-primary-1: rgba(177,227,234,1);
$rgba-primary-2: rgba(123,198,209,1);
$rgba-primary-3: rgba( 49,139,152,1);
$rgba-primary-4: rgba( 23,125,139,1);

$rgba-secondary-1-0: rgba(255,209,111,1);	// Main Secondary color (1) */
$rgba-secondary-1-1: rgba(255,235,192,1);
$rgba-secondary-1-2: rgba(255,221,148,1);
$rgba-secondary-1-3: rgba(248,194, 77,1);
$rgba-secondary-1-4: rgba(226,166, 35,1);

$rgba-secondary-2-0: rgba(255,121,111,1);	// Main Secondary color (2) */
$rgba-secondary-2-1: rgba(255,196,192,1);
$rgba-secondary-2-2: rgba(255,156,148,1);
$rgba-secondary-2-3: rgba(248, 89, 77,1);
$rgba-secondary-2-4: rgba(226, 49, 35,1);

$rgba-complement-0: rgba(255,180,111,1);	// Main Complement color */
$rgba-complement-1: rgba(255,222,192,1);
$rgba-complement-2: rgba(255,199,148,1);
$rgba-complement-3: rgba(248,159, 77,1);
$rgba-complement-4: rgba(226,127, 35,1);
