@import "../../styles/colors";
@import "../../styles/palette";
@import "../../styles/typography";
@import "../../styles/responsive";

.formControl {
  margin-top: 8px;
  min-width: 120px; }

.flexColumn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  button {
    background-color: $color-primary-1;

    color: white; }
  button:hover {
    background-color: $primary-color-hover;
    color: white; }
  .photo {
    margin-top: 15px;
    border-radius: 15px;
    background: url(../../img/loading.gif) 50% no-repeat;
    height: auto;
    width: auto;
    min-width: 75px;
    min-height: 75px;
    max-width: 500px;
    max-height: 500px;
    @media (max-width: $mobile) {
      max-height: 400px;
      max-width: 300px; } }
  .stepNextButton {
    position: absolute !important;
    top: 50% !important;
    right: 5px !important; }
  .stepBeforeButton {
    position: absolute !important;
    top: 50% !important;
    left: 5px !important; } }
.closeModalIcon {
  position: absolute!important;
  right: 5px!important;
  top: 5px!important; }

.textField {
  label {
    color: $color-primary-1 !important; }
  div {
    fieldset {
      border-color: $color-primary-1 !important; } } }

