@import "../../../styles/colors";
@import "../../../styles/palette";

@import "../../../styles/typography";
@import "../../../styles/responsive";


.mainDialogContent {
  background: #f8f8f8;

  @media(min-width: $computer) {
    border-radius: 8px;
    margin: 0 11rem;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important; } }

.parentContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  flex-wrap: inherit !important; }
.termsContainer {
  display: flex;
  flex-direction: column;
  align-items: center; }


.formControl {
  margin-top: 8px;
  min-width: 120px;
  display: flex!important;
  label {
    color: $color-primary-1 !important; }
  div {
    fieldset {
      border-color: $color-primary-1 !important; } }
  svg {
    color: $color-primary-1 !important; } }
.checkbox {
  color: $color-primary-1 !important; }

.countryGridItem {
  visibility: hidden;

  .formRedsys {
    width: 100%; }
  //button
  //  background-color: $color-primary-1

  //  color: white
  //button:hover
  //  background-color: $primary-color-hover
  //  color: white
  //button:disabled
  //  background-color: white
 }  //  color: $primary-color

.cityZipcodeContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }


.privacyLink {
  color: $complementary-concrete-color; }

.privacyLink:hover {
  color: #0077b5;
  text-decoration: underline; }

.closeModalIcon {
  position: absolute !important;
  right: 5px !important;
  top: 5px !important; }

.fixModalPadding {
  padding: 0 !important; }

.paymentModal {
  padding: 0 !important;
  [class^="MuiPaper-root"] {
    min-width: 350px; }
  .paymentModalTitleBox {
    display: flex;
    align-items: center;
    margin-top: -20px; } }

.sendingDataAnimation {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.textField {
  label {
    color: $color-primary-1 !important; }
  div {
    fieldset {
      border-color: $color-primary-1 !important; } } }

.textFieldError {
  label {
    color: $error-color !important; }
  div {
    fieldset {
      border-color: $error-color !important; } } }

.contentText {
  margin-bottom: 10px !important; }
.contentInnerText {
  margin-left: 8px !important;
  font-size: 2.5rem !important;
  align-self: start !important; }

.formContainer {
  display: flex;
  flex-direction: column!important;
  flex-wrap: wrap;
  justify-content: space-evenly; }

.sizeCardContainer {
  display: flex;
  width: 100%;
  flex-direction: row-reverse;
  justify-content: space-around;
  @media (max-width: $mobile) {
    flex-direction: column-reverse;
    margin-top: 10px !important; }
  .sizeCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 15px;
    margin: 0.75em;
    @media (max-width: $mobile) {
      margin: 1.00em;
      padding: 1em; }
    .sizeCardImg {
      width: 250px;
      margin: 1em !important;
      @media (max-width: $mobile) {
        width: 200px; } }
    .sizeCardSize {
      display: flex;
      flex-direction: column;
      align-items: center;
      color: $primary-color-hover;
      .braceletSizeBox {
        display: flex;
        align-items: center;
        flex-direction: row;
        color: $primary-color-hover;
        margin-top: 5px;
        margin-bottom: 10px; }
      .braceletSizeCode {
        margin-top: 10px; }
      svg {
        font-size: $small-font-size; } }
    p {
      color: $primary-color-hover;
      font-weight: bold; }
    .sizePicker {
      margin: 1em;
      label {
        font-size: $small-font-size;
        margin-left: -3px; } } }
  .braceletsSelectorGrid {
    display: flex;
    flex-direction: row;
    align-items: center;
    @media (max-width: $mobile) {
      flex-direction: column-reverse; } } }

.sizeSelectorActions {
    display: flex; }
.sizeSelectorMobileButton {
  @media (min-width: $tablet) {
    display: none !important; } }
.sizeStock {
  color: red !important;
  margin: 0;
  padding-bottom: 0.5rem; }

.sizeCardContainerCart {
  justify-content: center;
  margin-bottom: 1em !important;
  .sizeCardCart {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-left: solid 5px $primary-color;
    @media (min-width: $mobile) {
      padding: 0 1rem;
      margin: 1rem; }
    @media (max-width: $mobile) {
      width: 100% !important; }
    .sizeCardImg {
      width: 100px;
      margin: 1em !important;
      @media (max-width: $mobile) {
        width: 90px; } }
    p, span {
      color: $primary-color-hover;
      font-weight: bold;
      margin: 5px; }
    span {
      margin-top: 5px; }
    .productImageBox {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; }
    .productDetails {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center; } } }
.cartItem {
  display: flex !important;
  justify-content: center;
  padding: 10px 0 !important; }

.discountContainer {
  display: flex;
  align-items: center;
  .discountButton {
    margin: 10px;
    border: 1px solid $primary-color;
    color: $color-primary-1 !important; }
  .discountButton:disabled {
    border: 1px solid #aaaaaa;
    color: #e0e0e0 !important; } }
.snackTotalBraceletsMessage {
  font-weight: bold; }

.snackTotalBracelets {
  padding: 15px;
  button {
    background-color: transparent;
    color: white;
    border: none;
    cursor: pointer; }
  span {
    background-color: darkorange; }
  a {
    color: #fff; }
  a:hover {
    color: blue; } }

.packagingSection {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center; }
.packagingCheck {
  align-self: flex-start !important; }
.packagingCheckLabel {
  color: #716b77 !important; }
.packagingQuantitySelector {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 75%;
  //justify-content: space-evenly
  padding: 5px;
  @media (max-width: $mobile) {
    width: 75%; } }
.packagingImgContainer {
  display: flex;
  align-items: center;
  justify-content: center; }
.packagingImg {
  width: 270px;
  height: auto;
  border-radius: 15px;
  margin: 1em !important;
  @media (max-width: $mobile) {
    height: auto;
    width: 220px; } }
.packagingInput {
  width: 100px; }
.packagingCartImg {
  margin-top: 0.5em;
  width: 100%;
  border-radius: 15px;
  @media (max-width: $mobile) {
    width: 150px; } }

.priceChip {
  margin-bottom: 1em;
  margin-top: 0.5em;
  font-size: 1.5rem !important; }
.totalPriceChip {
  padding: 22px !important;
  font-size: 2rem !important;
  color: #ffffff !important;
  background-color: $color-primary-1 !important;
  margin-left: 1em; }
.oldPriceChip {
  margin-bottom: 1em;
  margin-top: 0.5em;
  text-decoration: line-through !important; }
.totalPriceWrapper {
  margin-top: 1em;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding-right: 1rem;
  flex-direction: column; }
.parentShippingPriceBox {
  display: flex;
  justify-content: center;
  align-items: center; }
.shippingPriceBox {
  background-color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-left: 5px solid #CCBD88;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  h6 {
    text-align: center; }
  .centerContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    text-align: center; } }


.offerCard {
  margin: auto;
  width: 75%;
  flex-direction: column;
  padding: 1em !important;
  text-align: center !important;
  border-radius: 50px !important;
  margin-bottom: 0.5em !important;
  margin-top: 0.5em !important;
  background-image: linear-gradient(-20deg, #fdbeb0 0%, #fdf8bd 100%);
  @media (min-width: $tablet) {
    width: 25%; } }

.dialogActions, .dialogActionsFirstStep {
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  padding: 0.5em;
  box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
  margin: 0 !important;
  z-index: 300;
  text-align: center; }


.dialogActionButton {
  padding: 0.5em 5em !important;
  margin: 5px !important;
  border-radius: 1em !important;
  color: #ffffff !important;
  background-color: $color-primary-1 !important; }

.dialogActionButton:disabled {
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  background-color: initial !important;
  color: #808080 !important; }

.disabledButton {
  padding: 0.5em 5em !important;
  margin: 5px !important;
  border-radius: 1em !important;
  border: 1px solid rgba(0, 0, 0, 0.23) !important;
  background-color: transparent !important;
  color: #808080 !important;
  box-shadow: none !important;
  cursor: default !important; }

.loadingPaymentActionsModal {
  [class^="MuiPaper-root"] {
    //min-height: 300px !important
    transition: height .45s ease-in-out !important; } }

.redsysAnimationContainer {
  transition: opacity .45s ease-in-out !important;
  opacity: 0;
  text-align: center; }

@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  .cityZipcodeContainer {
    display: flex;
    background-color: #4fb457;
    flex-direction: column;
    justify-content: space-between; } }

.shippingPriceSelector {}
