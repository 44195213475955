@font-face {
    font-family: 'Roboto Medium';
    src: url('fonts/Roboto-Medium.ttf') format('ttf');
}
@font-face {
    font-family: 'Roboto';
    src: url('fonts/Roboto-Bold.ttf') format('ttf');
}

html, body, #app, #app>div, li, span, p, input, a, h2, h3, h4, h5, h6, textarea, legend, option, div {
  /*height: 100%*/
    /*font-family: blogger-sans, serif !important;*/
    font-family: 'Roboto', sans-serif !important; ;

}

