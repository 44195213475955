@import "../../../styles/colors";
@import "../../../styles/palette";

@import "../../../styles/typography";
@import "../../../styles/responsive";

.card {
  width: 80%;

  @media (max-width: $tablet) {
    width: 95%; }

  .actions {
    justify-content: flex-end; }

  .assignContainer {
    display: flex;
    align-items: center;
    padding: 0 20px 20px;
    justify-content: flex-end;
    @media (max-width: $mobile) {
      justify-content: center; }

    .assignButton {
      text-transform: none;
      background-color: $color-primary-1;

      border: none;
      border-radius: 6px;
      font-size: $large-font-size;
      padding: 5px 10px;
      color: #fff;
      cursor: pointer; }

    .assignButton:hover {
      background-color: $primary-color-hover !important; } }

  .cardDataContainer {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    @media (max-width: $tablet) {
      flex-direction: column; }

    .ListItemIcon {
      div {
        display: flex;
        align-items: center;

        svg {
          margin-right: 5px; } } }

    li {
      justify-content: space-between;

      h3 {
        margin: 0; } }

    .responsiveRow {
      @media (max-width: $tablet) {
        flex-direction: column;
        align-items: flex-start; } } }

  .avatar {
    flex: 0 0 auto;
    margin-right: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: $tablet) {
      margin-right: 0; } }

  .avatarContainer {
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    margin-top: 10px;

    div {
      margin: 10px 0 0; }

    .logoImg {
      width: 150px;
      margin-bottom: 10px; }

    .visibilityIcon {
      color: $color-primary-1;

      font-size: 35px;
      margin-top: 10px;
      visibility: hidden;
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: 0;
      font-size: 45px;
      z-index: 10;
      top: 35%;
      -ms-transform: translateY(-35%);
      transform: translateY(-35%);
      -webkit-filter: drop-shadow(1px 1px 5px black);
      /* Chrome, Safari, Opera */
      filter: drop-shadow(1px 1px 5px black); } }


  @media (min-width: $tablet) {
    .visibilityIcon {
      visibility: visible;
      z-index: 10; }
    .avatarContainer:hover {
      position: relative;
      display: flex;
      align-items: center;
      flex-direction: column;
      cursor: pointer;

      .qrImage {
        -webkit-transition: all .15s ease;
        -moz-transition: all .15s ease;
        -o-transition: all .15s ease;
        transition: all .15s ease;
        -webkit-filter: blur(3px);
        /* Chrome, Safari, Opera */
        filter: blur(3px); }

      div {
        margin: 10px 0 0; }

      .logoImg {
        width: 150px;
        margin-bottom: 10px; }

      .visibilityIcon {
        visibility: visible;
        position: absolute;
        margin-left: auto;
        margin-right: auto;
        color: #fff9e4;
        left: 0;
        right: 0;
        z-index: 10;
        top: 35%;
        -ms-transform: translateY(-35%);
        transform: translateY(-35%);
        -webkit-filter: drop-shadow(1px 1px 5px black);
        /* Chrome, Safari, Opera */
        filter: drop-shadow(1px 1px 5px black); } } }

  .avatarBraceletContainer {
    display: flex;
    align-items: center;
    flex-direction: column;

    div {
      margin: 10px 0 0; }

    .logoImg {
      width: 128px; } }


  .cardHeader {
    width: 88%;
    display: flex;
    @media (max-width: $mobile) {
      flex-direction: column; } }

  .cardHeaderTitle {
    text-align: start;
    font-size: $medium-font-size+2 !important;
    font-family: Blogger-sans !important;
    @media (max-width: $mobile) {
      text-align: center;
      margin-top: 5px; } }

  .subheaderCardText {
    font-size: $medium-font-size+2 !important;
    @media (max-width: $mobile) {
      text-align: center; } } }

.paddedList {
  padding-left: 15px; }


.ListIcon {
  color: #000000; }

.contactDataContainer {
  position: relative;

  .contactDataEnable {
    position: absolute;
    right: 0;
    bottom: 10px; } }

.documentTypeText {
  min-width: 55px; }

.documentViewPhotos {
  font-size: $tiny-font-size;
  color: $color-primary-1;

  font-weight: bold;
  cursor: pointer;
  @media (min-width: $tablet) {
    margin-left: 2em; } }

.documentSecondaryBox {
  display: flex;
  @media (max-width: $mobile) {
    flex-direction: column; } }

.gridStepsContainer {
  background-color: #f7f7f7 !important;
  border-radius: 25px !important;
  margin-bottom: 2em !important; }

.gridContainer {
  background-color: #f7f7f7 !important;
  border-radius: 25px !important;
  display: flex;
  flex-direction: row; }

.relationshipText {
  color: #9a9a9a;
  font-size: 12px;
  margin-left: 5px; }

.subheaderText {
  font-weight: bold;
  font-size: 16px; }

.braceletStateActions {
  display: flex !important;
  align-items: center !important;
  justify-content: flex-end !important;
  margin-top: 1em !important;
  flex-direction: column !important;
  @media (min-width: $tablet) {
    flex-direction: row !important; } }


.deleteButtons {
  @media (max-width: $tablet) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; } }
.customIcon {
  width: 24px; }

.addButton, .saveButton {
  margin-top: 15px;
  background-color: $color-primary-1 !important; }
.addButton:hover, .saveButton:hover {
  background-color: $primary-color-hover !important; }
.addButton:disabled, .saveButton:disabled {
  background-color: rgba(0, 0, 0, 0.12) !important; }
.addButtonIcon {
  margin-right: 5px; }

.erasePatientDataButton {
  margin-right: 2em !important;
  @media (max-width: $tablet) {
    margin-right: 0 !important; } }
.erasePatientDataButton:nth-child(2) {
  @media (max-width: $tablet) {
    margin-top: 1rem; } }

.closeModalIcon {
  position: absolute!important;
  right: 5px!important;
  top: 5px!important; }

.sendingDataAnimation {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }


.downloadQrButton {
  margin-top: 0.75rem !important;
  background-color: $color-primary-1 !important;
  color: #ffffff !important; }
