@import "../../styles/colors";
@import "../../styles/palette";
@import "../../styles/typography";
@import "../../styles/responsive";

.chip {
  margin: 2px 5px; }

.textField {
  margin-left: 10px;
  label {
    color: $color-primary-1 !important; }
  div {
    fieldset {
      border-color: $color-primary-1 !important; } }
  .contentText {
    border-bottom: 10px; } }

.gridContainer {
  margin: 0px 2.5px !important;
  width: unset !important; }

.parentGrid {
  flex-direction: row;
  flex-wrap: wrap;
  display: flex;
  align-items: baseline;
  border: 1px solid $color-primary-1 !important;
  border-radius: 5px; }

.typography {
  margin-left: 5px !important;
  margin-top: 2px !important; }


.label {
  color: $color-primary-1 !important; }
