@import "../../../../styles/colors";
@import "../../../../styles/palette";
@import "../../../../styles/typography";
@import "../../../../styles/responsive";

.gridContainer {
  justify-content: space-between !important;
  margin-top: 15px !important;
  margin-bottom: 50px !important; }
.photoLabel {
  color: $color-primary-1; }
.filepondWrapper {
  margin-top: 0.5rem;
  margin-bottom: -1rem; }
.formControl, .formControlCalendar {
  box-sizing: border-box;
  min-width: 120px;
  display: flex!important;
  label {
    color: $color-primary-1 !important; }
  div {
    box-sizing: border-box;
    fieldset {
      box-sizing: border-box;
      border-color: $color-primary-1 !important; } }
  svg {
    color: $color-primary-1 !important; } }
.stepper {
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 10;
  position: absolute;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  @media (max-width: $tablet) {
    position: fixed; } }
.flexColumn {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  button {
    background-color: $color-primary-1;

    color: white; }
  button:hover {
    background-color: $primary-color-hover;
    color: white; }
  button:disabled {
    background-color: white;
    color: $primary-color; } }

.noEqualPasswordsMessage {
  color: red!important;
  font-style: italic;
  margin-left: 20px; }

.noEqualPasswordsMessageBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red!important;
  font-style: italic; }

.paymentModalTitleBox {
  display: flex;
  align-items: center; }

.sendingDataAnimation {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.textField {
  label {
    color: $color-primary-1 !important; }
  div {
    fieldset {
      border-color: $color-primary-1 !important; } } }
.textFieldError {
  label {
    color: $error-color !important; }
  div {
    fieldset {
      border-color: $error-color !important; } } }

.dateTextField {
    display: flex !important;
    margin-bottom: 20px !important;
    label {
      color: $color-primary-1 !important; }
    div {
      fieldset {
        border-color: $color-primary-1 !important; } }

    .MuiOutlinedInput-input-289 {
      cursor: pointer !important; }
    .MuiOutlinedInput-input-289:hover {
      cursor: pointer !important; } }


.dateTextField:hover {
  cursor: pointer !important; }

.dateTextFieldError {
  display: flex !important;
  margin-bottom: 5px;
  label {
    color: $error-color !important; }
  div {
    fieldset {
      border-color: $error-color !important; } } }
.dateTextFieldError:hover {
  cursor: pointer !important; }

.adornmentHover:hover {
  cursor: pointer !important; }


.contentText {
  border-bottom: 10px; }

.formContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-evenly; }

.doubleColumnContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start; }

.calendarBox {
  display: flex;
  flex-direction: column;
  align-items: center; }
.calendar {
  width: 100% !important;
  margin-top: -3px;
  z-index: 36;
  border-top: none !important;
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px; }

.formControlCalendar {
  input:hover {
    cursor: pointer; } }

.formControlCalendar:hover {
    cursor: pointer; }

.formControlCalendarOpened {
  box-sizing: border-box;
  min-width: 120px;
  display: flex!important;
  label {
    color: $color-primary-1 !important; }
  div {
    box-sizing: border-box;
    fieldset {
      box-sizing: border-box;
      border-color: $color-primary-1 !important;
      border-bottom: none; } }
  svg {
    color: $color-primary-1 !important; } }



.tutoWrapper {
  display: flex;
  flex-direction: column;
  text-align: center; }

.tutoImgWrapper {
  text-align: center;
  margin: 0.6em; }

.tutoParentWrapper:after, .tutoParentWrapper:before {
  @media (min-width: $tablet) {
    right: 99%;
    top: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none; } }


.tutoParentWrapper:after {
  @media (min-width: $tablet) {
    border-color: rgba(136, 183, 213, 0);
    border-right-color: #ffffff;
    border-width: 15px;
    margin-top: -15px; } }

.tutoParentWrapper:before {
  @media (min-width: $tablet) {
    border-color: rgba(194, 225, 245, 0);
    border-right-color: #ffffff;
    border-width: 21px;
    margin-top: -21px; } }

.UxTutoMask {
  @media (max-width: 1400px) {
    fill: rgba(0, 0, 0, 0.9) !important;
    stroke: rgba(0, 0, 0, 0.9) !important; } }

.patientPhotoContainer {
  position: relative;
  display: flex;
  justify-content: center;
 }  //height: 15rem
.patientPhotoWrapper {
  position: relative; }

.patientPhoto {
  height: 6rem !important;
  width: 6rem !important; }
.editPhotoButton {
  padding: 0.25rem !important;
  background-color: $primary-color !important;
  position: absolute !important;
  bottom: 0 !important;
  right: 0 !important;
  svg {
    color: #fff; } }
