@import "../../styles/colors";
@import "../../styles/palette";
@import "../../styles/typography";
@import "../../styles/responsive";

.formControl {
  margin-top: 8px;
  min-width: 120px; }

.flexColumn {
  display: flex;
  flex-direction: column; }
.actionsContainer {
  button {
    background-color: $color-primary-1;

    color: white; }
  button:hover {
    background-color: $primary-color-hover;
    color: white; }
  //button:disabled
  //  background-color: white
  //  color: $color-primary-1

 }  //  border: 1px solid $color-primary-1


.noEqualPasswordsMessage {
  color: #ff483f !important;
  font-style: italic;
  margin-left: 10px !important; }

.noEqualPasswordsMessageBox {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center !important;
  color: #ff483f !important;
  font-style: italic; }

.paymentModalTitleBox {
  display: flex;
  align-items: center; }

.sendingDataAnimation {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.textField {
  label {
    color: $color-primary-1 !important; }
  div {
    padding-right: 0 !important;
    fieldset {
      border-color: $color-primary-1 !important; } }
  .contentText {
    border-bottom: 10px; } }
