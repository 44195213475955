@import "../../../styles/colors";
@import "../../../styles/palette";

@import "../../../styles/typography";
@import "../../../styles/responsive";

.formControl {
  margin-top: 8px;
  min-width: 120px; }

.flexColumn {
  display: flex;
  flex-direction: column;
  button {
    background-color: $color-primary-1;

    color: white; }
  button:hover {
    background-color: $primary-color-hover;
    color: white; } }
.checkbox {
  color: $color-primary-1 !important; }

.noEqualPasswordsMessage {
  color: red!important;
  font-style: italic;
  margin-left: 20px; }

.noEqualPasswordsMessageBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: red!important;
  font-style: italic; }

.paymentModalTitleBox {
  display: flex;
  align-items: center; }

.privacyLink {
  color: $complementary-concrete-color; }
.privacyLink:hover {
  color: #0077b5;
  text-decoration: underline; }

.sendingDataAnimation {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0; }

.closeModalIcon {
  position: absolute!important;
  right: 5px!important;
  top: 5px!important; }

.forgottenPasswordText {
  margin-top: 10px!important;
  font-style: italic; }

.textField {
  label {
    color: $color-primary-1 !important; }
  div {
    fieldset {
      border-color: $color-primary-1 !important; } } }

