@import "../../styles/colors";
@import "../../styles/palette";
@import "../../styles/palette.sass";
@import "../../styles/typography.sass";
@import "../../styles/responsive.sass";
@import "../../styles/animations";

.Landing {
  display: flex;
  flex-direction: column;
  text-align: justify;
  align-items: center;
  overflow-x: hidden;
  font-family: Aristotelica;
  background-color: $secondary-color;

  h1 {
    color: white;
    text-align: center;
    font-size: $big-font-size; }

  h2 {
    color: white;
    text-align: center;
    font-size: $big-font-size;
    margin-top: -30px;
    @media (max-width: $tablet) {
      margin-top: 0;
      font-size: $large-font-size; } }

  h3 {
    font-family: Blogger-sans; }

  li {
    font-family: Blogger-sans; }

  a {
    font-family: Blogger-sans; }

  p {
    font-size: $large-font-size;
    font-family: Blogger-sans;
    text-align: justify; }

  .headContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap !important;
    width: 100%;
    min-height: 100vh;
    padding-top: 1em;
    align-items: center;
    justify-content: center;
    background: url(../../img/bg-masthead-blur2.png) no-repeat center;
    background-size: cover;
    @media (max-width: $tablet) {
      background: url(../../img/bg-masthead-blur.png) no-repeat center;
      background-size: cover; }
    @media (max-width: 360px) {
      padding-top: 3em !important;
      padding-bottom: 3em !important; }

    h1 {
      font-size: $huge-font-size;
      @media (max-width: $tablet) {
        font-size: $title-font-size; } }

    .primaryCTAButton {
      color: $secondary-color;
      border: 2px solid;
      padding: 1em 2.5em;
      border-radius: 10px;
      cursor: pointer;
      font-size: $big-font-size;
      @media (max-width: $mobile) {
        font-size: $large-font-size; } }

    .headContainerImg {
      @media (min-width: $computer) {
        margin-top: -200px; } }

    .headContainerContent {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      max-width: 80%;
      padding-top: 1em;
      .buy24Container {
        background-color: $secondary-color;
        color: $color-primary-1;

        font-size: $medium-font-size;
        padding-left: 2rem !important;
        padding-right: 2rem !important;
        margin-top: 4rem;
        border-radius: 4px;
        @media (max-width: $mobile) {
          margin-top: 2rem;
          width: 100%; } } } }


  .weHelpYouSection {
    display: flex;
    justify-content: space-around;
    background-color: #74a7b1;
    width: 100%;
    margin-bottom: -2px;

    p {
      text-align: center;
      color: #ffffff; }

    .documentsButtonContainer {
      margin-top: 1em;
      display: flex !important;
      justify-content: center !important;

      .downloadDocumentButton {
        background-color: $color-secondary-1-0  !important; }

      .documentsDownloadIcon {
        margin-right: 0.2em; } } }

  .peopleContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 0 10px 0 10px;
    color: $secondary-color;
    position: relative;
    p {
      max-width: 800px;
      @media (max-width: $tablet) {
        max-width: 400px; } }

    .fondoOlasImgs {
      position: absolute; }

    .peopleContainerContent {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      top: 1rem;
      max-width: 90vw; }

    .peopleIconContainer {
      display: flex;
      .peopleIconContent {
        display: flex;
        flex-direction: column;
        align-items: center; }
      p {
        @media (max-width: $mobile) {
          text-align: center; } }
      .peopleIconContent:hover {
        cursor: pointer; }
      .peopleIconLeft {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 50px; }

      .peopleIconRigth {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center; } } }

  .whyContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 {
      color: $color-primary-1; }


    .whyList {
      list-style-type: none;
      text-align: left;
      font-size: $large-font-size;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-weight: bold;
      @media (max-width: $tablet) {
        font-size: $medium-font-size;
        padding-left: 0;
        padding-right: 20px;
        margin-left: -20px; }

      li {
        display: flex;
        align-items: center; } }

    .whyArrowImg {
      transform: rotate(45deg); } }

  .ownBraceletsContainer {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;

    .braceletsContainerImg {
      display: flex;
      align-items: center;

      .braceletsContainerImgBracelet {
        animation: floatBracelet 6s ease-in-out infinite;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        margin-bottom: 250px;
        @media (max-width: $mobile) {
          margin-bottom: 50px; }

        img {
          height: 160px;
          @media (max-width: $tablet) {
            width: 250px; } }

        p {
          color: $primary-color-hover;
          font-size: $big-font-size; } }

      .braceletsContainerImgHandQR {
        width: 180px;
        transform: rotate(-10deg);
        @media (max-width: $mobile) {
          display: none; } } }

    .braceletsContainerImgArrowLeft {
      margin-top: -200px;
      margin-left: 70px;
      @media (max-width: $tablet) {
        display: none; } }

    .braceletsContainerImgArrowMobile {
      margin-top: -90px;
      margin-left: 70px;
      @media (min-width: $tablet) {
        display: none; } }

    .content {
      display: flex;
      justify-content: space-around;
      align-items: center;
      @media (max-width: $tablet) {
        flex-direction: column;
        align-items: center; } }

    .contentVideo {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 50px;
      @media (max-width: $tablet) {
        flex-direction: column-reverse;
        align-items: center; } }

    .ownBraceletsTextContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      max-width: 600px;
      @media (max-width: $tablet) {
        padding: 0 20px; }

      h1 {
        color: $color-primary-1; } }


    .iframeVideo {
      width: 600px;
      height: 350px;
      @media (max-width: $mobile) {
        width: 85%;
        height: 200px;
        margin-bottom: 20px; } } }

  .historyContainer {
    display: flex;
    background-color: $secondary-color-off;
    @media (max-width: $mobile) {
      flex-direction: column; }

    .historyContainerContent {
      max-width: 65%;
      padding: 10px 60px;
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: $mobile) {
        max-width: 100%;
        padding: 0 20px; }

      h1 {
        color: $color-primary-1; }


      p {
        @media (min-width: $computer) {
          max-width: 65%; } } }

    .historyImageContainer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media (min-width: $tablet) {
        margin-right: 15px; }

      img {
        width: 100%;
        height: auto;
        @media (min-width: $tablet) {
          margin-top: 3em;
          margin-bottom: 3em;
          border-radius: 25px; } } } }

  .enviromentContainer {
    display: flex;
    justify-content: center;
    background-color: $secondary-color;
    @media (max-width: $mobile) {
      padding-top: 40px; }

    .enviromentContainerContent {
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      @media (max-width: $mobile) {
        flex-direction: column-reverse; } }

    .enviromentTextContainer {
      max-width: 65%;
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      margin-right: 20px;
      align-items: center;
      @media (max-width: $mobile) {
        max-width: 100%; }

      h1 {
        color: $color-primary-1; }


      p {
        @media (min-width: $computer) {
          max-width: 70%; } } }

    .enviromentImageContainer {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      @media (min-width: $tablet) {
        margin-left: 15px; }

      img {
        width: 100%;
        height: auto;
        @media (min-width: $tablet) {
          border-radius: 25px;
          margin-top: 3em;
          margin-bottom: 3em; } } } }

  .sampleContainer {
    display: flex;
    justify-content: space-around;
    padding: 0 10px 40px 10px;
    width: 100%;
    margin-top: 60px;
    flex-direction: row;
    @media (max-width: $mobile) {
      flex-direction: column; }

    .contentMock {
      display: flex;
      justify-content: center;
      position: relative;

      .sampleImg {
        height: 350px;
        margin-right: -35px;
        align-self: flex-end;
        padding-right: 1em;
        @media (min-width: $tablet) {
          height: 400px;
          margin-right: 20px;
          padding-right: 0.5em; }
        @media (min-width: $computer) {
          height: 500px;
          padding-right: 4em; } }

      .mockStepsView {
        height: 325px;
        margin-right: -35px;
        z-index: 30;
        align-self: flex-end;
        @media (min-width: $tablet) {
          height: 350px;
          margin-right: -2em; }
        @media (min-width: $computer) {
          height: 450px;
          margin-right: -2.50em; } }

      .mockContainerImg {
        position: absolute;
        width: 250px;
        bottom: -8%;
        left: -20%;
        z-index: 50;
        @media (max-width: $tablet) {
          width: 160px;
          bottom: -6%;
          left: -17%; }
        @media (max-width: $mobile) {
          left: 0; } } }


    .contentList {
      margin-right: 40px;
      @media (max-width: $mobile) {
        margin-right: 20px;
        margin-left: 20px; }

      h1 {
        color: $color-primary-1;

        @media (min-width: $tablet) {
          padding-left: 66px; } }

      .content {
        display: flex;
        justify-content: space-around; }

      .sampleList {
        list-style-type: none;
        text-align: left;
        font-size: $large-font-size;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        font-weight: bold;
        @media (max-width: $tablet) {
          font-size: $medium-font-size;
          padding-left: 0; }

        li {
          display: flex;
          align-items: center; } } } }

  .reviewsContainer {
    display: flex;
    justify-content: center;
    width: 100%;

    button {
      font-size: $big-font-size;
      background-color: transparent;
      color: $secondary-color;
      border: none;
      padding: 10px 20px;
      border-radius: 6px;
      cursor: pointer; }

    .reviewContainerContent {
      display: flex;
      align-items: center;
      background-color: $color-primary-1;

      height: 350px;
      padding-left: 150px;
      padding-right: 150px;
      @media (max-width: $tablet) {
        padding-right: 10px;
        padding-left: 10px;
        flex-direction: column; }

      .reviewLogo {
        width: 150px;
        height: 150px;
        object-fit: contain;
        background-color: #ffffff;
        border-radius: 50%;
        @media (max-width: $tablet) {
          width: 50px;
          height: 50px;
          position: absolute;
          margin: 15px 0 0 90px; } }

      .reviewDataContainer {
        padding-left: 50px;
        display: flex;
        color: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        text-align: start;
        @media (max-width: $tablet) {
          max-width: 500px;
          margin-top: 20px;
          margin-right: 50px;
          text-align: justify; }
        .starsContainer {
          display: flex;
          align-items: center; }
        a {
          text-decoration: underline;
          color: white;
          margin-bottom: 10px; }

        p {
          font-weight: bold;
          font-size: $medium-font-size;
          margin-bottom: 8px; }
        h2 {
          @media (max-width: $tablet) {
            max-width: 200px;
            text-align: start;
            margin: 10px 0 0; } } } } }

  .newsContainer {
    display: flex;
    justify-content: center;
    width: 100%;

    button {
      font-size: $big-font-size;
      background-color: transparent;
      color: $secondary-color;
      border: none;
      padding: 10px 20px;
      border-radius: 6px;
      cursor: pointer; }

    .newsContainerContent {
      display: flex;
      align-items: center;
      background-color: $color-primary-1;

      height: 350px;
      padding-left: 150px;
      padding-right: 150px;
      @media (max-width: $tablet) {
        padding-right: 10px;
        padding-left: 10px;
        flex-direction: column; }

      .newsLogo {
        width: 150px;
        height: 150px;
        object-fit: contain;
        background-color: #ffffff;
        border-radius: 50%;
        @media (max-width: $tablet) {
          width: 50px;
          height: 50px;
          position: absolute;
          margin: 15px 0 0 90px; } }

      .newsDataContainer {
        padding-left: 50px;
        display: flex;
        color: white;
        flex-direction: column;
        justify-content: space-around;
        align-items: flex-start;
        text-align: start;
        @media (max-width: $tablet) {
          max-width: 500px;
          margin-top: 20px;
          margin-right: 50px;
          text-align: justify; }

        a {
          text-decoration: underline;
          color: white;
          margin-bottom: 10px; }

        p {
          font-weight: bold;
          font-size: $medium-font-size;
          margin-bottom: 8px;
          margin-top: 30px; }

        h2 {
          @media (max-width: $tablet) {
            max-width: 200px;
            text-align: start;
            margin: 10px 0 0; } } } } }

  .footerContainer {
    padding: 10px 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    @media (max-width: $tablet) {
      align-items: center; }

    .sevi {
      cursor: pointer;
      align-self: center;
      color: $complementary-concrete-color;
      margin-top: 20px;
      text-align: center; }

    .codeModal {
      cursor: pointer;
      align-self: center;
      margin: 10px;
      text-align: center;
      text-decoration: underline; }

    .footerContent {
      display: flex;
      justify-content: space-around;
      flex-direction: column;
      align-items: center;
      @media (max-width: $tablet) {
        flex-direction: column; }

      .footerLawsContainer {
        display: flex;
        width: 210px;
        justify-content: space-around;

        p {
          font-size: $small-font-size;
          color: $complementary-concrete-color; } }

      .seviLogo {
        width: 150px; }

      .footerContentServices {
        display: flex;
        flex-direction: column;

        div {
          max-width: 400px; } } } } }
.modalInfoHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px; }
.modalInfoContent {
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: $tablet) {
    flex-direction: column; }
  li {
    font-size: $large-font-size;
    margin-top: 10px; }
  .imageHand {
    width: 15rem;
    height: 15rem; }
  .imageKid {
    width: 11rem;
    height: 22rem; } }
.seeMoreText {
  text-decoration: underline; }
.cookiesLayer {
  width: 100%;
  height: 100%;
  background: transparentize(gray, 0.5);
  position: fixed;
  display: flex;
  justify-content: center;
  z-index: 6000;
  .snackbar {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 6rem;
    width: 100%;
    height: 22rem;
    font-size: 1.2rem;
    text-align: center;
    @media (max-width: $computer) {
      width: 75%;
      padding: 2rem; }
    .moreInfoCookieText {
      color: white;
      margin: 5px; }
    button {
      background-color: transparent;
      color: white;
      border: none; } } }

.finalImgContainer {
  position: relative;
  margin: 0;

  .asterisco {
    position: absolute;
    bottom: 2px;
    font-size: $small-font-size !important;
    color: $secondary-color-off;
    right: 20%;
    @media (max-width: $tablet) {
      right: 41%; } } }

.cookiesMoreInfoButton {
  background-color: $color-primary-1 !important; }

.cookiesMoreInfoButton:hover {
  background-color: $primary-color-hover !important; }

.privacy {
  cursor: pointer; }

.privacy:hover {
  text-decoration: underline; }

.shareButtonsBox {
  padding: 2em;
  padding-bottom: 1em !important;
  display: flex;
  align-items: center;
  justify-content: space-evenly; }

.shareButton:hover {
  cursor: pointer; }

.floatingButtonGetIt, .floatingButtonGetItLogged {
  position: fixed !important;
  bottom: 1em !important;
  right: 1em !important;
  box-shadow: 3px 7px 20px 0px black;
  color: $primary-color-hover !important;
  background-color: $color-secondary-1-0  !important;
  z-index: 10;
  @media (min-width: $tablet) {
    display: none !important; } }

.floatingButtonGetItLogged {
  bottom: 5em !important; }

.floatingButtonGetItIcon {
  margin-right: 0.3em; }

.companiesTrustContainer {
  margin-top: 3em !important;
  //position: absolute
  //bottom: -5rem
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: $tablet) {
    padding-bottom: 5em; }

  .companiesTrustContent {
    display: flex;
    align-items: center; }

  .companiesTrustLogo {
    width: 150px;
    // height: 150px
    @media (max-width: $mobile) {
      width: 75px; } } }
//height: 75px

.companiesTrustClaim {
  color: #ffffff !important;
  font-size: $large-font-size !important;
  text-align: center !important;
  @media (max-width: $mobile) {
    font-size: $small-font-size !important; } }

.floatingActiveBraceletButton {
  position: fixed !important;
  border-radius: 25px !important;
  background-color: $color-secondary-1-0  !important;
  color: $primary-color-hover !important;
  bottom: 1em;
  right: 1em;
  box-shadow: 3px 7px 20px 0px rgba(0, 0, 0, 1); }


.activeIcon {
  color: $primary-color-hover !important;
  margin-right: 0.5em !important; }

.FbLikeButtonContainer {
  padding-top: 1em;
  @media (min-width: $tablet) {
    padding-top: 3em; } }


.facebookLikeButton {
  display: flex !important;
  align-items: center !important;
  margin-top: 2em !important;
  padding-right: 1.5em !important;
  font-size: $micro-font-size !important;
  background-color: #3C5A99 !important;
  border-radius: 6px;
  @media (max-width: $tablet) {
    display: none !important; }
  @media (min-width: $tablet) {
    font-size: $tiny-font-size !important;
    padding-right: 1.7em !important; } }

.facebookLike {
  height: 30px;
  width: 30px;
  @media (min-width: $tablet) {
    height: 45px;
    width: 45px; } }


.pulsidHeadImage {
  display: none;
  height: 100px;
  width: 135px;
  @media (max-width: $tablet) {
    display: block; } }

.pulsidPriceBadgeDesktop {
  min-width: 4rem;
  min-height: 4rem;
  background-color: #c3dd36;
  font-size: large !important;
  font-weight: bold;
  @media (max-width: $tablet) {
    display: none !important; } }

.pulsidPriceBadgeAlways {
  min-width: 4rem;
  min-height: 4rem;
  background-color: #c3dd36;
  font-size: large !important;
  font-weight: bold; }

.pulsidPriceBadge {
  display: none !important;
  @media (max-width: $tablet) {
    display: block !important; }
  span:last-child {
    min-width: 3rem;
    background-color: #c3dd36 !important;
    font-size: medium;
    font-weight: bold;
    min-height: 3rem; } }

.discountSticker {
  position: absolute;
  right: -25px;
  top: -25px;
  border-radius: 50%;
  font-size: $small-font-size;
  padding: 1.3em 1em;
  background-color: #ff5a5a;
  color: #ffffff;
  box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 3px 1px -2px rgba(0, 0, 0, 0.12); }

.offerSnackbar {
  width: 100%;
  max-width: 100% !important;
  text-align: center;
  font-size: $medium-font-size !important;

  div:first-child {
    width: 100%;
    max-width: 100% !important;
    border-top-right-radius: 0 !important;
    border-top-left-radius: 0 !important;
    font-size: $medium-font-size !important; } }


.snackOfferButton {
  background-color: $primary-color-hover;
  color: $secondary-color; }

.offerBanner {
  position: fixed;
  display: flex;
  z-index: 55;
  bottom: 0;
  width: 100%;
  padding: 1em;
  text-align: center;
  font-size: $large-font-size;
  background-color: #ff0012;
  color: white;
  @media (max-width: $tablet) {
    flex-direction: column;
    display: none; } }

.offerBannerMobile {
  position: fixed;
  display: none;
  z-index: 55;
  bottom: 0;
  width: 100%;
  padding: 1em;
  text-align: center;
  font-size: $large-font-size;
  background-color: #FF0012;
  color: white;
  @media (max-width: $tablet) {
    display: flex;
    justify-content: space-around; } }

.closeOfferBanner {
  position: absolute;
  right: 25px;
  top: 8px;
  @media (max-width: $tablet) {
    position: absolute;
    right: 5px;
    top: 5px; } }

.offerBannerCountdown {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: $tablet) {
    margin-top: 1.4em !important; } }

.offerBannerCountdown, .offerClaim {
  @media (max-width: $tablet) {
    display: flex;
    flex-direction: column; } }

.offerClaim {
  span:first-child {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (min-width: $tablet) {
      display: inline-flex !important; } } }

.offerButtonBox {
  display: flex;
  align-items: center;
  justify-content: center;
  @media (max-width: $tablet) {
    margin-top: 1em !important; } }

.offerBannerGetItButton {
  background-color: #00B32C !important;
  color: #ffffff !important; }

.closeOfferBannerIcon {
  color: #ffffff; }

.whatsappPostSection {
  display: flex;
  justify-content: space-around;
  background-color: #74a7b1;
  width: 100%;
  padding-top: 2rem;
  margin-bottom: -2px;
  color: #fff; }
.whatsappPostClaim {
  text-align: center !important; }

.whatsappPostItem {
  display: flex !important;
  justify-content: center !important;
  margin-bottom: 2rem !important;
  .downloadDocumentButton {
    background-color: $secondary-color; } }
.whatsappPostImage {
  height: 250px; }

.newsletterSectionGrid {
  padding-top: 2rem;
  padding-bottom: 4rem;
  background-color: $secondary-color;
  @media (min-width: $tablet) {
    padding-top: 5rem;
    padding-bottom: 3.5rem; } }
.CTAContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em !important;
  p {
    box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    display: flex;
    align-items: center;
    font-size: $medium-font-size;
    background-color: #ffffff;
    border-radius: 8px;
    padding-left: 0.4rem;
    padding-right: 1.2rem; }
  .secondaryCTAButton {
    color: $color-primary-1 !important;
    border: 2px solid !important;
    padding: 1em 2.5em !important;
    border-radius: 10px !important;
    cursor: pointer !important;
    font-size: $big-font-size !important;
    @media (max-width: $mobile) {
      font-size: $large-font-size !important; } } }

.navidadCollage {
  width: 11rem;
  margin-top: 2rem;
  @media (min-width: $computer) {
    margin-top: 6rem;
    width: 14rem; } }
